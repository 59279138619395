const salarios = {
    '1999': 180,
    /*'2000': 195,
    '2001': 232,
    '2002': 248,
    '2011': 545,
    '2012': 622,
    '2013': 678,
    '2014': 724,
    '2015': 788,
    '2016': 880,
    '2017': 937,
    '2018': 954,
    '2019': 998,
    '2020': 1045,*/
};

export {
    salarios
};
