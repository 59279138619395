import React, {useEffect, useState} from 'react';
import api from "../../../services/api";
import {MDBContainer} from "mdbreact";
import {MDBCard, MDBCardBody} from "../Calculo/styles";
import 'react-quill/dist/quill.snow.css';

const View = (props) => {

    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState({});

    useEffect(() => {
        get(props.id);
    }, [props]);

    async function get(id){
        setLoading(true);
        try{
            const result = await api.get('/api/get-conteudo/'+id);
            //console.log(result.data);
            setItem(result.data);
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBCard style={{padding: '10px'}} >
                <MDBCardBody>
                    <h3>{item.titulo}</h3>
                    <hr/>
                    <div className="ql-editor" dangerouslySetInnerHTML={{__html: item.descricao}}/>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default View;