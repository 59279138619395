import React from 'react';
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBRow
} from "mdbreact";

const PageHeader = (props) => {
    return (
        /*<MDBContainer fluid style={{ marginTop: "1.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <MDBCardTitle tag="h3">{props.title}</MDBCardTitle>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>*/
        <MDBContainer fluid style={{ marginTop: "1.5rem" }}>
            <MDBRow>
                <MDBCol>
                    <h3>{props.title}</h3>
                    <MDBBreadcrumb style={{backgroundColor: 'rgb(247, 247, 247)'}}>
                        <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>{props.title}</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                </MDBCol>
                <MDBCol className="text-right">
                    {props.button} {props.button2}
                </MDBCol>
            </MDBRow>
            <hr style={{marginBottom: 0}}/>
        </MDBContainer>

    );
};

export default PageHeader;