import React, {useContext, useEffect, useState} from 'react';
import {MDBBox, MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBRow} from "mdbreact";
import {SingleSelect} from "react-select-material-ui";
import {optionsEstadoCivil, optionsSexo} from "../../private/Cliente/options";
import CreateButton from "../../../components/Buttons/CreateButton";
import validate from "./validate";
import Notify from "../../../components/Notify";
import api from "../../../services/api";
import AuthContext from "../../../store/auth";
import {useHistory} from "react-router-dom";
import { periodicidades } from "./options";
import {maskCel, maskCpf} from "../../../utils/mask";


const Signup = () => {

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({periodicidade: 1});
    const [showPassword, setShowPassword] = useState(false);
    const [notify, setNotify] = useState({type:null, text:null});
    const context = useContext(AuthContext);
    const [login, setLogin] = useState(false);
    const [plano, setPlano] = useState({id: 0, titulo: ''})
    const [slug, setSlug] = useState(null);
    const history = useHistory();
    const [planos, setPlanos] = useState([]);
    const [desconto, setDesconto] = useState(0);

    useEffect(() => {
        if(localStorage.getItem('@App:plano')){
            const tempPlano = JSON.parse(localStorage.getItem('@App:plano'));
            setPlano(tempPlano);
            setForm({
                ...form,
                ['plano_id']: tempPlano.id
            });
            getPlanos();
            return;
        }
        history.push("/planos");
    }, []);

    useEffect(() => {
        console.log(errors);
        if (Object.keys(errors).length === 0 && saving) {
            save();
            return;
        }
        setSaving(false);
    }, [errors]);

    const handleForm = (event) => {
        let { value, id } = event.target;

        if(id==='cpf'){
            value = maskCpf(value);
        }
        if(id==='cel'){
            value = maskCel(value);
        }

        let newForm = {
            ...form,
            [id]: value
        }

        if(id === 'escritorio'){
            let newSlug = value.replace(/[^a-zA-Z- ]/g,'');
            newSlug = newSlug.toLowerCase();
            newSlug = newSlug.replace(/ /g, '-');
            setSlug(newSlug);
            newForm = {
                ...newForm,
                slug: newSlug
            }
        }

        setForm(newForm);
        setErrors(validate(newForm));
    }

    const handlePeriodicidade = (value) => {
        const periodicidade = periodicidades.find((item) => {
            return item.value === value;
        });
        console.log('periodicidade', periodicidade);
        console.log('plano', plano);
        console.log('desconto_periodicidade_'+periodicidade.label.toLowerCase());
        const newDesconto = plano['desconto_periodicidade_'+periodicidade.label.toLowerCase()];
        console.log('newDesconto', newDesconto);
        setDesconto(newDesconto);
        setForm({
            ...form,
            periodicidade: value
        })

    }

    const handleValidate = () => {
        setErrors(validate(form));
        setSaving(true);
    }

    const handleNotify = (notify) =>{
        setNotify(notify);
        setNotify({type: null, text:null});
    }


    async function getPlanos(){
        try{
            const result = await api.get('/api/planos-recursos', {user: form});
            setPlanos(result.data);
        }catch (error){
            console.log(error);
        }
    }

    async function save(){
        try{
            const result = await api.post('/api/user', {user: form});
            console.log(result.data);
            if(!result.data.valid){
                handleNotify({type: 'warning', text: result.data.return});
                setSaving(false);
                return;
            }
            setSaving(false);
            handleNotify({type: 'success', text: ''});
            handleLogin();
        }catch (error){
            console.log(error);
            setSaving(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    function handleLogin(){
        setLogin(true);
        context.Login(form.email, form.password);
    }

    return (
        <MDBBox display="flex" justifyContent="center">
            <MDBCard style={{width: '500px', marginTop: '100px'}}>
                <MDBCardBody className="mx-4">
                    <Notify notify={notify} />
                    <div className="text-center">
                        <h3 className="dark-grey-text mb-5">
                            <strong>Cadastro</strong>
                        </h3>
                        {plano.id > 0 ? <h5>Plano {plano.titulo}</h5> : null}
                    </div>
                    <div>
                        <form action="" className="needs-validation">
                            <MDBRow>
                                <MDBCol md="12">
                                    <SingleSelect
                                        label="Periodicidade"
                                        options={periodicidades}
                                        onChange={handlePeriodicidade}
                                        defaultValue={1}
                                    />
                                    { desconto ? (<div>Desconto de {desconto}%</div>) : null}
                                </MDBCol>
                                <MDBCol md="12">
                                    <MDBInput label="Escritório" id="escritorio" name="escritorio"
                                              onChange={handleForm}
                                              containerClass={errors.slug ? "invalid-input" : ""}
                                    />
                                    <div>{slug}</div>
                                    <div className="erro-campo-invalido">{errors.slug}</div>
                                </MDBCol>
                                <MDBCol md="12">
                                    <MDBInput label="Nome Completo" id="name" name="name"
                                              onChange={handleForm}
                                              containerClass={errors.name ? "invalid-input" : ""}
                                    />
                                    <div className="erro-campo-invalido">{errors.name}</div>
                                </MDBCol>
                                <MDBCol md="12">
                                    <MDBInput label="CPF" id="cpf" name="cpf"
                                              onChange={handleForm}
                                              containerClass={errors.cpf ? "invalid-input" : ""}
                                              value={maskCpf(form.cpf)}
                                    />
                                    <div className="erro-campo-invalido">{errors.cpf}</div>
                                </MDBCol>
                                <MDBCol md="12">
                                    <MDBInput label="Celular" id="cel" name="cel"
                                              onChange={handleForm}
                                              containerClass={errors.cel ? "invalid-input" : ""}
                                              value={maskCel(form.cel)}
                                    />
                                    <div className="erro-campo-invalido">{errors.cel}</div>
                                </MDBCol>
                                <MDBCol md="12">
                                    <MDBInput label="E-mail" id="email" name="email"
                                              onChange={handleForm}
                                              containerClass={errors.email ? "invalid-input" : ""}
                                    />
                                    <div className="erro-campo-invalido">{errors.email}</div>
                                </MDBCol>
                                <MDBCol md="12">
                                    <MDBRow>
                                        <MDBCol md="11" sm="11" xs="11">
                                            <MDBInput label="Senha" id="password" name="password"
                                                      type={showPassword ? "text" : "password"}
                                                      onChange={handleForm}
                                                      containerClass={errors.password ? "invalid-input" : ""}
                                            />
                                            <div className="erro-campo-invalido">{errors.password}</div>
                                        </MDBCol>
                                        <MDBCol md="1" sm="1" xs="1">
                                            <i className={"fa "+(showPassword ? "fa-eye" : "fa-eye-slash")}
                                               style={{cursor: "pointer", marginTop: "45px"}}
                                               onClick={() => setShowPassword(!showPassword)}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </form>
                    </div>
                    <br/>
                    <div className="text-center mb-3">
                        <MDBBtn style={{display: (saving || login ? 'none' : '')}}
                            type="button"
                            color="primary"
                            className="btn-block"
                            onClick={handleValidate}
                        >
                            Cadastrar
                        </MDBBtn>
                        <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}
                                color="primary"
                                className="btn-block"
                        >
                            <MDBIcon icon="spinner" spin /> Cadastrar
                        </MDBBtn>
                        <MDBBtn outline disabled style={{display: (login ? '' : 'none')}}
                                color="success"
                                className="btn-block"
                        >
                            <MDBIcon icon="spinner" spin /> Usuário Cadastrado. Efetuando Login!
                        </MDBBtn>
                    </div>
                </MDBCardBody>
            </MDBCard>
        </MDBBox>
    );
};

export default Signup;
