import React, {useEffect} from 'react';
import './input-image.css';

const InputImage = (props) => {

    const [text, setText] = "Imagem";
    const [src, setSrc] = "";

    useEffect(() => {
        setImage(props.text);
    }, [props.text]);

    function handleImage(e){
        props.handleImage(e);
    }

    return (
        <div>
            <div className="box" onClick={() => getElementById('inputImage').click}>
                {
                    src ? <img src={src} alt="imagem"/> : {text}
                }
            </div>
            <input id={"inputImage"} type="file" style={{display: 'none'}} onChange={handleImage}/>
        </div>
    );
};

export default InputImage;
