import React, {useEffect, useState} from 'react';
import {MDBCard, MDBCardBody} from "./styles";
import {MDBBtn, MDBCardHeader, MDBCol, MDBRow} from "mdbreact";
import {formatDate} from "../../../utils/date";
import {MDBIcon} from "../../../components/Buttons/DeleteButton/styles";

const DataCalculo = (props) => {

    const [item, setItem] = useState(props.item);

    useEffect(() => {
        //console.log(props.item)
        setItem(props.item);
    }, [props.item]);

    function activeTabs(data){
        props.setActiveTabItemRegras(data);
        props.setActiveTabItem("2");
    }

    return (
        <MDBCard style={{fontSize: '16px', opacity: item.totalContribuicoesPorData ? 1 : 0.6}}>
            <MDBCardHeader   className="grey lighten-2"  style={{padding: '5px 10px 5px 10px', margin: 0}}>
                <div style={{padding: '10px 0 0 0', float: 'left'}} className="indigo-text">{formatDate(item.data)}</div>
                <div style={{float: 'right'}} className="indigo-text text-center" >
                    <div style={{fontSize: "10px"}} >Contribuições</div>
                    <div style={{fontSize: "18px"}} >{item.totalContribuicoesPorData}</div>
                </div>


            </MDBCardHeader>
            <MDBCardBody style={{minHeight: "170px"}}>
                {
                    item.totalContribuicoesPorData ?
                        <div style={{marginTop: '5px'}}>
                            <div>
                                <span style={{fontSize: "11px"}}>Tempo</span><br/>
                                &nbsp;<MDBIcon icon="clock" className="indigo-text"/>&nbsp;&nbsp;&nbsp;{item.tempo}
                            </div>
                            <div style={{marginTop: '8px'}}>
                                <span style={{fontSize: "11px"}}>Idade</span><br/>
                                &nbsp;<MDBIcon icon="calendar" className="indigo-text"/>&nbsp;&nbsp;&nbsp;{item.idade}
                            </div>
                            <div style={{margin: '10px 5px 0 5px'}}>
                                <MDBBtn
                                    outline color="primary"
                                    size="sm"
                                    className="m-0 w-100"
                                    style={{display: (item.data !== '1998-12-16' && item.data !== '1999-11-28' && item.data !== '2019-11-12') ? '' : 'none'}}
                                    onClick={() => activeTabs(item.data)}
                                >
                                    Simulação das Regras
                                </MDBBtn>
                            </div>

                        </div> :
                        <p style={{fontSize: "13px", textAlign: "center"}}>
                            <br/><br/><br/><br/>
                            As contribuições são superiores a esta data
                        </p>
                }
            </MDBCardBody>
        </MDBCard>
    );
}




export default DataCalculo;


