import React, {useContext, useState} from "react";
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from "mdbreact";
import {BrowserRouter as Router, useHistory} from 'react-router-dom';
import logo from "../../../assets/logo.png";
import AuthContext from "../../../store/auth";
import {MDBIcon} from "../../Buttons/DeleteButton/styles";

function Header(){
    const [isOpen, setIsOpen] = useState(false);
    const context = useContext(AuthContext);
    const history = useHistory();

    function logout(){
        context.Logout();
    }

    return(
        <Router>
            <MDBNavbar className="z-depth-1-half" color="indigo darken-4" dark expand="md">
                <MDBNavbarBrand>
                    {/*<img src={logo} height="40" alt="logo"/>*/}
                    <strong className="white-text" style={{fontSize: "28px"}}>ICDS Prev</strong>
                </MDBNavbarBrand>
                <MDBNavbarNav right>
                    <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav>
                                <MDBIcon icon="user-circle"/>
                            </MDBDropdownToggle>
                            <div style={{position: "absolute", top: "-5px", right: '100px'}}>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem href="#!">
                                        <div onClick={() => {history.push('/usuario')}}>Dados</div>
                                    </MDBDropdownItem>
                                    <MDBDropdownItem href="#!" onClick={logout}>Sair</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </div>

                        </MDBDropdown>
                    </MDBNavItem>
                    <MDBNavItem onClick={logout}>

                    </MDBNavItem>

                </MDBNavbarNav>
            </MDBNavbar>
        </Router>
    );
}

export default Header;