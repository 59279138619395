//ESTA VERSÃO DO COMPONENTE TRAZ UMA NOVA ABORDAGEM EM QUE O RELATÓRIO É GRADO
//COM VÁRIAS DATAS JUNTAS PODENDO SEREM ORDENADAS DA FORMA QUE O USÁRIO PREFERIR

import React, {forwardRef, useEffect, useState} from 'react';
import {MDBBtn, MDBCol, MDBInput, MDBNav, MDBNavItem, MDBNavLink, MDBRow, MDBTabContent, MDBTabPane} from "mdbreact";
import api from "../../../services/api";
import {MDBIcon} from "../../../components/Buttons/DeleteButton/styles";
import {optionsElementos, optionsRegras} from "./relatorio/options";
import {SingleSelect} from "react-select-material-ui";
import calculateAge, {formatDate} from "../../../utils/date";
import Regra1 from "./regras/regra1";
import RegraRelatorio from "./relatorio/regra";
import CreateButton from "../../../components/Buttons/CreateButton";
import InputImage from "../../../components/Inputs/Image";
import Vinculos from "./vinculos";
import imgUser from "../../../assets/user-h.png";
import imgUserM from "../../../assets/user-m.png";
import ChartSalarios from "./charts/chartSalarios";
import ChartVinculos from "./charts/chartVinculos";
import { styles } from "./stylesPDF";
import {Text, View} from "@react-pdf/renderer";
//usado para o bloco de vinculos

const Relatorio = (props) => {

    const [loadingDados, setLoadingDados] = useState(false);
    const [datas, setDatas] = useState([]);
    const [cliente, setCliente] = useState(null);
    const [dataId, setDataId] = useState(null);
    const [optionsDatas, setOptionsDatas] = useState([]);
    const [tipoElemento, setTipoElemento] = useState(1);
    const [regra, setRegra] = useState(1);
    const [elementos, setElementos] = useState([]);
    const [regras, setRegras] = useState([]);
    const [saving, setSaving] = useState(false);
    const [logo, setLogo] = useState(null);
    const [titulo, setTitulo] = useState(null);
    const [subtitulo, setSubtitulo] = useState(null);
    const [tabelaVinculos, setTabelaVinculos] = useState(0);
    const [chartSalarios, setChartSalarios] = useState(0);//addChartSalarios
    const [chartVinculos, setChartVinculos] = useState(0);//addChartVinculos

    const [salariosMeses, setSalariosMeses] = useState({'meses': [], 'salarios': []});
    const [salariosMinimos, setSalariosMinimos] = useState({'meses': [], 'salarios': []});
    const [totaisVinculos, setTotaisVinculos] = useState({'vinculos': [], 'totais': []});

    //const [addChartSalarios, setAddChartSalarios] = useState(false);
    //const [addChartVinculos, setAddChartVinculos] = useState(false);

    const [generateSalariosImage, setGenerateSalariosImage] = useState(false);
    const [salariosImage, setSalariosImage] = useState(false);
    const [generateVinculosImage, setGenerateVinculosImage] = useState(false);
    const [vinculosImage, setVinculosImage] = useState(false);

    const [vinculos, setVinculos] = useState([]);

    /*const [activeTabItem, setActiveTabItem] = useState(null);
    const toggle = tab => e => {
        if (activeTabItem !== tab) {
            setActiveTabItem(tab)
        }
    };*/

    useEffect(() => {
        setDatas([]);
        //carregarDados();
    }, []);

    async function carregarDados(){
        setLoadingDados(true);
        //console.log(datas);
        const result = await api.get("/api/carregar-dados-calculos/"+props.calculoId);
        setCliente(result.data.cliente);
        let datas = result.data.calculos;
        setDatas(datas);
        let optionsDatas = [];
        for(let i in datas){
            optionsDatas.push({value: datas[i].id, label: formatDate(datas[i].data)});
        }
        setOptionsDatas(optionsDatas);
        /*if(result.data[0]){
            setActiveTabItem(result.data[0].data);
        }*/
        //carrega os dados para os graficos. Necessário quando o status do cálculo é de 1-calculado ou 2--relatorio
        //=============================================================
        let meses = [];
        let salarios = [];
        let salariosMinimos = [];
        for (let i in result.data.salariosMeses) {
            meses.push(i);
            salarios.push(result.data.salariosMeses[i]);
            salariosMinimos.push(result.data.salariosMinimos[i]);
        }
        let tempSalariosMeses = {meses: meses, salarios: salarios};
        let tempSalariosMinimos = {meses: meses, salarios: salariosMinimos};
        setSalariosMeses(tempSalariosMeses);
        //props.setSalariosMeses(tempSalariosMeses);//passa pro component de insert para ser repassado para o de exportar
        setSalariosMinimos(tempSalariosMinimos);
        //props.setSalariosMinimosMeses(tempSalariosMinimos);//passa pro component de insert para ser repassado para o de exportar

        let vinculos = [];
        let totais = [];
        for (let i in result.data.totaisVinculos) {
            vinculos.push(result.data.totaisVinculos[i].vinculo);
            totais.push(result.data.totaisVinculos[i].total);
        }
        let tempTotaisVinculos = {vinculos: vinculos, totais: totais};
        setTotaisVinculos(tempTotaisVinculos);
        //props.setTotaisVinculos(tempTotaisVinculos);//passa pro component de insert para ser repassado para o de exportar
        //=============================================================


        setLoadingDados(false);
    }

    function handleTitulo(event){
        setTitulo(event.target.value);
    }

    function handleSubtitulo(event){
        setSubtitulo(event.target.value);
    }

    function handleTipoElemento(value){
        //console.log(value);
        setTipoElemento(value);
    }

    function handleRegra(value){
        //console.log(value);
        setRegra(value);
    }

    function handleVinculos(value){
        //console.log('--->', setTabelaVinculos);
        setTabelaVinculos(value);
    }

    function handleChartSalarios(value){
        setChartSalarios(value);
    }

    function handleChartVinculos(value){
        setChartVinculos(value);
    }

    function addElement(){
        //tipo = 1;
        //console.log(tipoElemento);
        if(tipoElemento===1){
            //console.log('adicionar regra');
            for(let i in datas){
                if(datas[i].id === dataId){
                    let regras = datas[i].regras;
                    //for(let j in regras){
                        //if(regras[j].regra === regra){
                            //console.log(regras[j]);
                            setElementos([
                                ...elementos,
                                {
                                    id: elementos.length+1,
                                    position: elementos.length+1,
                                    data_id: dataId,
                                    tipo: tipoElemento,
                                    value: regra,
                                }
                            ]);
                        //}
                    //}
                }
            }
            /*setElementos([
                ...elementos,
                {
                    data: activeTabItem,
                    tipo: 2,
                    value: ''
                }
            ]);*/
            return;
        }

        if(tipoElemento===2){
            //console.log('adicionar texto');
            setElementos([
                ...elementos,
                {
                    id: elementos.length+1,
                    position: elementos.length+1,
                    data_id: 0,
                    tipo: tipoElemento,
                    value: ''
                }
            ]);
            return;
        }

        if(tipoElemento===3){
            //console.log('adicionar texto');
            setElementos([
                ...elementos,
                {
                    id: elementos.length+1,
                    position: elementos.length+1,
                    data_id: 0,
                    tipo: tipoElemento,
                    value: 'vinculos'
                }
            ]);
            return;
        }

        if(tipoElemento===4){
            //console.log('adicionar texto');
            setElementos([
                ...elementos,
                {
                    id: elementos.length+1,
                    position: elementos.length+1,
                    data_id: 0,
                    tipo: tipoElemento,
                    value: '.'
                }
            ]);
            return;
        }
    }

    function removeElement(id){
        const newElementos = elementos.filter((item) => item.id !== id);
        setElementos(newElementos);
        //console.log(id);
        //console.log(newElementos);
    }

    function handleTexto(e){
        let id = parseInt(e.target.id.substr(5));
        const newElementos = [...elementos];
        for(let i in elementos){
            if(elementos[i].id === id){
                elementos[i].value = e.target.value;
            }
        }
        setElementos(newElementos);
    }

    function handleData(value){
        //console.log(value);
        setDataId(value);
    }

    function falta(tempo, tipo){
        return (tempo.anos < 0 || tempo.meses < 0 || tempo.dias < 0) || (tempo.anos === 0 && tempo.meses === 0 && tempo.dias < 0) ?
            (<p> - </p>) :
            (<p>
                {tempo.anos} anos,&nbsp;
                {tempo.meses} meses e&nbsp;
                {tempo.dias} dias de {tipo}
            </p>)
    }

    function handleValidate(){
        setGenerateSalariosImage(true);
        setGenerateVinculosImage(true);
        save();
    }

    async function save(){
        setSaving(true);
        const result = await api.post('/api/salvar-relatorio', {
            elementos: elementos,
            calculo_id: props.calculoId,
            logo: logo,
            titulo: titulo,
            subtitulo: subtitulo,
            vinculos: tabelaVinculos,
            chart_salarios: chartSalarios,
            chart_vinculos: chartVinculos,
        });
        //console.log('result', result);
        setSaving(false);
        props.setEtapa(4);
        // if(result.data.return){
        //     //setEtapa(3);
        // }

    }

    function handleImage(e){
        //console.log(e.target);
    }

    function getSalariosImage(image){
        setSalariosImage(image);
        props.setChartSalariosImage(image);
    }

    function getVinculosImage(image){
        setVinculosImage(image);
        props.setChartVinculosImage(image);
    }


    return (

        <div>
            <MDBRow>
                <MDBCol size="8">
                    {
                        cliente ? (
                            <div className="box-cliente">
                                <img src={1 !== 2 ? imgUser :  imgUserM} alt="" title="" width="100"/>
                                <p><strong>Nome:</strong> {cliente.nome}</p>
                                <p><strong>CPF:</strong> {cliente.cpf}</p>
                                <p><strong>Nascimento:</strong> {cliente.nascimento ? formatDate(cliente.nascimento) : null}</p>
                                <p><strong>Idade:</strong> {cliente.nascimento ? calculateAge(cliente.nascimento) : null}</p>
                            </div>
                            /*<div className="box-cliente">
                                <h5>
                                    <strong>Nome2: </strong>{cliente.nome}
                                    <strong>&nbsp;&nbsp;&nbsp;&nbsp;CPF: </strong>{cliente.cpf}
                                    <strong>&nbsp;&nbsp;&nbsp;&nbsp;Nascimento: </strong>{cliente.nascimento ? formatDate(cliente.nascimento) : null}
                                    <strong>&nbsp;&nbsp;&nbsp;&nbsp;Idade: </strong>{cliente.nascimento ? calculateAge(cliente.nascimento) : null}
                                </h5>
                            </div>*/
                        ) : <span>&nbsp;</span>
                    }
                    <br/>
                </MDBCol>

                <MDBCol size="4">
                    {
                        cliente ? (
                            <MDBRow>
                                <MDBCol size="12">
                                    <div className={"btn-onOff " + (tabelaVinculos===0 ? '' : 'btn-onOff-on')} onClick={() => handleVinculos(tabelaVinculos===0 ? 1 : 0)}>
                                        <i className={"fas fa-circle " + (tabelaVinculos===0 ? '' : 'btn-onOff-on-i')}/>
                                        <p className={(tabelaVinculos===0 ? '' : 'btn-onOff-on-p')}>Vínculos</p>
                                        <div style={{clear: 'both'}}/>
                                    </div>
                                </MDBCol>
                                <MDBCol size="12">
                                    <div className={"btn-onOff " + (chartSalarios===0 ? '' : 'btn-onOff-on')} onClick={() => handleChartSalarios(chartSalarios===0 ? 1 : 0)}>
                                        <i className={"fas fa-circle " + (chartSalarios===0 ? '' : 'btn-onOff-on-i')}/>
                                        <p className={(chartSalarios===0 ? '' : 'btn-onOff-on-p')}>Gráfico de salário</p>
                                        <div style={{clear: 'both'}}/>
                                    </div>
                                </MDBCol>
                                <MDBCol size="12">
                                    <div className={"btn-onOff " + (chartVinculos===0 ? '' : 'btn-onOff-on')} onClick={() => handleChartVinculos(chartVinculos===0 ? 1 : 0)}>
                                        <i className={"fas fa-circle " + (chartVinculos===0 ? '' : 'btn-onOff-on-i')}/>
                                        <p className={(chartVinculos===0 ? '' : 'btn-onOff-on-p')}>Gráfico de vínculos</p>
                                        <div style={{clear: 'both'}}/>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        ) : <span>&nbsp;</span>
                    }


                </MDBCol>

                <MDBCol>
                    <h3>Relatório <MDBBtn onClick={carregarDados}>Carregar Dados</MDBBtn></h3>
                </MDBCol>
                <MDBCol>
                    <div style={{textAlign: 'right', display: (saving ? 'none' : '')}}>
                        <CreateButton text="Salvar e Continuar" onClick={handleValidate} />
                    </div>
                    <div style={{textAlign: 'right', display: (saving ? '' : 'none')}}>
                        <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                            <MDBIcon icon="spinner" spin />&nbsp;&nbsp;Salvar e Continuar
                        </MDBBtn>
                    </div>

                </MDBCol>
            </MDBRow>
            <hr/>
            <div style={{display: loadingDados ? '' : 'none'}} className="text-center">
                <MDBIcon icon="spinner" spin style={{fontSize: "64px"}} />
                <br/><br/>
                <h4>Carregando os dados ...</h4>
                <br/>
            </div>

            <div style={{display: datas.length > 0 ? '' : 'none'}}>
                <div data-test="alert" className="alert alert-warning" role="alert">
                    <i data-test="fa" className="fa fa-exclamation-triangle sc-pFZIQ dSzqsX"/>
                    &nbsp;&nbsp;&nbsp;Os dados foram carregados de acordo com as datas de cálculo. Selecione os elementos que deseja adicionar ao relatório.
                </div>
                {/*<MDBRow>
                    <MDBCol>
                        <h5>Os dados foram carregados de acordo com as datas de cálculo. Selecione os elementos que deseja adicionar ao relatório.</h5><br/>
                    </MDBCol>
                </MDBRow>*/}
                <br/>
                <MDBRow>
                    <MDBCol>
                        <h3>
                            <MDBInput className="text-center" labelClass="text-center" size="lg" label="Digite o Título do Relatório" onChange={handleTitulo}/>
                        </h3>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol>
                        {
                            elementos.map(
                                (elemento, key) => {
                                    //console.log(key, elemento);
                                    if(elemento.tipo === 1){
                                        let regra = {};
                                        let data = null;
                                        //console.log(elementos);
                                        //console.log('elemento', elemento);
                                        for(let i in datas){
                                            if(datas[i].id === elemento.data_id){
                                                data = datas[i].data;
                                                //console.log('regras data '+datas[i].id, datas[i].regras);
                                                regra = datas[i].regras[elemento.value-1];
                                                //console.log(regra);
                                            }
                                        }
                                        let removeButton = (
                                            <MDBIcon
                                                icon="times"
                                                alt="Remover"
                                                title="Remover"
                                                onClick={() => removeElement(elemento.id)}
                                            />
                                        );
                                        return(
                                            <MDBRow>
                                                <MDBCol>
                                                    <RegraRelatorio regra={regra} removeButton={removeButton} removeElement={removeElement} data={data}/>
                                                </MDBCol>
                                            </MDBRow>
                                        );
                                    }

                                    if(elemento.tipo === 2){
                                        return(
                                            <MDBRow>
                                                <MDBCol size="11">
                                                    <MDBInput label="Texto" id={'texto'+(key+1)} name={'texto'+(key+1)} onChange={handleTexto}/>
                                                </MDBCol>
                                                <MDBCol size="1">
                                                    <MDBIcon
                                                        icon="times"
                                                        alt="Remover"
                                                        title="Remover"
                                                        style={{bottom:0}}
                                                        onClick={() => removeElement(elemento.id)}
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                        );
                                    }

                                    /*if(elemento.tipo === 3){
                                        return(
                                            <MDBRow>
                                                <MDBCol size="11">
                                                    <Vinculos vinculos={props.vinculos}/>
                                                </MDBCol>
                                                <MDBCol size="1">
                                                    <MDBIcon
                                                        icon="times"
                                                        alt="Remover"
                                                        title="Remover"
                                                        style={{bottom:0}}
                                                        onClick={() => removeElement(elemento.id)}
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                        );
                                    }*/

                                    if(elemento.tipo === 4){
                                        return(
                                            <MDBRow>
                                                <MDBCol size="11">
                                                    <InputImage  handleImage={handleImage} />
                                                </MDBCol>
                                                <MDBCol size="1">
                                                    <MDBIcon
                                                        icon="times"
                                                        alt="Remover"
                                                        title="Remover"
                                                        style={{bottom:0}}
                                                        onClick={() => removeElement(elemento.id)}
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                        );
                                    }
                                    /*return (
                                        <MDBRow>
                                            <MDBCol>
                                                <SingleSelect
                                                    label="Tipo "
                                                    options={optionsRegras}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    )*/

                                }
                            )
                        }
                        <br/>


                        <MDBRow>
                            <MDBCol size={3}>
                                <SingleSelect
                                    label="Tipo de Elemento"
                                    options={optionsElementos}
                                    id="tipo"
                                    name="tipo"
                                    onChange={handleTipoElemento}
                                />
                            </MDBCol>
                            {
                                tipoElemento === 1 ?
                                    ([
                                        <MDBCol size={3}>
                                            <SingleSelect
                                                label="Data"
                                                options={optionsDatas}
                                                id="data"
                                                name="data"
                                                onChange={handleData}
                                            />
                                        </MDBCol>,
                                        <MDBCol size={3}>
                                            <SingleSelect
                                                label="Tipo "
                                                id="regra"
                                                name="regra"
                                                options={optionsRegras}
                                                onChange={handleRegra}
                                            />
                                        </MDBCol>
                                    ]) : null

                            }
                            <MDBCol size={3}>
                                <MDBBtn onClick={addElement}>Adicionar Elemento</MDBBtn>
                            </MDBCol>
                        </MDBRow>

                        <br/><hr/>

                        <MDBRow>
                            {
                                chartSalarios ? (
                                    <MDBCol size={chartVinculos ? "6" : "12"}>
                                        {
                                            salariosMeses ? (
                                                <ChartSalarios meses={salariosMeses.meses} salarios={salariosMeses.salarios}
                                                               generateImage={generateSalariosImage} getImage={getSalariosImage}
                                                               salariosMinimos={salariosMinimos.salarios}/>
                                            ) : null
                                        }

                                        {/*<ChartSalarios meses={props.salariosMeses.meses} salarios={props.salariosMeses.salarios}
                                                       generateImage={generateSalariosImage} getImage={getSalariosImage}
                                                       salariosMinimos={props.salariosMinimos.salarios}/>*/}
                                        <img style={{display: salariosImage ? '' : 'none'}} src={salariosImage}/>
                                        <MDBBtn style={{display:'none'}} onClick={() => {
                                            setGenerateSalariosImage(true);
                                            setGenerateVinculosImage(true);
                                        }}>Teste Salários Image</MDBBtn>
                                    </MDBCol>
                                ) : null
                            }
                            {
                                chartVinculos ? (
                                    <MDBCol size={chartSalarios ? "6" : "12"}>
                                        {
                                            totaisVinculos ? (
                                                <ChartVinculos vinculos={totaisVinculos.vinculos} totais={totaisVinculos.totais}
                                                               generateImage={generateVinculosImage} getImage={getVinculosImage}/>
                                            ) : null
                                        }
                                        {/*<ChartVinculos vinculos={props.totaisVinculos.vinculos} totais={props.totaisVinculos.totais}
                                                       generateImage={generateVinculosImage} getImage={getVinculosImage}/>*/}
                                        <img style={{display: vinculosImage ? '' : 'none'}} src={vinculosImage} alt="vinculos-image"/>
                                    </MDBCol>
                                ) : null
                            }
                        </MDBRow>


                        <MDBRow>
                            {
                                tabelaVinculos ? (
                                    <MDBCol>
                                        {
                                            Object.keys(props.vinculos).length > 0 ?
                                                (
                                                    <div>
                                                        <div style={styles.table}>
                                                            {
                                                                props.vinculos.map((item, index) => {
                                                                    let contribuicoesDivergentes = item.qtdContribuicoesPorData !== item.qtdContribuicoesRegistradas;
                                                                    return (
                                                                        <div key={'salarios-r3-'+index}>
                                                                            <div style={styles.bg}>
                                                                                <p style={{fontSize: "12px"}}>{item.origem}</p>
                                                                            </div>
                                                                            <div style={styles.flexDirection}>
                                                                                <div style={styles.flex}>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{fontSize: "10px"}}>Início</p>
                                                                                    <p style={styles.tableCell}>{formatDate(item.inicio)}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                                <div style={styles.flexR}>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{fontSize: "10px"}}>Fim</p>
                                                                                    <p style={styles.tableCell}>{formatDate(item.fim)}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                            </div>

                                                                            <div style={styles.flexDirection}>
                                                                                <div style={styles.flex}>
                                                                                    <p style={{fontSize: "10px"}}>Normal</p>
                                                                                    <p style={styles.tableCell}>{item.tempo}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                                <div style={styles.flexR}>
                                                                                    <p style={{fontSize: "10px"}}>Especial</p>
                                                                                    <p style={styles.tableCell}>{item.tempoEspecial}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                            </div>

                                                                            <div style={styles.flexDirection}>
                                                                                <div style={styles.flex}>
                                                                                    <p style={{fontSize: "10px"}}>Contrib. Per</p>
                                                                                    <p style={styles.tableCell}>{item.qtdContribuicoesPorData}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                                <div style={styles.flexR}>
                                                                                    <p style={{fontSize: "10px"}}>Contrib. Reg</p>
                                                                                    <p style={styles.tableCell}>{item.qtdContribuicoesRegistradas}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );

                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                ) : null
                                        }
                                    </MDBCol>
                                ) : null
                            }
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <br/>
                {/*<MDBRow>
                    <MDBCol>
                        <SingleSelect
                            label="Adicionar os vínculos ao relatório"
                            options={[
                                {value: 0, label: "Não"},
                                {value: 1, label: "Sim"},
                            ]}
                            id="data"
                            name="data"
                            onChange={handleVinculos}
                            value={vinculos}
                        />
                    </MDBCol>
                    <MDBCol>
                        <SingleSelect
                            label="Adicionar gráfico de salários ao relatório"
                            options={[
                                {value: 0, label: "Não"},
                                {value: 1, label: "Sim"},
                            ]}
                            id="data"
                            name="data"
                            onChange={handleChartSalarios}
                            value={chartSalarios}
                        />
                    </MDBCol>
                    <MDBCol>
                        <SingleSelect
                            label="Adicionar gráfico de vínculos ao relatório"
                            options={[
                                {value: 0, label: "Não"},
                                {value: 1, label: "Sim"},
                            ]}
                            id="data"
                            name="data"
                            onChange={handleChartVinculos}
                            value={chartVinculos}
                        />
                    </MDBCol>
                </MDBRow>*/}
                {/*<MDBRow>
                    <MDBCol>
                        <img src={props.chartSalariosImage} alt=""/>
                    </MDBCol>
                    <MDBCol>
                        <img src={props.chartVinculosImage} alt=""/>
                    </MDBCol>
                </MDBRow>*/}
            </div>

            <br/><br/><br/>
            <br/><br/><br/>
            <br/><br/><br/>
        </div>
    );
};

export default Relatorio;
