import React from 'react';
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBIcon, MDBRow, MDBCol, MDBContainer} from "mdbreact";
import {Link} from "react-router-dom";

const Card = (card) => {


    return (

        <div className="box-card" style={{backgroundColor: card.color}}>
            <div>
                <p className="float-left">{card.title}</p>
                <h2 className="float-right">{card.content}</h2>
            </div>
            <div className="box-card-i" style={{backgroundColor: card.color2}}>
                <MDBIcon  size="lg" className={"fa fa-fw fa-2x " + card.icon} />
            </div>
            <Link to={card.add}>
                <div className="float-right box-card-btn">Adicionar</div>
            </Link>
        </div>

        /*<MDBContainer style={{ marginTop: "1.5rem"}}>
            <MDBCard>
                <MDBCardHeader className="grey lighten-3" tag="h5">
                    <MDBRow>
                        <MDBCol className="custom-primary-color-text" size="10">
                            {card.title}
                        </MDBCol>
                        <MDBCol className="text-right" size="2">
                            <Link to={card.add}>
                                <MDBIcon icon="plus-circle" size="lg" className="custom-primary-color-text" />
                            </Link>
                        </MDBCol>
                    </MDBRow>

                </MDBCardHeader>
                <MDBCardBody className="text-center">
                    <MDBCardTitle tag="h1"><strong>{card.content}</strong></MDBCardTitle>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>*/
    );
}

export default Card;
