import React, {useEffect, useState} from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import Notify from "../../../components/Notify";
import {SingleSelect} from "react-select-material-ui";
import {optionsEstadoCivil, optionsSexo} from "../Cliente/options";
import CreateButton from "../../../components/Buttons/CreateButton";
import {periodicidades} from "../../public/Signup/options";
import {maskCel, maskCpf} from "../../../utils/mask";
import validate from "./validate";
import api from "../../../services/api";

const Dados = () => {

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({periodicidade: 1});
    const [notify, setNotify] = useState({type:null, text:null});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        get();
    }, []);

    useEffect(() => {
        console.log(errors);
        if (Object.keys(errors).length === 0 && saving) {
            save();
        }
        setSaving(false);
    }, [errors]);

    function handleNotify(notify){
        setNotify(notify);
        setNotify({type: null, text:null});
    }

    async function get(id){
        setLoading(true);
        try{
            const result = await api.get('/api/auth-user/');
            //console.log(result.data);
            setForm(result.data);
            setErrors(validate(result.data));
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    async function save(){
        try{
            const result = await api.put('/api/update-dados-usuario/', {user: form});
            //console.log(result.data);
            if(!result.data.valid){
                //notifyWarning(result.data.return);
                handleNotify({type: 'warning', text: result.data.return});
                setSaving(false);
                return;
            }
            setSaving(false);
            handleNotify({type: 'success', text: ''});
        }catch (error){
            console.log(error);
            setSaving(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    const handleForm = (event) => {
        let { value, id } = event.target;

        if(id==='cpf'){
            value = maskCpf(value);
        }
        if(id==='cel'){
            value = maskCel(value);
        }

        let newForm = {
            ...form,
            [id]: value
        }

        setForm(newForm);
        setErrors(validate(newForm));
    }

    const submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    function handleValidate(){
        setErrors(validate(form));
        setSaving(true);
    }

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <Notify notify={notify} />
                    <div className="text-center" style={{display: (loading ? '' : 'none')}}>
                        <MDBIcon icon="spinner" spin size="3x"/>
                    </div>
                    <form action="" className="needs-validation" onSubmit={submitHandler} noValidate style={{display: (loading ? 'none' : '')}}>
                        <MDBRow>
                            <MDBCol md="12">
                                <MDBInput label="Nome Completo" id="name" name="name"
                                          onChange={handleForm}
                                          containerClass={errors.name ? "invalid-input" : ""}
                                          value={form.name}
                                />
                                <div className="erro-campo-invalido">{errors.name}</div>
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBInput label="CPF" id="cpf" name="cpf"
                                          onChange={handleForm}
                                          containerClass={errors.cpf ? "invalid-input" : ""}
                                          value={maskCpf(form.cpf)}
                                />
                                <div className="erro-campo-invalido">{errors.cpf}</div>
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBInput label="Celular" id="cel" name="cel"
                                          onChange={handleForm}
                                          containerClass={errors.cel ? "invalid-input" : ""}
                                          value={maskCel(form.cel)}
                                />
                                <div className="erro-campo-invalido">{errors.cel}</div>
                            </MDBCol>
                            <MDBCol md="12">
                                <MDBInput label="E-mail" id="email" name="email"
                                          onChange={handleForm}
                                          containerClass={errors.email ? "invalid-input" : ""}
                                          value={form.email}
                                />
                                <div className="erro-campo-invalido">{errors.email}</div>
                            </MDBCol>
                        </MDBRow>
                        <hr/>
                        <MDBRow>
                            <MDBCol className="text-right">
                                <div style={{display: (saving ? 'none' : '')}}>
                                    <CreateButton onClick={handleValidate} />
                                </div>
                                <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                                    <MDBIcon icon="spinner" spin />&nbsp;&nbsp;Salvar
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Dados;