import {validateCel, validateCpf, validateEmail} from "../../../utils/validate";

export default function validate(values) {
    let errors = {};

    if (!values.name) {
        errors.name = 'Informe o nome';
    }

    if(values.name){
        let partes = values.name.split(' ');
        if(partes.length < 2){
            errors.name = 'Informe nome e sobrenome';
        }else if(!partes[1]){
            errors.name = 'Informe nome e sobrenome';
        }
    }

    if(!values.cpf){
        errors.cpf = 'Informe o CPF';
    }else if(!validateCpf(values.cpf)){
        errors.cpf = 'CPF Inválido';
    }

    if(!values.cel){
        errors.cel = 'Informe o Cel';
    }else if(!validateCel(values.cel)){
        errors.cel = 'Cel inválido';
    }

    if (!values.email) {
        errors.email = 'Informe o email';
    }else if(!validateEmail(values.email)){
        errors.email = 'E-mail inválido';
    }

    return errors;
};
