import React, {useContext, useState} from "react";
import { MDBContainer, MDBBox, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput, MDBInputGroup, MDBBtn, MDBIcon, MDBModalFooter } from "mdbreact";
import AuthContext from "../../../store/auth";


const Login = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState({email: '', password: ''})
    const context = useContext(AuthContext);

    function handleChange(e){
        const { value, id } = e.target;
        setUser({
            ...user,//pega o valor atual
            [id]: value,//sobrescreve apenas o valor alterado
        });
    }

    function handleLogin(){
        context.Login(user.email, user.password);
    }

    return(
        <MDBBox display="flex" justifyContent="center">
                <MDBCard style={{width: '500px', marginTop: '100px'}}>
                    <MDBCardBody className="mx-4">
                        <div className="text-center">
                            <h1 style={{fontSize: '28px', fontWeight: 'bold'}}>Login</h1>
                        </div>
                        <MDBInputGroup
                            material
                            hint="e-mail"
                            type="email"
                            error="wrong"
                            success="right"
                            id="email"
                            name="email"
                            onChange={handleChange}
                        />
                        <MDBInputGroup
                            material
                            hint="senha"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            onChange={handleChange}
                            append={
                                <i className={"fa "+(showPassword ? "fa-eye" : "fa-eye-slash")}
                                   style={{cursor: "pointer", marginTop: "15px"}}
                                   onClick={() => setShowPassword(!showPassword)}
                                />
                            }
                        />
                        <p className="font-small blue-text d-flex justify-content-end pb-3">
                            <a href="#!" className="blue-text ml-1">
                                Esqueci meus dados
                            </a>
                        </p>
                        <div className="text-center mb-3">
                            <MDBBtn
                                type="button"
                                color="primary"
                                rounded
                                className="btn-block z-depth-1a indigo darken-4"
                                onClick={handleLogin}
                                style={{display: context.processing ? 'none' : ''}}
                            >
                                Entrar
                            </MDBBtn>
                            <MDBBtn
                                type="button"
                                color="primary"
                                rounded
                                className="btn-block z-depth-1a"
                                onClick={handleLogin}
                                disabled={true}
                                style={{display: context.processing ? '' : 'none'}}
                            >
                                <MDBIcon icon="spinner" spin/>
                            </MDBBtn>
                        </div>
                        <div className="text-center text-danger">{context.msgLogin}</div>
                    </MDBCardBody>
                </MDBCard>
        </MDBBox>
    );
}

export default Login;

