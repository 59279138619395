import React, {useEffect, useState} from 'react';
import {MDBBadge, MDBCardHeader, MDBCol, MDBIcon, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {MDBCard, MDBCardBody} from "../../styles";
import {formatDate} from "../../../../../utils/date";

const Regra6Relatorio = (props) => {

    const [regra, setRegra] = useState(props.regra);

    useEffect(() => {
        console.log(props.regra);
        setRegra(props.regra);
    }, [props.regra]);

    if(!regra){
        return (<div>&nbsp;</div>)
    }


    return (
        <div>

            <MDBRow>
                <MDBCol>
                    <MDBCard style={{opacity: regra.direito ? 1 : 0.7}}>

                        <div className="row">
                            <div className="col-md-2 col-sm-2 text-center">
                                <div className={"box-regra-alert p-2 "+ (regra.direito ? 'box-regra-alert-on' : 'box-regra-alert-off')} >
                                    <br/>
                                    <div style={{display: regra.direito ? '' : 'none'}}>
                                        <MDBIcon far icon="check-circle" className="fa-4x"/> <br/> <div style={{fontSize: '15px', lineHeight: '18px', margin: '12px 0'}}>Possui direito ao benefício</div>
                                    </div>
                                    <div style={{display: regra.direito ? 'none' : ''}}>
                                        <MDBIcon far icon="clock" className="fa-4x" /> <br/> <div style={{fontSize: '15px', lineHeight: '18px', margin: '12px 0'}}>Não possui direito ao benefício</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-10 col-sm-10">
                                <div className="box-regra-header">
                                    <div className="row">
                                        <div className="col-md-7 col-sm-12 xs-center">
                                            <strong>&nbsp;&nbsp;Regra de Transição 3 - art. 17, EC 103 (Pedágio 50%)</strong>
                                        </div>

                                        <div className="col-md-2 col-sm-6 col-6">
                                            <div className="text-right xs-left">
                                                <div style={{fontSize: '12px', display: regra.direito ? '' : 'none'}}>
                                                    Salário de Benefício
                                                </div>
                                                <div>
                                                    {
                                                        regra.direito ?
                                                            (
                                                                <div className="rmi-v2">
                                                                    R$ {new Intl.NumberFormat('pt-BR', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }).format(regra.salario_beneficio)}
                                                                </div>
                                                            ) : (<span>&nbsp;</span>)
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-right col-md-3 col-sm-6 col-6">
                                            <div style={{fontSize: '12px', display: regra.direito ? '' : 'none'}}>
                                                RMI
                                            </div>
                                            <div>
                                                {
                                                    regra.direito ?
                                                        (
                                                            <div className="rmi-v2">
                                                                R$ {new Intl.NumberFormat('pt-BR', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }).format(regra.rmi)}
                                                                &nbsp;<MDBIcon icon="info-circle" onClick={() => setModalRmi(true)} style={{cursor: 'pointer'}} />
                                                            </div>
                                                        ) : (<span>&nbsp;</span>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr style={{margin: 5, padding: 0}}/>

                                <div className="row box-regra-itens">
                                    <div className="col-md-4">
                                        <div className="grey lighten-2 p-3 min-h">
                                            <span style={{fontSize: '13px', float: 'right'}}><i>Condição 1</i></span>
                                            <strong>Tempo de Contribuição</strong><br/>
                                            <span className="regra-falta" style={{display: regra.direito ? 'none' : ''}}>
                            {regra.falta_condicao_especial_tempo ? regra.falta_condicao_especial_tempo : ''}
                        </span>
                                            <br/>
                                            <span className="regra-possui">
                            {regra.possui_condicao_especial_tempo} em {regra.data_condicao_especial}
                        </span>
                                            <br/>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="grey lighten-2 p-3 min-h">
                                            <strong>Carência</strong>
                                            <span className="regra-falta" style={{display: regra.direito ? 'none' : ''}}>
                                                {regra.falta_contribuicoes > 0 ? "faltam "+regra.falta_contribuicoes : " "}
                                            </span>
                                            <h2 className="regra-possui text-center" style={{fontSize: '35px', fontWeight: 'bold'}}>{regra.atual_contribuicoes}</h2>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="grey lighten-2 p-3 min-h">
                                            {/*/////////Duvida///////*/}
                                            {
                                                regra.condicao_especial ?
                                                    <tr>
                                                        <td>
                                                        <span className="regra-falta" style={{display: regra.direito ? 'none' : ''}}>
                                                            {regra.falta_tempo ? 'faltam '+regra.falta_tempo : ''}
                                                            <br/>
                                                        </span>
                                                            <span className="regra-possui">
                                                            {regra.atual_tempo}
                                                        </span>
                                                            <br/>
                                                            <span style={{fontSize: '13px'}}><i>Condição 2</i></span>
                                                        </td>
                                                        <td>
                                                        <span className="regra-falta" style={{display: regra.direito ? 'none' : ''}}>
                                                            {regra.falta_contribuicoes > 0 ? "faltam "+regra.falta_contribuicoes : " "}
                                                            <br/>
                                                        </span>
                                                            <span className="regra-possui">{regra.atual_contribuicoes}</span>
                                                        </td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    : null
                                            }
                                            {/*////////////////*/}
                                        </div>
                                    </div>
                                </div>


                                <hr style={{margin: 5, padding: 0}}/>

                                <div className="row mt-2 ml-2 mr-2 mb-2" style={{fontSize: '15px'}}>
                                    <div className="col-md-6 col-sm-12">
                                        <strong>Regra (condição 1): </strong>
                                        {regra.regra_condicao_especial_tempo} anos de contribuições em {formatDate(regra.data_condicao_especial)}
                                    </div>
                                    <div className="col-md-6 col-sm-12 text-right col-sm md-t-l">
                                        {
                                            regra.condicao_especial ?
                                                <tr>
                                                    <td colSpan="5" className="regra">
                                                        <strong>Regra (condição 2): </strong>
                                                        {regra.regra_tempo} anos de contribuições e&nbsp;
                                                        <span style={{display: (props.hidePedagio(regra.pedagio)) ? 'none' : '', fontSize: '14px'}}>
                                       + Pedágio de {regra.pedagio} e&nbsp;
                                    </span>
                                                        {regra.regra_contribuicoes} de carência
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                    </MDBCard>
                </MDBCol>
            </MDBRow>



            <MDBRow style={{display: 'none'}}>
                <MDBCol>
                    <MDBCard style={{opacity: regra.direito ? 1 : 0.8}}>
                        <MDBCardHeader color="primary" className={"text-center "+(!regra.direito ? "primary-color-dark" : "success-color-dark")}>
                            <MDBRow>
                                <MDBCol size="11" className="text-left"><strong>{formatDate(props.data)} - Regra de Transição 3 - art. 17, EC 103 (Pedágio 50%)</strong></MDBCol>
                                <MDBCol size="1" className="text-right" style={{cursor: 'pointer'}}>{props.removeButton}</MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol color={(regra.direito ? "success" : "")}>
                                    <p className="texto-direito-beneficio">
                                        De acordo com a simulação&nbsp;
                                        {(regra.direito ? <strong style={{color: "#279e11"}}>possui </strong> : <strong style={{color: "#a80606"}}>não possui </strong>)}
                                        direito ao benefício
                                    </p>
                                    {
                                        regra.direito ?
                                            (
                                                <div className="text-center">
                                                    <p className="rmi">
                                                        RMI: R$ {new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(regra.rmi)}
                                                    </p>
                                                </div>
                                            ) : null
                                    }
                                </MDBCol>
                            </MDBRow>

                            <hr/>
                            <MDBRow>
                                {/*<MDBCol size="12" style={{display: regra.condicao_especial ? '' : 'none'}}>Condição 1</MDBCol>*/}
                                <MDBCol>
                                    <p><strong>Regra 1:</strong></p>
                                    <p>Ter {regra.regra_condicao_especial_tempo} anos de contribuições em {regra.data_condicao_especial}</p>
                                </MDBCol>
                                <MDBCol>
                                    <p><strong>Você Possui:</strong></p>
                                    <p>{regra.possui_condicao_especial_tempo} de contribuições em {regra.data_condicao_especial}</p>
                                    {/*<p style={{fontSize: '13px'}}>Tempo Atual: {regra.atual.tempo} de contribuições</p>*/}
                                </MDBCol>
                                <MDBCol style={{display: regra.condicao_especial ? 'none' : ''}}>
                                    <p><strong>Falta: </strong></p>
                                    {regra.falta_condicao_especial_tempo}
                                </MDBCol>
                            </MDBRow>
                            {
                                !regra.condicao_especial ?
                                    (
                                        <MDBRow>
                                            <MDBCol>&nbsp;</MDBCol>
                                        </MDBRow>
                                    ) :
                                    (
                                        <MDBRow>
                                            <MDBCol size="12">
                                                <hr/>
                                            </MDBCol>
                                            <MDBCol>
                                                <p><strong>Regra 2:</strong></p>
                                                {/*<p>Ter {regra.regraCondicao2.tempo} anos de contribuições</p>*/}
                                                <p>
                                                    {/*Ter {regra.tempoComPedagio['anos']} anos,&nbsp;
                                                {regra.tempoComPedagio['meses']} meses e&nbsp;
                                                {regra.tempoComPedagio['dias']} dias de contribuições*/}
                                                    Ter {regra.regra_tempo} anos
                                                    <div style={{display: (props.hidePedagio(regra.pedagio)) ? 'none' : '', fontSize: '12px'}}>
                                                        {/*{regra.regraCondicao2.tempo} anos +*/}
                                                        + Pedágio de {regra.pedagio}
                                                    </div>
                                                </p>
                                                <p>Ter {regra.regra_contribuicoes} contribuições mensais</p>
                                            </MDBCol>
                                            <MDBCol>
                                                <p><strong>Você Possui:</strong></p>
                                                <p>{regra.atual_tempo} de contribuições</p>
                                                <p>{regra.atual_contribuicoes} contribuições mensais</p>
                                            </MDBCol>
                                            <MDBCol style={{display: regra.direito ? 'none' : ''}}>
                                                <p><strong>Falta: </strong></p>
                                                {regra.falta_tempo}
                                                <p>
                                                    {
                                                        regra.falta_contribuicoes > 0 ?
                                                            regra.falta_contribuicoes+" contribuições mensais" :
                                                            " - "
                                                    }
                                                </p>
                                            </MDBCol>
                                        </MDBRow>
                                    )
                            }

                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            
        </div>
        
    );
};

export default Regra6Relatorio;
