import React, {useEffect, useState} from 'react';
import { Page, Text, View, Document, StyleSheet,PDFDownloadLink, Image, Svg } from '@react-pdf/renderer';
import { styles } from "../stylesPDF";
import TopPdf from "../../../../components/pdf/topPdf";
import calculateAge, {dateToday, formatDate} from "../../../../utils/date";
import {MDBCol, MDBRow} from "mdbreact";
import RegraRelatorio from "./regra";
import RegraPDF from "./regraPDF";
import VinculosPdf from "../vinculosPDF";
import salario from "../../../../assets/salario.png";
import vinculo from "../../../../assets/vinculo.png";


const ExportarPDF = (props) => {
    console.log(props);
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <TopPdf info={"Relatório - "+dateToday()}/>
                {
                    props.titulo ? (
                        <View>
                            <Text>&nbsp;</Text>
                            <Text style={{fontSize: 18, textAlign: 'center'}}>{props.titulo}</Text>
                            <Text>&nbsp;</Text>
                        </View>
                    ) : (<View><Text>&nbsp;</Text></View>)
                }
                {
                    props.cliente ? (
                        <View style={styles.cliente}>
                            <View style={styles.clienteRignt}>
                                <Text style={styles.clienteNome}>
                                    {props.cliente.nome}
                                </Text>
                                <Text style={styles.clienteCpf}>CPF: {props.cliente.cpf}</Text>
                                <Text style={styles.clienteDados}>
                                </Text>
                            </View>
                            <View  style={styles.clienteRignt}>
                                <Text style={{fontSize: 25}}>{props.cliente.nascimento ? calculateAge(props.cliente.nascimento) : null}</Text>
                                <Text style={{fontSize: 13}}>anos</Text>
                            </View>
                        </View>

                    ) : <View><Text>&nbsp;</Text></View>
                }

                {
                    props.elementos.map(
                        (elemento, key) => {
                            //console.log('elemento', elemento);
                            if(elemento.tipo === 1){
                                //console.log('elemento', elemento);
                                let regra = {};
                                let data = null;
                                //console.log(elementos);
                                //console.log(elemento);
                                //console.log(props.datas);
                                for(let i in props.datas){
                                    //console.log(props.datas[i].id, elemento.data_id);
                                    if(props.datas[i].id === elemento.data_id){
                                        data = props.datas[i].data;
                                        //console.log('regras data '+elemento.data_id, datas[i].regras);
                                        //console.log(datas[i].regras[parseInt(elemento.value)-1]);
                                        regra = props.datas[i].regras[parseInt(elemento.value)-1];
                                    }
                                }
                                let removeButton = (
                                    <Text>&nbsp;</Text>
                                );
                                let regraJson = JSON.stringify(regra);
                                console.log('exportarPDF', regra);
                                console.log(elemento.img);
                                return(
                                    <View key={'regra_'+key}>
                                        {/*<RegraPDF regra={regra} data={data}/>*/}
                                        <Image src={elemento.img}/>
                                        {/*<Svg>{elemento.svg}</Svg>*/}
                                        {/*<Text>{elemento.img}</Text>*/}
                                    </View>
                                );
                            }

                            if(elemento.tipo === 2){
                                return(
                                    <View>
                                        <Text style={{fontSize: 12}}>{elemento.value}</Text>
                                    </View>
                                );
                            }


                            /*return (
                                <MDBRow>
                                    <MDBCol>
                                        <SingleSelect
                                            label="Tipo "
                                            options={optionsRegras}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            )*/

                        }
                    )
                }

                </View>
            </Page>
            {
                props.addChartSalarios || props.addChartVinculos ? (
                    <Page>
                        {/*<View>
                            <Text>Gráficos</Text>
                        </View>*/}
                        {
                            props.addChartSalarios ? (
                                <View style={{padding: "30px"}}>
                                    <Text><br/></Text>
                                    <Text style={styles.bg}>
                                        <Image src={salario} alt="salario" width="50%" style={{zIndex: 1}}/>&nbsp;
                                        Salários
                                    </Text>
                                    <Text><br/><br/></Text>
                                    <Image src={props.chartSalariosImage} style={styles.bgImg}/>
                                </View>
                            ) : null
                        }
                        <View>
                            <Text>&nbsp;</Text>
                        </View>
                        {
                            props.addChartVinculos ? (
                                <View style={{padding: "30px"}}>
                                    <Text><br/></Text>
                                    <Text style={styles.bg}>
                                        <Image src={vinculo} alt="salario" width="50%" style={{zIndex: 1}}/>&nbsp;
                                        Vínculos
                                    </Text>
                                    <Text><br/><br/></Text>
                                    <Image src={props.chartVinculosImage}/>
                                </View>
                            ) : null
                        }
                    </Page>
                ) : null
            }
            {
                props.vinculos && props.addVinculos ? (
                    <VinculosPdf vinculos={props.vinculos}/>
                ) : null
            }
        </Document>
    );
};

export default ExportarPDF;
