import React, {useEffect, useState} from 'react';
import api from "../../../services/api";
import {MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import {MDBCard, MDBCardBody} from "../Calculo/styles";
import {SingleSelect} from "react-select-material-ui";

const ListConteudo = (props) => {

    const [loadingItems, setLoadingItems] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [categoria, setCategoria] = useState(0);
    const [search, setSearch] = useState('');
    const [items, setItems] = useState([]);

    useEffect(() => {
        getCategorias();
        list();
    }, []);

    useEffect(() => {
        list();
    }, [categoria, search]);

    async function getCategorias(){
        const result = await api.get('/api/get-categorias');
        let categorias = result.data;
        let options = [{value: 0, label: 'Todas'}];
        for(let i in categorias){
            options.push(
                {value: categorias[i].id, label: categorias[i].titulo}
            );
        }
        setCategorias(options);
    }

    function handleCategoria(value){
        setCategoria(value)
    }

    function handleKeyDownSearch(e){
        if(e.key === 'Enter'){
            setSearch(e.target.value);
        }
    }

    async function list(){
        setLoadingItems(true);
        const result = await api.get('/api/list-conteudos', {
            params: {
                categoria_id: categoria,
                search: search
            }
        });
        setItems(result.data);
        setLoadingItems(false);
    }

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBRow>
                <MDBCol>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol size="4">
                                    <SingleSelect
                                        label="Categoria"
                                        options={categorias}
                                        onChange={handleCategoria}
                                        value={categoria}
                                    />
                                </MDBCol>
                                <MDBCol size="8">
                                    <MDBInput label="Pesquisar" type="text" onKeyDown={handleKeyDownSearch}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                {
                    loadingItems ? (
                            <MDBCol size="12" className="text-center">
                                <MDBIcon icon="spinner" spin size="3x"/>
                            </MDBCol>
                        ) :
                        (
                            items.map((item, index) => {
                                    return (
                                        <MDBCol key={'conteudo_' + index} size="12">
                                            <MDBCard style={{padding: '10px', cursor: 'pointer'}} onClick={() => props.view(item.id)}>
                                                <MDBCardBody>
                                                    <h5>{item.titulo}</h5>
                                                    <hr/>
                                                    <div className="text" style={{fontSize: '16px'}}>
                                                        <div dangerouslySetInnerHTML={{__html: item.descricao}}/>
                                                    </div>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    )
                                }
                            )
                        )
                }
            </MDBRow>
        </MDBContainer>
    );
};

export default ListConteudo;