import React from "react";
import {MDBContainer} from "mdbreact";
import Header from "./Header";
import Footer from "./Footer";

function PublicLayout({children}){
    return(
        <div className="background-site">
            <Header/>
            {children}
            <Footer/>
        </div>
    );
}

export default PublicLayout;