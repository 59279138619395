//
import React, {useEffect, useState, useCallback, useRef} from "react";
import {
    MDBBadge, MDBBtn,
    MDBCardHeader,
    MDBCol,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBRow,
    MDBTabContent,
    MDBTabPane
} from "mdbreact";
import {MDBCard, MDBCardBody} from "./styles";
import './styles.css';
import Regra1 from "./regras/regra1";
import Regra2 from "./regras/regra2";
import Regra3 from "./regras/regra3";
import Regra4 from "./regras/regra4";
import Regra5 from "./regras/regra5";
import Regra6 from "./regras/regra6";
import Regra7 from "./regras/regra7";
import Regra8 from "./regras/regra8";
import {formatDate} from "../../../utils/date";
import PDFRegra from "./regras/pdfRegra";
import useDynamicRefs from "use-dynamic-refs";
import * as htmlToImage from "html-to-image";
import {toPng} from "html-to-image";




const Regras = (props) => {

    const [imgRegras, setImgRegras] = useState([]);

    const [regras, setRegras] = useState({
        REGRA1: {
            direito: false,
            regra:{
                contribuicoes: 0,
                tempo: 0,
                dataLimite: ""
            },
            atual: {
                contribuicoes: 0,
                tempo: ""
            },
            falta: {
                contribuicoes: 0,
                tempo: {anos: 0, meses: 0, dias: 0}
            }
        }
    });

    const [datas, setDatas] = useState([]);
    //const [actives, setActives] = useState({});
    const [activeTabItem, setActiveTabItem] = useState(null);

    const toggle = tab => e => {
        if (activeTabItem !== tab) {
            setActiveTabItem(tab)
        }
    };

    useEffect(() => {
        setRegras(props.regras);
        //console.log(props.regras);
    }, [props.regras]);

    useEffect(() => {
        setActiveTabItem(props.activeTabItem);
        //console.log(props.activeTabItem);
    }, [props.activeTabItem]);

    useEffect(() => {
        //console.log(props.datas);
        setDatas(props.datas);
        setActiveTabItem(props.datas[0].data);
        /*const tempActives = {};
        datas.find((item, index) => {
            tempActives[item.data] = index === 0;
        });
        setActives(tempActives);*/
    }, [props.datas]);

    function imageRegra(id){
        htmlToImage.toPng(document.getElementById(id))
            .then(function (dataUrl) {
                setImgRegras({
                    ...imgRegras,
                    [id]: dataUrl
                });
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    if(datas.length === 0){
        return <div>&nbsp;</div>
    }

    if(!regras[datas[0].data]['REGRA2']){
        return <div>&nbsp;</div>
    }

    /*function falta(tempo, tipo){
        return (tempo.anos < 0 || tempo.meses < 0 || tempo.dias < 0) || (tempo.anos === 0 && tempo.meses === 0 && tempo.dias < 0) ?
            (<p> - </p>) :
            (<p>
                {tempo.anos} anos,&nbsp;
                {tempo.meses} meses e&nbsp;
                {tempo.dias} dias de {tipo}
            </p>)
    }*/

    function falta(tempo, tipo){
        return (tempo.anos < 0 || tempo.meses < 0 || tempo.dias < 0) || (tempo.anos === 0 && tempo.meses === 0 && tempo.dias < 0) ?
            (<span>&nbsp;</span>) :
            (<span>
                faltam&nbsp;
                {tempo.anos}anos,&nbsp;
                {tempo.meses}meses e&nbsp;
                {tempo.dias}dias
            </span>)
    }

    function hidePedagio(tempo){
        return (tempo.anos < 0 || tempo.meses < 0 || tempo.dias < 0) || (tempo.anos === 0 && tempo.meses === 0 && tempo.dias < 0)
    }

    function handleRmi(data, regra, rmi){
        //console.log('handleRmi', data, regra, rmi);
        props.handleRmiRegra(data, regra, rmi);
    }
    function handleSalarioBeneficio(data, regra, salarioBeneficio){
        //console.log('handleSalarioBeneficio', data, regra, salarioBeneficio);
        props.handleSalarioBeneficio(data, regra, salarioBeneficio);
    }

    return (
        <div>
            <MDBNav className="nav-tabs">
                {
                    datas.map((item, index) => {
                        return (
                            <MDBNavItem key={"tabDate"+index}>
                                <MDBNavLink link to="#" active={activeTabItem === item.data} onClick={toggle(item.data)} role="tab">
                                    <strong>{formatDate(item.data)}</strong>
                                </MDBNavLink>
                            </MDBNavItem>
                        );
                    })
                }
            </MDBNav>
            <MDBTabContent activeItem={activeTabItem} style={{fontSize: '18px'}}>
                {
                    datas.map((item, index) => {
                        let calculos = [];
                        for (let i in props.calculosDatas){
                            //console.log(item.data, props.calculosDatas[i].data);
                            if(item.data === props.calculosDatas[i].data){
                                calculos = props.calculosDatas[i];
                            }
                        }
                        return (
                            <MDBTabPane key={'regras'+index} tabId={item.data} role="tabpanel">
                                <MDBRow>
                                    <MDBCol lg="12" xl="12">
                                        <div id={item.data+'regra1'}>
                                            <Regra1 regras={regras[item.data]} falta={falta} vinculos={props.vinculos}
                                                    calculos={calculos} handleRmi={handleRmi} handleSalarioBeneficio={handleSalarioBeneficio} data={item.data}
                                                    salariosMinimos={props.salariosMinimos} salariosTetos={props.salariosTetos}
                                            />
                                        </div>
                                        <MDBBtn style={{display: 'none'}} onClick={() => imageRegra(item.data+'regra1')}>teste imagem regra</MDBBtn>
                                        <img src={imgRegras[item.data+'regra1']} alt="..."/>
                                    </MDBCol>
                                    <MDBCol lg="12" xl="12">
                                        <div>
                                            <Regra2 regras={regras[item.data]} falta={falta} vinculos={props.vinculos}
                                                    calculos={calculos} handleRmi={handleRmi} handleSalarioBeneficio={handleSalarioBeneficio} data={item.data}
                                                    salariosMinimos={props.salariosMinimos} salariosTetos={props.salariosTetos}
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol lg="12" xl="12">
                                        <Regra3 regras={regras[item.data]} falta={falta} vinculos={props.vinculos}
                                                calculos={calculos} handleRmi={handleRmi} handleSalarioBeneficio={handleSalarioBeneficio} data={item.data}
                                                salariosMinimos={props.salariosMinimos} salariosTetos={props.salariosTetos}
                                        />
                                    </MDBCol>
                                    <MDBCol lg="12" xl="12">
                                        <Regra4 regras={regras[item.data]} falta={falta} vinculos={props.vinculos}
                                                calculos={calculos} handleRmi={handleRmi} handleSalarioBeneficio={handleSalarioBeneficio} data={item.data}
                                                salariosMinimos={props.salariosMinimos} salariosTetos={props.salariosTetos}
                                        />
                                    </MDBCol>
                                    <MDBCol lg="12" xl="12">
                                        <Regra5 regras={regras[item.data]} falta={falta} vinculos={props.vinculos}
                                                calculos={calculos} handleRmi={handleRmi} handleSalarioBeneficio={handleSalarioBeneficio} data={item.data}
                                                salariosMinimos={props.salariosMinimos} salariosTetos={props.salariosTetos}
                                        />
                                    </MDBCol>
                                    <MDBCol lg="12" xl="12">
                                        <Regra6 regras={regras[item.data]} falta={falta} vinculos={props.vinculos}
                                                hidePedagio={hidePedagio} calculos={calculos} handleRmi={handleRmi} handleSalarioBeneficio={handleSalarioBeneficio} data={item.data}
                                                salariosMinimos={props.salariosMinimos} salariosTetos={props.salariosTetos}
                                        />
                                    </MDBCol>
                                    <MDBCol lg="12" xl="12">
                                        <Regra7 regras={regras[item.data]} falta={falta} vinculos={props.vinculos}
                                                calculos={calculos} handleRmi={handleRmi} handleSalarioBeneficio={handleSalarioBeneficio} data={item.data}
                                                salariosMinimos={props.salariosMinimos} salariosTetos={props.salariosTetos}
                                        />
                                    </MDBCol>
                                    <MDBCol lg="12" xl="12">
                                        <Regra8 regras={regras[item.data]} falta={falta} vinculos={props.vinculos}
                                                hidePedagio={hidePedagio} calculos={calculos} handleRmi={handleRmi} handleSalarioBeneficio={handleSalarioBeneficio} data={item.data}
                                                salariosMinimos={props.salariosMinimos} salariosTetos={props.salariosTetos}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBTabPane>
                        );
                    })
                }
            </MDBTabContent>

            {/*<Regra1 regras={regras} falta={falta}/>
            <Regra2 regras={regras} falta={falta}/>
            <Regra3 regras={regras} falta={falta}/>
            <Regra4 regras={regras} falta={falta}/>
            <Regra5 regras={regras} falta={falta}/>
            <Regra6 regras={regras} falta={falta} hidePedagio={hidePedagio}/>
            <Regra7 regras={regras} falta={falta}/>
            <Regra8 regras={regras} falta={falta} hidePedagio={hidePedagio}/>*/}
            <br/><br/>
        </div>

    );
}

export default Regras;
