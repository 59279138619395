import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow
} from "mdbreact";
import Card from "./card";
import api from "../../../services/api";
import validateCliente from "./validateCliente";
import Notify from "../../../components/Notify";
import Cnis from "./listCnis";

const NovoCalculo = (props) => {

    const [cnisFile, setCnisFile] = useState(null);
    const [loadingCnis, setLoadingCnis] = useState(false);
    const [cnisModel, setCnisModel] = useState(false);
    const [notify, setNotify] = useState({type:null, text:null});
    const [usoLimite, setUsoLimite] = useState({uso: 0, limite: 0});
    const [loadingLimite, setLoadingLimite] = useState(false);
    const [usoLimiteGb, setUsoLimiteGb] = useState({uso: 0, limite: 0});
    const [loadingLimiteGb, setLoadingLimiteGb] = useState(false);

    useEffect(() => {
        usoCalculos();
        usoGb();
    }, []);

    useEffect(() => {
        //console.log('cnis alterado', cnisFile);
        if(cnisFile){
            importCnis();
        }
    }, [cnisFile])

    async function usoCalculos(){
        setLoadingLimite(true);
        const result = await api.get('/api/get-limite-calculos-user-mes');
        setUsoLimite(result.data);
        setLoadingLimite(false);
    }

    async function usoGb(){
        setLoadingLimiteGb(true);
        const result = await api.get('/api/get-limite-gb-user');
        setUsoLimiteGb(result.data);
        setLoadingLimiteGb(false);
    }

    function selectCnis(){
        setCnisFile(null);
        document.getElementById('cnisFile').value = null;
        document.getElementById('cnisFile').click()
    }

    function handleNotify(notify){
        console.log(notify);
        setNotify(notify);
        setNotify({type: null, text:null});
    }

    function handleCnis(e){
        setCnisFile(e.target.files[0]);
    }

    async function importCnis(){

        setLoadingCnis(true);

        const formData = new FormData();
        formData.append(
            "cnisFile",
            cnisFile,
            cnisFile.name
        );

        const result = await api.post('/api/import-cnis', formData);

        if(!result.data.valido){
            setLoadingCnis(false);
            handleNotify({type: 'warning-text', text: 'Cnis Inválido!'});
            return;
        }

        props.setDadosCnis(result.data);
        setLoadingCnis(false);

    }

    async function loadCnisImportado(id){

        setLoadingCnis(true);

        const result = await api.get('/api/load-cnis-importado/'+id);

        if(!result.data.valido){
            setLoadingCnis(false);
            handleNotify({type: 'warning-text', text: 'Cnis Inválido!'});
            return;
        }

        props.setDadosCnis(result.data);
        setLoadingCnis(false);

    }

    function selectCnisImportado(id){
        setCnisModel(false);
        loadCnisImportado(id);
    }


    return (
        <MDBContainer fluid>
            <Notify notify={notify} />
            <MDBModal isOpen={cnisModel} centered size="lg" toggle={() => setCnisModel(false)}>
                <MDBModalHeader>Cnis</MDBModalHeader>
                <MDBModalBody>
                    <Cnis selectCnis={selectCnisImportado} />
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="default" onClick={() => setCnisModel(false)}>Fechar</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
            <input style={{display: 'none'}} type="file" id="cnisFile" onChange={handleCnis} />
            <MDBRow>
                <MDBCol xl="6" lg="6" md="12" sm="12">
                    <div className="text-center" style={{display: loadingLimite ? '' : 'none'}}>
                        <MDBIcon icon="spinner" spin size="lg"/>
                    </div>
                    <div style={{display: loadingLimite ? 'none' : ''}}>
                        {
                            usoLimite.uso < usoLimite.limite ? (
                                <Card
                                    title="Dados"
                                    img="dados"
                                    teaser="Você pode importar os dados diretamente do CNIS ou inserir manualmente."
                                    icon="plus"
                                    btn="Adicionar"
                                    color="indigo darken-4"
                                    icon2="upload"
                                    btn2={(usoLimiteGb.uso < usoLimiteGb.limite ? "Importar Cnis" : null)}
                                    icon3="list"
                                    btn3="Cnis Importados"
                                    color2="blue darken-2"
                                    clickBtn1={props.showInsert}
                                    clickBtn2={selectCnis}
                                    clickBtn3={() => setCnisModel(true)}
                                />
                            ) : (
                                <div style={{padding: '30px'}}>
                                    <h5>Já foi utilizado o limite de {usoLimite.limite} clientes cadastrados no mês</h5>
                                </div>
                            )
                        }
                    </div>
                </MDBCol>
                <MDBCol xl="6" lg="6" md="12" sm="12">
                    <Card
                        title="Cálculos"
                        img="calculos"
                        teaser="Continue um cálculo pendente ou gere o relatório de um cálculo já finalizado."
                        icon="list"
                        btn="Listar"
                        color="success"
                        clickBtn1={props.showList}
                        icon2={null}
                        btn2={null}
                        color2={null}
                    />
                </MDBCol>
                <MDBCol md="12" className="pt-3 text-center">
                    <br/>
                    O CNIS pode ser baixado em <a href="https://meu.inss.gov.br/" target="_blank">meu.inss.gov.br</a>
                    <br/>
                    <br/>
                </MDBCol>
            </MDBRow>
        </MDBContainer>

    );
};

export default NovoCalculo;