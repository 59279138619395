import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCol,
    MDBIcon, MDBModal,
    MDBModalBody, MDBModalFooter,
    MDBModalHeader, MDBNav, MDBNavItem, MDBNavLink,
    MDBRow, MDBTabContent,
    MDBTable,
    MDBTableBody,
    MDBTableHead, MDBTabPane
} from "mdbreact";
import Contribuicoes from "./contribuicoes";
import api from "../../../services/api";
import {formatDate} from "../../../utils/date";
import Regra1 from "./regras/regra1";
import Regra2 from "./regras/regra2";
import Regra3 from "./regras/regra3";
import Regra4 from "./regras/regra4";
import Regra5 from "./regras/regra5";
import Regra6 from "./regras/regra6";
import Regra7 from "./regras/regra7";
import Regra8 from "./regras/regra8";

const Calculos = (props) => {

    const [remuneracoes, setRemuneracoes] = useState([]);
    const [media80, setMedia80] = useState(null);
    const [media100, setMedia100] = useState(null);
    const [coeficiente, setCoeficiente] = useState(null);
    const [fator, setFator] = useState(null);
    const [modalFatores, setModalFatores] = useState(false);
    const [fatores, setFatores] = useState([]);

    const [calculos2, setCalculos2] = useState([]);

    const [activeTabItem, setActiveTabItem] = useState(null);

    const toggle = tab => e => {
        if (activeTabItem !== tab) {
            setActiveTabItem(tab)
        }
    };


    useEffect(() => {
        setRemuneracoes(props.calculos.remuneracoes);
        setMedia80(props.calculos.media80);
        setMedia100(props.calculos.media100);
        setCoeficiente(props.calculos.coeficiente);
        setFator(props.calculos.fator);
        setCalculos2(props.calculos2);
        if(props.calculos2[0]){
            setActiveTabItem(props.calculos2[0].data);
        }
        getFatores();
    }, [props.calculos, props.calculos2]);

    async function getFatores(){
        const result = await api.get('/api/fator-previdenciario');
        setFatores(result.data)
    }

    return (
        <div>
            <MDBModal isOpen={modalFatores} centered >
                <MDBModalHeader>Fator Previdenciário</MDBModalHeader>
                <MDBModalBody style={{maxHeight: '500px', overflowY: 'scroll'}}>
                    <MDBTable small fixed>
                        <MDBTableHead color="blue darken-4" style={{color: '#FFF'}}>
                            <tr>
                                <th>Ano</th>
                                {/*<th>Sobrevida</th>*/}
                                <th>Tempo</th>
                                <th>Idade</th>
                                <th>Fator</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {
                                fatores.map((item, index) => {
                                    let sobrevida = new Intl.NumberFormat('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(item.sobrevida);
                                    let fator = new Intl.NumberFormat('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(item.fator);
                                    return (
                                        <tr key={"fator"+index}>
                                            <td>{item.ano}</td>
                                            {/*<td>{sobrevida}</td>*/}
                                            <td>{item.tempo}</td>
                                            <td>{item.idade}</td>
                                            <td>{fator}</td>
                                        </tr>
                                    );
                                })
                            }
                        </MDBTableBody>
                    </MDBTable>

                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="default" onClick={() => setModalFatores(false)}>Fechar</MDBBtn>
                </MDBModalFooter>
            </MDBModal>




            <MDBRow>
                <MDBCol size="12">
                    <p>Estão sendo consideradas apenas as contribuições a partir de Julho de 1994.</p>
                    <br/>
                    <MDBNav className="nav-tabs">
                        {
                            calculos2.map((item, index) => {
                                return (
                                    <MDBNavItem key={"tabDate"+index}>
                                        <MDBNavLink link to="#" active={activeTabItem === item.data} onClick={toggle(item.data)} role="tab">
                                            <strong>{formatDate(item.data)}</strong>
                                        </MDBNavLink>
                                    </MDBNavItem>
                                );
                            })
                        }
                    </MDBNav>


                    <MDBTabContent activeItem={activeTabItem}>
                        {
                            calculos2.map((item, index) => {
                                return (
                                    <MDBTabPane key={'regras'+index} tabId={item.data} role="tabpanel">
                                        <br/>
                                        <MDBRow>

                                            <MDBCol size="6">
                                                <MDBTable small fixed>
                                                    <MDBTableHead color="blue darken-4" style={{color: '#FFF'}}>
                                                        <tr>
                                                            <th>Competência</th>
                                                            <th>Original</th>
                                                            <th>Indice</th>
                                                            <th>Atualizado</th>
                                                        </tr>
                                                    </MDBTableHead>
                                                    <MDBTableBody>

                                                        {
                                                            item.remuneracoes.map((remuneracao, index) => {
                                                                /*let acumulado = new Intl.NumberFormat('pt-BR', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }).format(remuneracao.acumulado);*/

                                                                let original = new Intl.NumberFormat('pt-BR', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }).format(remuneracao.original);

                                                                let indice = new Intl.NumberFormat('pt-BR', {
                                                                    minimumFractionDigits: 6,
                                                                    maximumFractionDigits: 6
                                                                }).format(remuneracao.indice);

                                                                let atualizado = new Intl.NumberFormat('pt-BR', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }).format(remuneracao.atualizado);

                                                                return (
                                                                    <tr key={"remuneracao"+index}>
                                                                        <th>{remuneracao.competencia}</th>
                                                                        <th>R$ {original}</th>
                                                                        <th>{indice}</th>
                                                                        <th>R$ {atualizado}</th>
                                                                    </tr>
                                                                );

                                                            })
                                                        }
                                                    </MDBTableBody>
                                                </MDBTable>
                                            </MDBCol>
                                            <MDBCol size="6">
                                                <div>
                                                    {
                                                        item.media80 ?
                                                            (
                                                                <div>
                                                                    <h5><strong>Média 80% maiores</strong></h5>
                                                                    <h3>R$ {item.media80.toString().replace('.', ',')}</h3>
                                                                </div>
                                                            ) :
                                                            (<div>&bnsp;</div>)
                                                    }
                                                </div>
                                                <br/><hr/>
                                                <div>
                                                    {
                                                        item.media100 ?
                                                            (
                                                                <div>
                                                                    <h5><strong>Média 100%</strong></h5>
                                                                    <h3>R$ {item.media100.toString().replace('.', ',')}</h3>
                                                                </div>
                                                            ) :
                                                            (<div>&bnsp;</div>)
                                                    }
                                                </div>

                                                <br/><hr/>
                                                <div>
                                                    {
                                                        item.fator ?
                                                            (
                                                                <div>
                                                                    <h5>
                                                                        <strong>Fator Previdenciário &nbsp;&nbsp;</strong>
                                                                        <MDBIcon
                                                                            icon="info-circle"
                                                                            style={{cursor: 'pointer', fontSize: '20px'}}
                                                                            onClick={() => setModalFatores(true)}
                                                                        />
                                                                    </h5>
                                                                    <h3>
                                                                        {item.fator}
                                                                    </h3>
                                                                </div>
                                                            ) :
                                                            (<div>
                                                                <h5>
                                                                    <strong>Fator Previdenciário &nbsp;&nbsp;</strong>
                                                                    <MDBIcon
                                                                        icon="info-circle"
                                                                        style={{cursor: 'pointer', fontSize: '20px'}}
                                                                        onClick={() => setModalFatores(true)}
                                                                    />
                                                                    <h3>0</h3>
                                                                </h5>
                                                            </div>)
                                                    }
                                                </div>
                                                <br/><hr/>
                                                <div>
                                                    {
                                                        item.coeficiente ?
                                                            (
                                                                <div>
                                                                    <h5><strong>Coeficiente</strong></h5>
                                                                    <h3>{item.coeficiente.coeficiente}%</h3>
                                                                    <div>Cálculo a partir de: {item.coeficiente.anosMinimo} anos</div>
                                                                    <div>Tempo de Contriuição: {item.coeficiente.anos} anos</div>
                                                                    <div>Extra: {item.coeficiente.anosExtra} anos</div>
                                                                </div>
                                                            ) :
                                                            (<div>&bnsp;</div>)
                                                    }
                                                </div>
                                                <br/><hr/>
                                                <div>
                                                    {
                                                        item.rmiFator ?
                                                            (
                                                                <div>
                                                                    <h5><strong>RMI (Fator)</strong></h5>
                                                                    <h3>
                                                                        R$ {
                                                                            new Intl.NumberFormat('pt-BR', {
                                                                                minimumFractionDigits: 2,
                                                                                maximumFractionDigits: 2
                                                                            }).format(item.rmiFator)
                                                                        }
                                                                    </h3>
                                                                </div>
                                                            ) :
                                                            (<div>&bnsp;</div>)
                                                    }
                                                </div>
                                                <br/><hr/>
                                                <div>
                                                    {
                                                        item.rmiCoeficiente ?
                                                            (
                                                                <div>
                                                                    <h5><strong>RMI (Coeficiente)</strong></h5>
                                                                    <h3>
                                                                        R$ {
                                                                            new Intl.NumberFormat('pt-BR', {
                                                                                minimumFractionDigits: 2,
                                                                                maximumFractionDigits: 2
                                                                            }).format(item.rmiCoeficiente)
                                                                        }
                                                                    </h3>
                                                                </div>
                                                            ) :
                                                            (<div>&bnsp;</div>)
                                                    }
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBTabPane>
                                );
                            })
                        }
                    </MDBTabContent>
                </MDBCol>


                {/*<MDBCol size="6">
                    <MDBTable small fixed>
                        <MDBTableHead color="blue darken-4" style={{color: '#FFF'}}>
                            <tr>
                                <th>Competência</th>
                                <th>Original</th>
                                <th>Índice</th>
                                <th>Atualizado</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {
                                remuneracoes.map((item, index) => {
                                    return (
                                        <tr key={"remuneracao"+index}>
                                            <th>{item.competencia}</th>
                                            <th>R$ {item.original.toString().replace('.', ',')}</th>
                                            <th>{item.indice}</th>
                                            <th>R$ {item.atualizado.toString().replace('.', ',')}</th>
                                        </tr>
                                    );
                                })
                            }
                        </MDBTableBody>
                    </MDBTable>
                </MDBCol>
                <MDBCol size="6">
                    <div>
                        {
                            media80 ?
                                (
                                    <div>
                                        <h5><strong>Média 80% maiores</strong></h5>
                                        <h3>R$ {media80.toString().replace('.', ',')}</h3>
                                    </div>
                                ) :
                                (<div>&bnsp;</div>)
                        }
                    </div>
                    <br/><hr/>
                    <div>
                        {
                            media100 ?
                                (
                                    <div>
                                        <h5><strong>Média 100%</strong></h5>
                                        <h3>R$ {media100.toString().replace('.', ',')}</h3>
                                    </div>
                                ) :
                                (<div>&bnsp;</div>)
                        }
                    </div>

                    <br/><hr/>
                    <div>
                        {
                            fator ?
                                (
                                    <div>
                                        <h5>
                                            <strong>Fator Previdenciário &nbsp;&nbsp;</strong>
                                            <MDBIcon
                                                icon="info-circle"
                                                style={{cursor: 'pointer', fontSize: '20px'}}
                                                onClick={() => setModalFatores(true)}
                                            />
                                        </h5>
                                        <h3>
                                            {fator}
                                        </h3>
                                    </div>
                                ) :
                                (<div>
                                    <h5>
                                        <strong>Fator Previdenciário &nbsp;&nbsp;</strong>
                                        <MDBIcon
                                            icon="info-circle"
                                            style={{cursor: 'pointer', fontSize: '20px'}}
                                            onClick={() => setModalFatores(true)}
                                        />
                                        <h3>0</h3>
                                    </h5>
                                </div>)
                        }
                    </div>
                    <br/><hr/>
                    <div>
                        {
                            coeficiente ?
                                (
                                    <div>
                                        <h5><strong>Coeficiente</strong></h5>
                                        <h3>{coeficiente.coeficiente}%</h3>
                                        <div>Cálculo a partir de: {coeficiente.anosMinimo} anos</div>
                                        <div>Tempo de Contriuição: {coeficiente.anos} anos</div>
                                        <div>Extra: {coeficiente.anosExtra} anos</div>
                                    </div>
                                ) :
                                (<div>&bnsp;</div>)
                        }
                    </div>
                </MDBCol>*/}
            </MDBRow>

        </div>

    );
};

export default Calculos;
