import { validateCpf } from "../../../utils/validate";

export default function validateCliente(values) {
    let errors = {};

    //console.log('===========================================');

    if (!values.nome) {
        errors.nome = 'Informe o Nome';
    }

    /*if (!/\d{3}\.\d{3}\.\d{3}-\d{2}/.test(values.cpf)) {
        errors.cpf = 'Cpf Inválido';
    }*/

    if(!values.cpf){
        errors.cpf = 'Informe o CPF';
    }else if(!validateCpf(values.cpf)){
        errors.cpf = 'CPF Inválido';
    }

    if (!values.nascimento) {
        errors.nascimento = 'Informe o nascimento';
    }else{
        const strData = values.nascimento;
        const partesData = strData.split("/");
        const nascimento = new Date(values.nascimento);
        //console.log(nascimento);
        if (nascimento > new Date()) {
            errors.nascimento = 'Data de nascimento inválida';
        }
    }

    if (!values.sexo) {
        errors.sexo = 'Informe o Sexo';
    }

    return errors;
};


