import React, {useEffect, useState} from 'react';
import calculateAge from "../../utils/date";
import {MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import EditButton from "../Buttons/EditButton";
import DeleteButton from "../Buttons/DeleteButton";

const List = (props) => {

    const [search, setSearch] = useState(null);
    const [showEdit, setShowEdit] = useState(true);
    const [showDelete, setShowDelete] = useState(true);
    const [placeholder, setPlaceholder] = useState('Pesquisar');
    const [extraIcons, setExtraIcons] = useState([]);


    useEffect(() => {
        headElements(props.head);
    }, []);

    useEffect(() => {
        if(props.placeholder !== undefined){
            setPlaceholder(props.placeholder);
        }
    }, [props.placeholder]);

    useEffect(() => {
        if(props.showEdit !== undefined){
            setShowEdit(props.showEdit);
        }
    }, [props.showEdit]);

    useEffect(() => {
        if(props.showDelete !== undefined) {
            setShowDelete(props.showDelete);
        }
    }, [props.showDelete]);

    useEffect(() => {
        itemsElements(props.items);
    }, [props.items]);

    useEffect(() => {
        let newExtraIcons = [];
        if(props.extraIcons){
            newExtraIcons = props.extraIcons;
        }
        setExtraIcons(newExtraIcons);
    }, [props.extraIcons]);

    function headElements(head){
        return head.map((item, key) => {
            return <th key={"head"+key}>{item}</th>;
        });
    }

    function itemsElements(items){
        //console.log(items);
        //console.log('columns', props.columns);
        return items.map((item, key) => {
            return (
                <tr key={"cliente"+key}>
                   {/* <th>{item.id}</th>
                    <td>{item.nome}</td>
                    <td>{item.cpf}</td>
                    <td>{calculateAge(item.nascimento)}</td>*/}
                    {
                        props.columns.map((column, index) => {
                            return (<td key={'columnList'+index}>{item[column]}</td>);
                        })
                    }
                    <td>
                        <MDBRow>
                            {
                                extraIcons.map((element) => {
                                    return (
                                        <MDBCol>
                                            {/*<MDBIcon icon={item.icon} onClick={() => item.fnc(item.id)}/>*/}
                                            <MDBIcon
                                                style={{cursor: 'pointer'}}
                                                icon={element.icon} onClick={() => element.fnc(item.id)}
                                            />
                                        </MDBCol>
                                    );
                                })
                            }
                            <MDBCol style={{display: (showEdit && !item.hideEdit) ? '' : 'none'}}>
                                <EditButton to="#" onClick={() => props.edit(item.id)}/>
                            </MDBCol>
                            <MDBCol style={{display: showDelete ? '' : 'none'}}>
                                <DeleteButton onClick={() => props.askDelete(item)}/>
                            </MDBCol>
                        </MDBRow>
                    </td>
                </tr>
            )
        });
    }

    function handleKeyDownSearch(e){
        if(e.key === 'Enter'){
            props.list(e.target.value);
        }
    }

    return (
        <div>
            <MDBInput hint={placeholder} type="text" containerClass="mt-0"  onKeyDown={handleKeyDownSearch}/>
            <div className="text-center" style={{display: (props.loading ? '' : 'none')}}>
                <MDBIcon icon="spinner" spin size="3x"/>
            </div>
            <MDBTable responsiveSm responsiveMd responsiveLg style={{display: (props.loading ? 'none' : '')}}>
                <MDBTableHead>
                    <tr>
                        {headElements(props.head)}
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {itemsElements(props.items)}
                </MDBTableBody>
            </MDBTable>
        </div>
    );
};

export default List;
