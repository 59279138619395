import React, {useEffect, useState} from 'react';
import {MDBBadge, MDBCardHeader, MDBCol, MDBIcon, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {MDBCard, MDBCardBody} from "../../styles";
import {formatDate} from "../../../../../utils/date";

const Regra2Relatorio = (props) => {

    const [regra, setRegra] = useState(props.regra);


    useEffect(() => {
        setRegra(props.regra);
    }, [props.regra]);

    if(!regra){
        return (<div>&nbsp;</div>)
    }

    return (
        <div>


            <MDBRow>
                <MDBCol>
                    <MDBCard style={{opacity: regra.direito ? 1 : 0.7}}>
                        <div className="row">
                            <div className="col-md-2 col-sm-2 text-center">
                                <div className={"box-regra-alert p-2 "+ (regra.direito ? 'box-regra-alert-on' : 'box-regra-alert-off')} >
                                    <br/>
                                    <div style={{display: regra.direito ? '' : 'none'}}>
                                        <MDBIcon far icon="check-circle" className="fa-4x"/> <br/> <div style={{fontSize: '15px', lineHeight: '18px', margin: '12px 0'}}>Possui direito ao benefício</div>
                                    </div>
                                    <div style={{display: regra.direito ? 'none' : ''}}>
                                        <MDBIcon far icon="clock" className="fa-4x" /> <br/> <div style={{fontSize: '15px', lineHeight: '18px', margin: '12px 0'}}>Não possui direito ao benefício</div>
                                    </div>
                                </div>
                            </div>
                            {/*//////////*/}
                            <div className="col-md-10 col-sm-10">
                                <div className="box-regra-header">
                                    <div className="row">
                                        <div className="col-md-7 col-sm-12 xs-center">
                                            <strong>&nbsp;&nbsp;Aposentadoria por idade (Direito Adquirido)</strong>
                                        </div>

                                        <div className="col-md-2 col-sm-6 col-6">
                                            <div className="text-right xs-left">
                                                <div style={{fontSize: '12px', display: regra.direito ? '' : 'none'}}>
                                                    Salário de Benefício
                                                </div>
                                                <div>
                                                    {
                                                        regra.direito ?
                                                            (
                                                                <div className="rmi-v2">
                                                                    R$ {new Intl.NumberFormat('pt-BR', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }).format(salarioBeneficio)}
                                                                </div>
                                                            ) : (<span>&nbsp;</span>)
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-right col-md-3 col-sm-6 col-6">
                                            <div style={{fontSize: '12px', display: regra.direito ? '' : 'none'}}>
                                                RMI
                                            </div>
                                            <div>
                                                {
                                                    regra.direito ?
                                                        (
                                                            <div className="rmi-v2">
                                                                R$ {new Intl.NumberFormat('pt-BR', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }).format(rmi)}
                                                                &nbsp;<MDBIcon icon="info-circle" onClick={() => setModalRmi(true)} style={{cursor: 'pointer'}} />
                                                            </div>
                                                        ) : (<span>&nbsp;</span>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr style={{margin: 5, padding: 0}}/>

                                <div className="row box-regra-itens">
                                    <div className="col-md-4">
                                        <div className="grey lighten-2 p-3 min-h">
                                            <strong>Carência</strong>
                                            <span className="regra-falta" style={{display: regra.direito ? 'none' : ''}}>
                                                {regra.falta_contribuicoes > 0 ? "faltam "+regra.falta_contribuicoes : " "}
                                            </span>
                                            <h2 className="regra-possui text-center" style={{fontSize: '35px', fontWeight: 'bold'}}>{regra.atual_contribuicoes}</h2>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="grey lighten-2 p-3 min-h">
                                            <strong>Idade</strong><br/>
                                            <span className="regra-falta" style={{display: regra.direito ? 'none' : ''}}>
                                                {regra.falta_idade ? 'faltam '+regra.falta_idade : ''}</span>
                                            <br/>
                                            <span className="regra-possui">{regra.atual_idade}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-4">

                                    </div>

                                </div>


                                <hr style={{margin: 5, padding: 0}}/>

                                <div className="row mt-2 ml-2 mr-2 mb-2" style={{fontSize: '15px'}}>
                                    <div className="col-md-6 col-sm-12">
                                        <strong>Regra: </strong>{regra.regra_idade} anos de idade e {regra.regra_contribuicoes} de carência.
                                    </div>
                                    <div className="col-md-6 col-sm-12 text-right col-sm md-t-l">
                                        <span className="regra-obs-v2"><MDBIcon icon="exclamation-triangle" /> OBS: São consideradas apenas as contribuições feitas até {formatDate(regra.regra_data_limite)}</span>
                                    </div>
                                </div>

                            </div>

                            {/*//////////*/}
                        </div>


                    </MDBCard>
                </MDBCol>
            </MDBRow>




            <MDBRow style={{display: 'none'}}>
                <MDBCol>
                    <MDBCard style={{opacity: regra.direito ? 1 : 0.8}}>
                        <MDBCardHeader color="primary" className={"text-center "+(!regra.direito ? "primary-color-dark" : "success-color-dark")}>
                            <MDBRow>
                                <MDBCol size="11" className="text-left"><strong>{formatDate(props.data)} - Aposentadoria por idade (Direito Adquirido)</strong></MDBCol>
                                <MDBCol size="1" className="text-right" style={{cursor: 'pointer'}}>{props.removeButton}</MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol color={(regra.direito ? "success" : "")} className="text-center">
                                    <p className="texto-direito-beneficio">
                                        De acordo com a simulação&nbsp;
                                        {(regra.direito ? <strong style={{color: "#279e11"}}>possui </strong> : <strong style={{color: "#a80606"}}>não possui </strong>)}
                                        direito ao benefício
                                    </p>
                                    <p className="regra-obs">OBS: São consideradas apenas as contribuições feitas até {regra.regra_data_limite}</p>
                                    {
                                        regra.direito ?
                                            (
                                                <div className="text-center">
                                                    <p className="rmi">
                                                        RMI: R$ {new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(regra.rmi)}
                                                    </p>
                                                </div>
                                            ) : null
                                    }
                                </MDBCol>
                            </MDBRow>
                            <hr/>
                            <MDBRow>
                                <MDBCol>
                                    <p><strong>Regra:</strong></p>
                                    <p>Ter {regra.regra_idade} anos de idade</p>
                                    <p>Ter {regra.regra_contribuicoes} contribuições mensais</p>
                                </MDBCol>
                                <MDBCol>
                                    <p><strong>Você Possui:</strong></p>
                                    <p>{regra.atual_idade} de idade</p>
                                    <p>{regra.atual_contribuicoes} contribuições mensais</p>
                                </MDBCol>
                                <MDBCol style={{display: regra.direito ? 'none' : ''}}>
                                    <p><strong>Falta: </strong></p>
                                    {regra.falta_idade}
                                    <p>
                                        {
                                            regra.falta_contribuicoes > 0 ?
                                                regra.falta_contribuicoes+" contribuições mensais" :
                                                " - "
                                        }
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </div>
    );
};

export default Regra2Relatorio;
