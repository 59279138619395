import React, {useEffect, useState} from 'react';
import {MDBBtn, MDBIcon} from "mdbreact";

const CreateButton = (props) => {

    const [text, setText] = useState('Salvar');
    const [buttonStyle, setButtonStyle] = useState({});
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(props.text){
            setText(props.text);
        }
    }, [props.text]);

    useEffect(() => {
        if(props.buttonStyle){
            setButtonStyle(props.buttonStyle);
        }
    }, [props.buttonStyle]);

    return (
        <div>
            <MDBBtn style={{display: (saving ? 'none' : '')}} buttonStyle={buttonStyle} className="px-4" onClick={props.onClick}>
                <MDBIcon icon="plus" />&nbsp;&nbsp;&nbsp;&nbsp;{text}
            </MDBBtn>
            <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                <MDBIcon icon="spinner" spin />&nbsp;&nbsp;{text}
            </MDBBtn>
        </div>

    );
};

export default CreateButton;

