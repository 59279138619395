export const optionsElementos = [
    {value: 1, label: "Regra"},
    {value: 2, label: "Texto"},
    /*{value: 3, label: "Imagem"},*/
];

export const optionsRegras = [
    {value: 1, label: "Aposentadoria por tempo de contribuição (Direito Adquirido)"},
    {value: 2, label: "Aposentadoria por idade (Direito Adquirido)"},
    {value: 3, label: "REGRA GERAL - CF, art. 201, § 7º, I"},
    {value: 4, label: "Regra de Transição 1 - art. 15, EC 103 (Pontos)"},
    {value: 5, label: "Regra de Transição 2 - art. 16, EC 103 (?)"},
    {value: 6, label: "Regra de Transição 3 - art. 17, EC 103 (Pedágio 50%)"},
    {value: 7, label: "Regra de Transição 4 - art. 18, EC 103 (Idade)"},
    {value: 8, label: "Regra de Transição 5 - art. 20, EC 103 (Pedágio 100%)"},
];

