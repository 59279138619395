import React from 'react';
import {MDBBtn, MDBIcon} from "mdbreact";

const ListButton = (props) => {

    return (
        <MDBBtn  color="amber" className="px-4" onClick={props.onClick}>
            <MDBIcon icon="list" />&nbsp;&nbsp;&nbsp;&nbsp;Listar {props.module}
        </MDBBtn>
    );
};

export default ListButton;

