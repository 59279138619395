import React, {useEffect, useState} from 'react';
import api from "../../../services/api";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBProgress,
    MDBRow
} from "mdbreact";
import Card from "./card";
import "./styles.css";

const Limites = () => {

    const [calculos, setCalculos] = useState({limite: 0, uso: 0});
    const [clientes, setClientes] = useState({limite: 0, uso: 0});
    const [gb, setGb] = useState({limite: 0, uso: 0});

    useEffect((item) => {
        usoClientes()
        usoCalculos();
        usoGb();
    }, []);

    async function usoClientes(){
        const result = await api.get('/api/get-limite-clientes-user-mes');
        setClientes(result.data);
    }

    async function usoCalculos(){
        const result = await api.get('/api/get-limite-calculos-user-mes');
        setCalculos(result.data);
    }

    async function usoGb(){
        const result = await api.get('/api/get-limite-gb-user');
        setGb(result.data);
    }

    function porcentagem(valor, total){
        return valor / total * 100;
    }


    return (
        <MDBContainer fluid>
            <br/>
            <MDBRow>
                <MDBCol>
                    <MDBCard>
                        <MDBCardBody>
                            <h5 className="text-center">{clientes.uso} de {clientes.limite} clientes no mês</h5>
                            <MDBProgress value={porcentagem(clientes.uso, clientes.limite)} className="bar1" barClassName="bar2"/>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardBody>
                            <h5 className="text-center">{calculos.uso} de {calculos.limite} cálculos no mês</h5>
                            <MDBProgress value={porcentagem(calculos.uso, calculos.limite)} className="bar1" barClassName="bar2"/>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardBody>
                            <h5 className="text-center">{gb.uso} de {gb.limite} Gb no total</h5>
                            <MDBProgress value={porcentagem(gb.uso, gb.limite)} className="bar1" barClassName="bar2"/>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <br/>
            <hr/>
        </MDBContainer>
    );
};


export default Limites;