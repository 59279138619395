import React, {useEffect, useState} from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";
import "./index.css";

const Planos = () => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        list();
    }, []);

    async function list(search = ''){
        setLoading(true);
        try{
            const result = await api.get('/api/planos-recursos', {params: {search: search}});
            setItems(result.data)
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
        }
    }

    function signup(plano){
        localStorage.setItem('@App:plano', JSON.stringify(plano));
        history.push("/assinar")
    }

    function valorRecurso(recurso){
        switch (recurso.tipo){
            case 1:
                return <p>{recurso.titulo}: {recurso.pivot.valor_int}</p>
            case 2:
                return <p>{recurso.titulo}: {recurso.pivot.valor_dbl}</p>
            case 3:
                return <p>{recurso.valor_str}</p>
        }
    }

    return (
        <MDBContainer>
            <div className="text-center">
                <br/>
                <br/>
                <br/>
                <h1 className="title">Planos</h1>
                <h2 className="sub-title">Confira nossos planos</h2>
            </div>
            <div className="row">
                {items.map((item, index) => {
                    let itemDescricao = item.descricao.split(';');
                    let arrayDescricao = [];
                    itemDescricao.find(function(item){
                        arrayDescricao.push(<hr/>);
                        arrayDescricao.push(item);
                    });

                    return(
                        <div className="col-md-4" key={'p'+index}>
                            <div className="text-center box-assinar" style={{marginTop: "1rem" }}>
                                <div className="box-assinar-m">
                                    <h2 >{item.titulo}</h2>
                                    <MDBCardText>
                                        <hr/><div>{item.clientes} Clientes</div>
                                        <hr/><div>{item.calculos} Cálculos</div>
                                        <hr/><div>{item.gb} Gb</div>
                                        <div>{arrayDescricao}</div>
                                        {item.recursos.map((recurso) => {
                                            return valorRecurso(recurso);
                                        })}
                                    </MDBCardText>
                                    <MDBBtn color="deep-orange" onClick={() => signup(item)}>Assinar</MDBBtn>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </MDBContainer>
    );
};

export default Planos;
