import React, {useContext, useEffect, useState} from "react";
import { BrowserRouter as Router } from "react-router-dom";
//import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import styled from 'styled-components';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from './StyledSideNav';
import { Route, Switch} from 'react-router-dom';
import AuthContext from "../../../store/auth";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function Sidebar({children}){

    const [expanded, setExpanded] = useState(true);
    const { planoExpirado, closeSidebar } = useContext(AuthContext);
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        if(width < 700 || closeSidebar){
            setExpanded(false);
        }
    }, [width, closeSidebar]);

    return(
        <Router>
            <Route render={({ location, history }) => (
                <React.Fragment>
                    {
                        planoExpirado ? null : (
                            <SideNav className="no-print"
                                     expanded={expanded}
                                     onSelect={(selected) => {
                                         const to = '/' + selected;
                                         if (location.pathname !== to) {
                                             history.push(to);
                                         }
                                     }}
                                     onToggle={() => setExpanded(!expanded)}
                            >
                                <Nav defaultSelected="dashboard">
                                    <NavItem eventKey="dashboard">
                                        <NavIcon>
                                            <i className="fa fa-fw fa-tachometer-alt"/>
                                        </NavIcon>
                                        <NavText>
                                            Dashboard
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="clientes">
                                        <NavIcon>
                                            <i className="fa fa-fw fa-users"/>
                                        </NavIcon>
                                        <NavText>
                                            Clientes
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="calculos">
                                        <NavIcon>
                                            <i className="fa fa-fw fa-calculator"/>
                                        </NavIcon>
                                        <NavText>
                                            Cálculos
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="conteudos">
                                        <NavIcon>
                                            <i className="fa fa-fw fa-chart-line"/>
                                        </NavIcon>
                                        <NavText>
                                            Escola de Planejamento
                                        </NavText>
                                    </NavItem>
                                </Nav>
                                {/*<Toggle/>*/}
                                <Toggle className="my-analytics-toggle" >
                                    {expanded ? (
                                        <i className="fa fa-outdent" aria-hidden="true"/>
                                    ) : (
                                        <i className="fa fa-indent" aria-hidden="true"/>
                                    )}
                                </Toggle>
                            </SideNav>
                        )
                    }
                    <main style={{marginLeft: planoExpirado ? '0' : (expanded ? '240px' : '65px')}}>
                        {children}
                        {/*<Route path="/" exact component={props => <RootComponent />} />*/}
                        {/*<Route path="/home" component={props => <Home />} />*/}
                        {/*<Route path="/devices" component={props => <Devices />} />*/}
                    </main>
                </React.Fragment>
            )}
            />
        </Router>
    );
}

export default Sidebar;
