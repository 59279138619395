import React from 'react';
import { styles } from "./stylesPDF";
import TopPdf from "../../../components/pdf/topPdf";
import {dateToday, formatDate} from "../../../utils/date";
import {Document, Page, Text, View} from "@react-pdf/renderer";

const VinculosPdf = (props) => {
    return (

        <Page size="A4" style={styles.page} /*wrap orientation="landscape"*/>
                <View style={styles.section}>
                    <TopPdf info={"Vínculos - "+dateToday()}/>
                    {
                        Object.keys(props.vinculos).length > 0 ?
                            (
                                <View>
                                    <View style={styles.table}>
                                            {
                                                props.vinculos.map((item, index) => {
                                                    let contribuicoesDivergentes = item.qtdContribuicoesPorData !== item.qtdContribuicoesRegistradas;
                                                    return (
                                                        <View key={'salarios-r3-'+index}>
                                                            <View style={styles.bg}>
                                                                <Text style={{fontSize: "12px"}}>{item.origem}</Text>
                                                            </View>
                                                            <View style={styles.flexDirection}>
                                                                <View style={styles.flex}>
                                                                    <Text>&nbsp;</Text>
                                                                    <Text style={{fontSize: "10px"}}>Início</Text>
                                                                    <Text style={styles.tableCell}>{formatDate(item.inicio)}</Text>
                                                                    <Text style={styles.hr}/>
                                                                </View>
                                                                <View style={styles.flexR}>
                                                                    <Text>&nbsp;</Text>
                                                                    <Text style={{fontSize: "10px"}}>Fim</Text>
                                                                    <Text style={styles.tableCell}>{formatDate(item.fim)}</Text>
                                                                    <Text style={styles.hr}/>
                                                                </View>
                                                            </View>

                                                            <View style={styles.flexDirection}>
                                                                <View style={styles.flex}>
                                                                    <Text style={{fontSize: "10px"}}>Normal</Text>
                                                                    <Text style={styles.tableCell}>{item.tempo}</Text>
                                                                    <Text style={styles.hr}/>
                                                                </View>
                                                                <View style={styles.flexR}>
                                                                    <Text style={{fontSize: "10px"}}>Especial</Text>
                                                                    <Text style={styles.tableCell}>{item.tempoEspecial}</Text>
                                                                    <Text style={styles.hr}/>
                                                                </View>
                                                            </View>

                                                            <View style={styles.flexDirection}>
                                                                <View style={styles.flex}>
                                                                    <Text style={{fontSize: "10px"}}>Contrib. Per</Text>
                                                                    <Text style={styles.tableCell}>{item.qtdContribuicoesPorData}</Text>
                                                                    <Text style={styles.hr}/>
                                                                </View>
                                                                <View style={styles.flexR}>
                                                                    <Text style={{fontSize: "10px"}}>Contrib. Reg</Text>
                                                                    <Text style={styles.tableCell}>{item.qtdContribuicoesRegistradas}</Text>
                                                                    <Text style={styles.hr}/>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    );

                                                })
                                            }

                                    </View>
                                </View>
                            ) : null
                    }
                </View>
            </Page>

    );
};

export default VinculosPdf;
