import React from "react";
import { Line } from "react-chartjs-2";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import {Link} from "react-router-dom";

class Chart extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            dataLine: {
                labels: this.props.labels ? this.props.labels : ["January", "February", "March", "April", "May", "June", "July"],
                datasets: [
                    {
                        label: "Cálculos dos últimos 6 meses",
                        fill: true,
                        lineTension: 0.3,
                        backgroundColor: "rgba(184, 185, 210, .3)",
                        borderColor: "rgb(35, 26, 136)",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "rgb(35, 26, 136)",
                        pointBackgroundColor: "rgb(255, 255, 255)",
                        pointBorderWidth: 10,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "rgb(0, 0, 0)",
                        pointHoverBorderColor: "rgba(220, 220, 220, 1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: this.props.data ? this.props.data : [10, 59, 50, 81, 50, 55, 80]
                    }
                ]
            }
        };
    }


    render() {
        return (
            <MDBContainer fluid style={{marginTop: "1.5rem" }}>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>
                            <MDBRow>
                                <MDBCol><strong>Cálculos</strong></MDBCol>
                                <MDBCol className="text-right" style={{fontSize: "12px", fontWeight: 'bold'}}>
                                    {/*<span>7D</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>1M</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>3M</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>1A</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>MÊS</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>ANO</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                                </MDBCol>
                            </MDBRow>
                        </MDBCardTitle>
                        <hr/>
                        <Line data={this.state.dataLine} options={{ responsive: true }} height={80} />
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
        );
    }
}

export default Chart;
