import React, {useContext, useEffect, useState} from 'react';
import api from "../../../../services/api";
import {MDBBtn, MDBCol, MDBRow} from "mdbreact";
import {MDBIcon} from "../../../../components/Buttons/DeleteButton/styles";
import RegraRelatorio from "./regra";
import calculateAge, {formatDate} from "../../../../utils/date";
import ReactDOMServer from 'react-dom/server';
import {pdf} from "@react-pdf/renderer";
import ExportarPDF from "./exportarPDF";
import Vinculos from "../vinculos";
import * as htmlToImage from "html-to-image";
import download from 'downloadjs';
import ChartSalarios from "../charts/chartSalarios";
import ChartVinculos from "../charts/chartVinculos";
import AuthContext from "../../../../store/auth";
import {styles} from "../stylesPDF";

const ExportarRelatorio = (props) => {

    const [elementos, setElementos] = useState([])
    const [datas, setDatas] = useState([]);
    const [cliente, setCliente] = useState(null);
    const [loadingDados, setLoadingDados] = useState(false);
    const [doc, setDoc] = useState(<div>...</div>);
    const [titulo, setTitulo] = useState(null);
    const [subtitulo, setSubtitulo] = useState(null);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [loadingDoc, setLoadingDoc] = useState(false);
    const [showBtnPdf, setShowBtnPdf] = useState(false);
    const [addVinculos, setAddVinculos] = useState(false);
    const [addChartSalarios, setAddChartSalarios] = useState(false);
    const [addChartVinculos, setAddChartVinculos] = useState(false);
    const [imgRegras, setImgRegras] = useState([]);
    const [regrasIds, setRegrasIds] = useState([]);
    const [imgRegrasMounted, setImgRegrasMounted] = useState(false);
    const { setCloseSidebar } = useContext(AuthContext);

    const [salariosMeses, setSalariosMeses] = useState({'meses': [], 'salarios': []});
    const [salariosMinimos, setSalariosMinimos] = useState({'meses': [], 'salarios': []});
    const [totaisVinculos, setTotaisVinculos] = useState({'vinculos': [], 'totais': []});

    useEffect(() => {
        carregarDados();
        setCloseSidebar(true);
    }, []);

    useEffect(() => {
        if(loadingPdf){
            generatePdf();
        }
    }, [loadingPdf]);

    useEffect(() => {
        //mountRegrasIds();
    }, [elementos]);

    useEffect(() => {
        let newImgRegras = [];
        regrasIds.forEach((id) => {
            const img = imageRegra(id);
            newImgRegras.push({id: id, img:img});
        });
        console.log(newImgRegras);
        setImgRegras(newImgRegras);
    }, [regrasIds]);

    async function carregarDados(){
        setLoadingDados(true);
        const result = await api.get("/api/carregar-dados-calculos/"+props.calculoId);
        setCliente(result.data.cliente);
        let datas = result.data.calculos;
        setDatas(datas);

        let vinculos = [];
        let totais = [];
        for (let i in result.data.totaisVinculos) {
            vinculos.push(result.data.totaisVinculos[i].vinculo);
            totais.push(result.data.totaisVinculos[i].total);
        }
        let tempTotaisVinculos = {vinculos: vinculos, totais: totais};
        setTotaisVinculos(tempTotaisVinculos);
        //props.setTotaisVinculos(tempTotaisVinculos);//passa pro component de insert para ser repassado para o de exportar

        //setLoadingDados(false);
        carregarDadosRelatorio()
    }

    async function carregarDadosRelatorio(){
        setImgRegrasMounted(false);
        //setLoadingDados(true);
        const result = await api.get('/api/get-relatorio-calculo/'+props.calculoId);
        //console.log(result.data);
        setTitulo(result.data.titulo);
        setAddVinculos(result.data.vinculos);
        setAddChartSalarios(result.data.chart_salarios);
        setAddChartVinculos(result.data.chart_vinculos);
        if(result.data.itens){
            setElementos(result.data.itens);
            setShowBtnPdf(true);
        }
        setLoadingDados(false);
    }

    function removeElement(){

    }

    async function exportarDoc(){
        setLoadingDoc(true);
        /*const result1 = await api.get('/api/export-doc/');
        const htmlString = ReactDOMServer.renderToStaticMarkup(html);
        const result = await api.post('/api/export-doc/', {conteudo: htmlString});*/
        const elementosImg = await elementosImage();
        try{
            const result = await api.post('/api/export-doc', {
                titulo:titulo,
                cliente: cliente,
                elementos: elementosImg,
                addChartSalarios: addChartSalarios,
                addChartVinculos: addChartVinculos,
                chartSalariosImage: props.chartSalariosImage,
                chartVinculosImage: props.chartVinculosImage,
                addVinculos: addVinculos,
                vinculos: props.vinculos,
            }, {
                responseType: 'blob'
            });
            const content = result.headers['content-type'];
            download(result.data, 'relatorio.docx', content);
            setLoadingDoc(false);
        }catch (error){
            alert('erro');
            console.log(error);
            setLoadingDoc(false);
        }
    }


    function imprimir(){
        window.print();
    }

    async function generatePdf(){
        //console.log(addVinculos);
        /*let imgRegras = [];
        regrasIds.forEach((id) => {
            const img = imageRegra(id);
            imgRegras.push({id: id, img:img});
        });
        console.log(imgRegras);*/
        //return;
        console.log('chamando elementosWithImage')
        const elementosImg = await elementosImage();
        const blob = await pdf(
            <ExportarPDF
                cliente={cliente}
                elementos={elementosImg}
                datas={datas}
                imgRegras = {imgRegras}
                titulo={titulo}
                vinculos={props.vinculos}
                addVinculos={addVinculos}
                addChartSalarios={addChartSalarios}
                addChartVinculos={addChartVinculos}
                chartSalariosImage={props.chartSalariosImage}
                chartVinculosImage={props.chartVinculosImage}
            />
        ).toBlob();
        saveAs(blob, 'relatorio.pdf');
        setLoadingPdf(false);
    }

    function date(){
        var data = new Date(),
            dia  = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0'+dia : dia,
            mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0'+mes : mes,
            anoF = data.getFullYear();
        return diaF+"/"+mesF+"/"+anoF;
    }

    async function elementosImage(){
        if(imgRegrasMounted){
            console.log('as regras já foram convertidas em imagens');
            //return;
        }
        console.log('converter regras em imagens');
        setImgRegrasMounted(true);
        const newRegrasIds = [...regrasIds];
        const elementosImg = await Promise.all(elementos.map(async function (elemento, key) {
            if (elemento.tipo === 1) {
                let regra = {};
                let data = null;
                for (let i in datas) {
                    if (datas[i].id === elemento.data_id) {
                        data = datas[i].data;
                        regra = datas[i].regras[parseInt(elemento.value) - 1];
                    }
                }
                let id = 'key' + key + '-' + data + "-regra" + regra.regra
                /*if(!newRegrasIds.includes(id)){
                    newRegrasIds.push(id);
                }*/
                elemento.img = await imageRegra(id);
                //elemento.svg = await svgRegra(id);
                //console.log(elemento.img);
            }
            return elemento;
        }));
        return elementosImg;
        //setRegrasIds(newRegrasIds);
        //console.log(newElementos);
        //setElementos(newElementos);
        //console.log('set loading dados false');
        //setLoadingDados(false);
    }


     function imageRegra(id){
        return htmlToImage.toPng(document.getElementById(id))
            .then(function (dataUrl) {
                return dataUrl;
            })
            .catch(function (error) {
                console.error('erro imageRegra', error);
            });
    }



    return (
        <div>
            <MDBRow>
                <MDBCol>
                    <h3>
                        Exportar Relatório &nbsp;
                        <MDBBtn onClick={carregarDados}>Carregar Dados p/ Exportação</MDBBtn>
                        {/*<MDBBtn onClick={imprimir} style={{display: 'none'}}>Imprimir</MDBBtn>*/}
                        <MDBBtn onClick={exportarDoc} style={{display: loadingDoc ? 'none' : ''}}>
                            Exportar p/ DOC
                        </MDBBtn>
                        <MDBBtn onClick={exportarDoc} style={{display: loadingDoc ? '' : 'none'}}>
                            <MDBIcon icon="spinner" spin style={{fontSize: "14px"}} /> Exportando ...
                        </MDBBtn>
                        <MDBBtn onClick={() => setLoadingPdf(true)} style={{display: loadingPdf || !showBtnPdf ? 'none' : ''}}>
                            Exportar p/ PDF
                        </MDBBtn>
                        <MDBBtn style={{display: loadingPdf ? '' : 'none'}}>
                            <MDBIcon icon="spinner" spin style={{fontSize: "14px"}} /> Exportando ...
                        </MDBBtn>
                    </h3>
                </MDBCol>
            </MDBRow>
            <div style={{display: loadingDados ? '' : 'none'}} className="text-center">
                <MDBIcon icon="spinner" spin style={{fontSize: "64px"}} />
                <br/><br/>
                <h4>Carregando os dados ...</h4>
                <br/>
            </div>
            <br/>
            <div id="relat" style={{
                border: 'solid 1px #ccc',
                boxShadow: '3px 3px #ccc'
            }} className="divRelatorio">
                <div className="myDivToPrint divRelatorio" style={{backgroundColor: '#fff'}}>
                    <MDBRow className="primary-color" style={{padding: '20px', margin: 0}}>
                        <MDBCol size="9"><strong className="white-text" style={{fontSize: "28px"}}>ICDS Prev</strong></MDBCol>
                        <MDBCol size="3" className="text-right" style={{color: 'white'}}>Relatório {date()}</MDBCol>
                    </MDBRow>
                    <div  style={{padding: '50px',}}>
                        {/*<div>
                            <div style={{
                                backgroundColor: '#CCC',
                                paddingTop: '20px',
                                paddingBottom: '20px',
                                fontSize: '22px',
                                width: '200px',
                                margin: 'auto',
                                textAlign: 'center'
                            }}>Logo</div>
                        </div>
                        <br/><br/>*/}
                        <div style={{textAlign: 'center'}}>
                            <h3>{titulo}</h3>
                        </div>
                        <br/><br/>
                        <MDBRow>
                            <MDBCol size="12">
                                {
                                    cliente ? (
                                        <div className="box-cliente">
                                            <h5>
                                                <strong>Nome: </strong>{cliente.nome}
                                                <strong>&nbsp;&nbsp;&nbsp;&nbsp;CPF: </strong>{cliente.cpf}
                                                <strong>&nbsp;&nbsp;&nbsp;&nbsp;Nascimento: </strong>{cliente.nascimento ? formatDate(cliente.nascimento) : null}
                                                <strong>&nbsp;&nbsp;&nbsp;&nbsp;Idade: </strong>{cliente.nascimento ? calculateAge(cliente.nascimento) : null}
                                            </h5>
                                        </div>
                                    ) : <span>&nbsp;</span>
                                }
                                <br/>
                            </MDBCol>
                            <MDBCol>
                                {
                                    elementos.map(
                                        (elemento, key) => {
                                            //console.log('elemento', elemento);
                                            if(elemento.tipo === 1){
                                                //console.log('elemento', elemento);
                                                let regra = {};
                                                let data = null;
                                                //console.log(elementos);
                                                //console.log(elemento);
                                                for(let i in datas){
                                                    if(datas[i].id === elemento.data_id){
                                                        data = datas[i].data;
                                                        //console.log('regras data '+elemento.data_id, datas[i].regras);
                                                        //console.log(datas[i].regras[parseInt(elemento.value)-1]);
                                                        regra = datas[i].regras[parseInt(elemento.value)-1];
                                                    }
                                                }
                                                let removeButton = (
                                                    <span>&nbsp;</span>
                                                );
                                                let regraJson = JSON.stringify(regra);
                                                //console.log(regra);
                                                //setRegrasIds(...regrasIds, 'key'+key+'-'+data+"-regra"+regra.regra);
                                                //imageRegra('key'+key+'-'+data+"-regra"+regra.regra);
                                                return(
                                                    <MDBRow>
                                                        <MDBCol>
                                                            {/*{regraJson}*/}
                                                            <div style={{padding: '5px'}}>
                                                                <div id={'key'+key+'-'+data+"-regra"+regra.regra}>
                                                                    <RegraRelatorio regra={regra} removeButton={removeButton} removeElement={removeElement} data={data}/>
                                                                    {/*<img src={elemento.img}/>*/}
                                                                </div>
                                                            </div>

                                                        </MDBCol>
                                                    </MDBRow>
                                                );
                                            }

                                            if(elemento.tipo === 2){
                                                return(
                                                    <MDBRow>
                                                        <MDBCol size="11">
                                                            <br/>
                                                            <div>{elemento.value}</div>
                                                            {/*<MDBInput label="Texto" id={'texto'+(key+1)} name={'texto'+(key+1)} onChange={handleTexto}/>*/}
                                                        </MDBCol>
                                                    </MDBRow>
                                                );
                                            }

                                            if(elemento.tipo === 3){
                                                return(
                                                    <MDBRow>
                                                        <MDBCol size="11">
                                                            <br/>
                                                            <Vinculos vinculos={props.vinculos}/>
                                                        </MDBCol>
                                                    </MDBRow>
                                                );
                                            }
                                            /*return (
                                                <MDBRow>
                                                    <MDBCol>
                                                        <SingleSelect
                                                            label="Tipo "
                                                            options={optionsRegras}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                            )*/

                                        }
                                    )
                                }
                            </MDBCol>
                        </MDBRow>
                        <br/>

                        {/*<MDBRow>
                            <MDBCol>
                                <img src={props.chartSalariosImage} alt=""/>
                            </MDBCol>
                            <MDBCol>
                                <img src={props.chartVinculosImage} alt=""/>
                            </MDBCol>
                        </MDBRow>*/}


                        <MDBRow>
                            {
                                addVinculos ? (
                                    <MDBCol>
                                        {
                                            Object.keys(props.vinculos).length > 0 ?
                                                (
                                                    <div>
                                                        <div style={styles.table}>
                                                            {
                                                                props.vinculos.map((item, index) => {
                                                                    let contribuicoesDivergentes = item.qtdContribuicoesPorData !== item.qtdContribuicoesRegistradas;
                                                                    return (
                                                                        <div key={'salarios-r3-'+index}>
                                                                            <div style={styles.bg}>
                                                                                <p style={{fontSize: "12px"}}>{item.origem}</p>
                                                                            </div>
                                                                            <div style={styles.flexDirection}>
                                                                                <div style={styles.flex}>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{fontSize: "10px"}}>Início</p>
                                                                                    <p style={styles.tableCell}>{formatDate(item.inicio)}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                                <div style={styles.flexR}>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{fontSize: "10px"}}>Fim</p>
                                                                                    <p style={styles.tableCell}>{formatDate(item.fim)}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                            </div>

                                                                            <div style={styles.flexDirection}>
                                                                                <div style={styles.flex}>
                                                                                    <p style={{fontSize: "10px"}}>Normal</p>
                                                                                    <p style={styles.tableCell}>{item.tempo}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                                <div style={styles.flexR}>
                                                                                    <p style={{fontSize: "10px"}}>Especial</p>
                                                                                    <p style={styles.tableCell}>{item.tempoEspecial}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                            </div>

                                                                            <div style={styles.flexDirection}>
                                                                                <div style={styles.flex}>
                                                                                    <p style={{fontSize: "10px"}}>Contrib. Per</p>
                                                                                    <p style={styles.tableCell}>{item.qtdContribuicoesPorData}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                                <div style={styles.flexR}>
                                                                                    <p style={{fontSize: "10px"}}>Contrib. Reg</p>
                                                                                    <p style={styles.tableCell}>{item.qtdContribuicoesRegistradas}</p>
                                                                                    <p style={styles.hr}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );

                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                ) : null
                                        }
                                    </MDBCol>
                                ) : null
                            }
                        </MDBRow>

                    </div>
                </div>

            </div>
            <br/><br/><br/>
        </div>
    );
};

export default ExportarRelatorio;
