export function maskCpf(cpf){
    if(!cpf){
        return cpf;
    }
    cpf = cpf.replace(/\D/g, "");

    cpf = cpf.substr(0,11);

    cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2");
    cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2");
    cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2");

    return cpf

}

export function maskCNPJ(cnpj){
    if(!cnpj){
        return cnpj;
    }
    cnpj=cnpj.replace(/\D/g,"");
    cnpj=cnpj.replace(/^(\d{2})(\d)/,"$1.$2");
    cnpj=cnpj.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3");
    cnpj=cnpj.replace(/\.(\d{3})(\d)/,".$1/$2");
    cnpj=cnpj.replace(/(\d{4})(\d)/,"$1-$2");

    return cnpj

}


export function maskCel(cel) {
    if(!cel){
        return cel;
    }
    let r = cel.replace(/\D/g,"");
    //r = r.replace(/^0/,"");
    if(r.length === 0){
        return "";
    }
    if (r.length > 10) {
        // 11+ digits. Format as 5+4.
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/,"($1) $2-$3");
    }
    //else if (r.length > 5) {
    else if (r.length > 6) {
        // 6..10 digits. Format as 4+4
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/,"($1) $2-$3");
    }
    else if (r.length > 2) {
        // 3..5 digits. Add (0XX..)
        r = r.replace(/^(\d\d)(\d{0,5})/,"($1) $2");
    }
    else {
        // 0..2 digits. Just add (0XX
        r = r.replace(/^(\d*)/, "($1");
    }
    return r;
}

export function maskTel(tel) {
    if(!tel){
        return tel;
    }
    let r = tel.replace(/\D/g,"");
    //r = r.replace(/^0/,"");
    if(r.length === 0){
        return "";
    }
    /*if (r.length > 10) {
        // 11+ digits. Format as 5+4.
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*!/,"($1) $2-$3");
    }*/
    //else if (r.length > 5) {
    else if (r.length > 6) {
        // 6..10 digits. Format as 4+4
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/,"($1) $2-$3");
    }
    else if (r.length > 2) {
        // 3..5 digits. Add (0XX..)
        r = r.replace(/^(\d\d)(\d{0,5})/,"($1) $2");
    }
    else {
        // 0..2 digits. Just add (0XX
        r = r.replace(/^(\d*)/, "($1");
    }
    return r;
}

export function maskCep(cep) {
    let r = cep.replace(/\D/g,"");
    //r = r.replace(/^0/,"");
    if(r.length === 0){
        return "";
    }
    if (r.length > 5) {
        // 11+ digits. Format as 5+4.
        r = r.replace(/^(\d{5})(\d{0,3}).*/,"$1-$2");
    } else {
        // 0..2 digits. Just add (0XX
        r = r.replace(/^(\d*)/, "$1");
    }
    return r;
}