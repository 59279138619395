import React, {useState} from 'react';
import {
    MDBBtn,
    MDBCollapse,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBNavItem,
    MDBNavLink
} from "mdbreact";
import logo from "../../../assets/logo-site.png";


const Header = () => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <MDBNavbar expand="sm" className="navbar-site">
                <MDBNavbarBrand>
                    <a href="/">
                        <img src={logo} alt="icdsprev"/>
                    </a>
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={() => setIsOpen(!isOpen)} />
                <MDBCollapse id="navbarSite" isOpen={isOpen} navbar>
                    <MDBNavbarNav right>
                        <MDBNavItem className="menu-home">
                            <MDBNavLink to="#!">Recursos</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem className="menu-home">
                            <MDBNavLink to="planos">Planos</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem className="menu-home">
                            <MDBNavLink to="login">Login</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem className="menu-home">
                            <MDBBtn color="indigo"  size="sm" className="btn-signup" onClick={() => {history.push("/assinar");}}>Assine Agora</MDBBtn>
                        </MDBNavItem>
                        {/*<MDBNavItem className="menu-home" style={{width: '150px'}}>&nbsp;</MDBNavItem>*/}
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        </div>
    );
};

export default Header;