import React from 'react';
import { MDBLink, MDBIcon } from "./styles";

const DeleteButton = (props) => {
    return (
        <MDBLink to="#" onClick={props.onClick}>
            <MDBIcon icon="times" alt="excluir" title="excluir"/>
        </MDBLink>
    );
};

export default DeleteButton;

