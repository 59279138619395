import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBIcon,
    MDBInput,
    MDBRow
} from "mdbreact";
import {SingleSelect} from "react-select-material-ui";
import { optionsSexo, optionsEscolaridade, optionsEstadoCivil } from './options';
import api from "../../../services/api";
import CreateButton from "../../../components/Buttons/CreateButton";
import validate from "./validate";
import Notify from "../../../components/Notify";
import {maskCel, maskCep, maskCpf, maskTel} from "../../../utils/mask";

const Insert = () => {

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});
    const [notify, setNotify] = useState({type:null, text:null});
    const [saveClicked, setSaveClicked] = useState(false);
    const [usoLimite, setUsoLimite] = useState({uso: 0, limite: 0});
    const [loadingLimite, setLoadingLimite] = useState(false);

    useEffect(() => {
        usoClientes();
    }, []);

    useEffect(() => {
        //console.log(errors);
        if (Object.keys(errors).length === 0 && saving) {
            save();
        }
        setSaving(false);
    }, [errors]);

    async function usoClientes(){
        setLoadingLimite(true);
        const result = await api.get('/api/get-limite-clientes-user-mes');
        setUsoLimite(result.data);
        setLoadingLimite(false);
    }

    function handleValidate(){
        setSaveClicked(true);
        setErrors(validate(form));
        setSaving(true);
    }


    function handleForm(event){
        let { value, id } = event.target;
        if(id==='cpf'){
            value = maskCpf(value);
        }
        if(id==='cel'){
            value = maskCel(value);
        }
        if(id==='tel'){
            value = maskTel(value);
        }
        if(id==='tel2'){
            value = maskTel(value);
        }
        if(id==='cep'){
            value = maskCep(value);
        }
        const newForm = {
            ...form,
            [id]: value
        }
        setForm(newForm);
        setErrors(validate(newForm));
    }

    function handleSexo(value){
        const newForm = {
            ...form,
            sexo: value
        }
        setForm(newForm);
        setErrors(validate(newForm));
    }

    function handleEstadoCivil(value){
        setForm({
            ...form,
            estado_civil: value
        })
    }

    function handleNotify(notify){
        setNotify(notify);
        setNotify({type: null, text:null});
    }


    async function save(){
        try{
            const result = await api.post('/api/cliente', {cliente: form});
            //console.log(result.data);
            if(!result.data.valid){
                //notifyWarning(result.data.return);
                handleNotify({type: 'warning', text: result.data.return});
                setSaving(false);
                return;
            }
            setSaving(false);
            handleNotify({type: 'success', text: ''});
        }catch (error){
            console.log(error);
            setSaving(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    const submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <div className="text-center" style={{display: loadingLimite ? '' : 'none'}}>
                <MDBIcon icon="spinner" spin size="lg"/>
            </div>
            <div style={{display: loadingLimite ? 'none' : ''}}>
                {
                    usoLimite.uso < usoLimite.limite ? (
                        <MDBCard>
                            <MDBCardBody>
                                <Notify notify={notify} />
                                <form action="" className="needs-validation" onSubmit={submitHandler} noValidate>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput label="Nome" id="nome" name="nome"
                                                      onChange={handleForm}
                                                      containerClass={errors.nome ? "invalid-input" : ""}
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="CPF" id="cpf" name="cpf"
                                                      onChange={handleForm}
                                                      containerClass={errors.cpf ? "invalid-input" : ""}
                                                      value={form.cpf}
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <SingleSelect
                                                label="Sexo"
                                                options={optionsSexo}
                                                onChange={handleSexo}
                                                className={errors.sexo ? "invalid-select" : ""}
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Nascimento"
                                                type="date"
                                                id="nascimento"
                                                onChange={handleForm}
                                                containerClass={errors.nascimento ? "invalid-input" : ""}
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <SingleSelect
                                                label="Estado Civil"
                                                options={optionsEstadoCivil}
                                                onChange={handleEstadoCivil}
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="Profissão" id="profissao" name="profissao" onChange={handleForm} />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="E-mail" id="email" name="email"
                                                      onChange={handleForm} containerClass={errors.email ? "invalid-input" : ""}/>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="Cel" id="cel" name="cel"
                                                      onChange={handleForm}
                                                      containerClass={errors.cel ? "invalid-input" : ""}
                                                      value={form.cel}
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="Tel" id="tel" name="tel"
                                                      onChange={handleForm}
                                                      containerClass={errors.tel ? "invalid-input" : ""}
                                                      value={form.tel}
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="Tel2" id="tel2" name="tel2"
                                                      onChange={handleForm}
                                                      value={form.tel2}
                                                      containerClass={errors.tel ? "invalid-input" : ""}
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="Cep" id="cep" name="cep"
                                                      onChange={handleForm}
                                                      value={form.cep}
                                                      containerClass={errors.cep ? "invalid-input" : ""}
                                            />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="Endereço" id="endereco" name="endereco" onChange={handleForm} />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="Número" id="numero" name="numero" onChange={handleForm} />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="Complemento" id="complemento" name="complemento" onChange={handleForm} />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="Bairro" id="bairro" name="bairro" onChange={handleForm} />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="Cidade" id="cidade" name="cidade" onChange={handleForm} />
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput label="Estado" id="estado" name="estado" onChange={handleForm} />
                                        </MDBCol>
                                    </MDBRow>
                                    <hr/>
                                    <MDBRow>
                                        <MDBCol className="text-right">
                                            <div style={{display: (saving ? 'none' : '')}}>
                                                <CreateButton onClick={handleValidate} />
                                            </div>
                                            <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                                                <MDBIcon icon="spinner" spin />&nbsp;&nbsp;Salvar
                                            </MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                </form>
                            </MDBCardBody>
                        </MDBCard>
                    ) : (
                        <div style={{padding: '30px'}}>
                            <h5>Já foi utilizado o limite de {usoLimite.limite} clientes cadastrados no mês</h5>
                        </div>
                    )
                }
            </div>
        </MDBContainer>
    );
};

export default Insert;
