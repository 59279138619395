import React, {useEffect, useState} from 'react';
import {MDBCol, MDBIcon, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";

const InfoCalculos = (props) => {

    const [calculos, setCalculos] = useState({});

    useEffect(() => {
        setCalculos(props.calculos);
    }, [props.calculos]);

    return (
        <div>
            {
                Object.keys(calculos).length > 0 /*&& regras['REGRA3'].direito*/ ?
                    (
                        <MDBRow>
                            <MDBCol size="6">
                                <MDBTable small fixed>
                                    <MDBTableHead color="blue darken-4" style={{color: '#FFF'}}>
                                        <tr>
                                            <th>Competência</th>
                                            <th>Original</th>
                                            <th>Indice</th>
                                            <th>Atualizado</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>

                                        {
                                            calculos.remuneracoes.map((remuneracao, index) => {
                                                /*let acumulado = new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format(remuneracao.acumulado);*/

                                                let original = new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format(remuneracao.original);

                                                let indice = new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 6,
                                                    maximumFractionDigits: 6
                                                }).format(remuneracao.indice);

                                                let atualizado = new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format(remuneracao.atualizado);

                                                return (
                                                    <tr key={"remuneracao"+index}>
                                                        <th>{remuneracao.competencia}</th>
                                                        <th>R$ {original}</th>
                                                        <th>{indice}</th>
                                                        <th>R$ {atualizado}</th>
                                                    </tr>
                                                );

                                            })
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCol>
                            <MDBCol size="6">
                                <div>
                                    {
                                        calculos.media80 && props.media === '80' ?
                                            (
                                                <div>
                                                    <h5><strong>Média 80% maiores</strong></h5>
                                                    <h3>R$ {calculos.media80.toString().replace('.', ',')}</h3>
                                                    <br/><hr/>
                                                </div>
                                            ) :
                                            (<div> </div>)
                                    }

                                </div>
                                <div>
                                    {
                                        calculos.media100 && props.media === '100' ?
                                            (
                                                <div>
                                                    <h5><strong>Média 100%</strong></h5>
                                                    <h3>R$ {calculos.media100.toString().replace('.', ',')}</h3>
                                                    <br/><hr/>
                                                </div>
                                            ) :
                                            (<div> </div>)
                                    }

                                </div>
                                <div>
                                    {
                                        props.pontos ?
                                            (
                                                <div>
                                                    <h5>
                                                        <strong>Pontos &nbsp;&nbsp;</strong>
                                                    </h5>
                                                    <h3>
                                                        {props.pontos}
                                                    </h3>
                                                    <br/><hr/>
                                                </div>
                                            ) :
                                            (<div>&nbsp;</div>)
                                    }
                                </div>
                                <div>
                                    {/* Fator:  0 - Não aplica | 1 - aplica | 2 - aplica se for positivo (maior q 1) */}
                                    {
                                        calculos.fator &&
                                        (
                                            props.fator === '1' ||
                                            (props.fator === '2' && calculos.fator > 1) ||
                                            (
                                                props.fator === '3' &&
                                                ( (props.sexo === 1 && props.pontos < 96) || (props.sexo === 2 && props.pontos < 86) )
                                            ) ||
                                            (
                                                props.fator === '3' &&
                                                ( (props.sexo === 1 && props.pontos >= 96 && calculos.fator > 1) || (props.sexo === 2 && props.pontos >= 86 && calculos.fator > 1) )
                                            )
                                        ) ?
                                            (
                                                <div>
                                                    <h5>
                                                        <strong>Fator Previdenciário &nbsp;&nbsp;</strong>
                                                        {/*<MDBIcon
                                                            icon="info-circle"
                                                            style={{cursor: 'pointer', fontSize: '20px'}}
                                                            onClick={() => setModalFatores(true)}
                                                        />*/}
                                                    </h5>
                                                    <h3>
                                                        {calculos.fator}
                                                    </h3>
                                                    <br/><hr/>
                                                </div>
                                            ) :
                                            (<div> </div>)
                                    }
                                </div>

                                <div>
                                    {/* Coeficiente: 0 - Não aplica | 60 - aplica o coeficente60 | 70 - aplica o coeficiente70 */}
                                    {
                                        calculos.coeficiente && props.coeficiente === '60' ?
                                            (
                                                <div>
                                                    <h5><strong>Coeficiente</strong></h5>
                                                    <h3>{calculos.coeficiente.coeficiente}%</h3>
                                                    <div>Cálculo a partir de: {calculos.coeficiente.anosMinimo} anos</div>
                                                    <div>Tempo de Contribuição: {calculos.coeficiente.anos} anos</div>
                                                    <div>Extra: {calculos.coeficiente.anosExtra} anos</div>
                                                    <br/><hr/>
                                                </div>
                                            ) :
                                            (<div> </div>)
                                    }
                                    {
                                        calculos.coeficiente70  && props.coeficiente === '70' ?
                                            (
                                                <div>
                                                    <h5><strong>Coeficiente</strong></h5>
                                                    <h3>{calculos.coeficiente70.coeficiente}%</h3>
                                                    <div>Cálculo a partir de: {calculos.coeficiente70.anosMinimo} anos</div>
                                                    <div>Tempo de Contribuição: {calculos.coeficiente70.anos} anos</div>
                                                    <div>Extra: {calculos.coeficiente70.anosExtra} anos</div>
                                                    <br/><hr/>
                                                </div>
                                            ) :
                                            (<div> </div>)
                                    }
                                </div>

                                <div>
                                    {
                                        props.rmi ?
                                            (
                                                <div>
                                                    <h5><strong>RMI</strong></h5>
                                                    <h3>
                                                        R$ {
                                                        new Intl.NumberFormat('pt-BR', {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        }).format(props.rmi)
                                                    }
                                                    </h3>
                                                </div>
                                            ) :
                                            (<div> </div>)
                                    }
                                </div>
                                <br/><hr/>
                            </MDBCol>
                        </MDBRow>
                    ) : null
            }

        </div>
    );
};

export default InfoCalculos;
