import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCol,
    MDBInput,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow
} from "mdbreact";
//import NumberFormat from "react-number-format";
//import {salarios} from "./salarios";
//import { MDBInput } from "./style-contribuicoes";
import './style-contribuicoes.css'
import api from "../../../services/api";

const Contribuicoes = (props) => {

    const [contribuicoes, setContribuicoes] = useState([]);
    const [indexSeq, setIndexSeq] = useState(0);
    const [salarios, setSalarios] = useState({});
    const [salariosTetos, setSalariosTetos] = useState({});
    const [updated, setUpdated] = useState(false);


    useEffect(() => {
        if(!updated){
            console.log('set contribuições', props.indexSeq);
            setContribuicoes(props.constribuicoes);
            setUpdated(true);
        }
    }, [props.constribuicoes])


    useEffect(() => {
        setIndexSeq(props.indexSeq)
    }, [props.indexSeq])

    useEffect(() => {
        if(Object.keys(props.salariosMinimos).length > 0){
            //console.log('constribuicoes.js', props.salariosMinimos);
            setSalarios(props.salariosMinimos);
        }
    }, [props.salariosMinimos])

    useEffect(() => {
        if(Object.keys(props.salariosTetos).length > 0){
            setSalariosTetos(props.salariosTetos);
        }
    }, [props.salariosTetos])

    /*function salariosMinimos(salariosMinimos){
        console.log('salariosMinimos', salariosMinimos);
        let salariosTemp = {};
        for(let i in salariosMinimos){
            salariosTemp[salariosMinimos[i].mes_ano] = salariosMinimos[i].salario;
        }
        console.log('salariosTemp', salariosTemp);
        setSalarios(salariosTemp);
    }*/

    function removeContribuicao(key){
        //removendo da variavel local do componente
        const newContribuicoes = contribuicoes.filter((item, index) => index !== key);
        setContribuicoes(newContribuicoes);

        //removendo contribuição da sequencia para salvamento posterior
        props.removeContribuicao(indexSeq, key)
    }

    const handleContribuicao = (indexSeq, indexContribuicao) => (e) => {
        if (e.target.id === "remuneracao_"+indexContribuicao)
            e.target.value = maskRemuneracao(e.target.value);
        if (e.target.id === "competencia_"+indexContribuicao)
            e.target.value = formatDate(e.target.value);
        //console.log('value', e.target.value);
        //atualizando os dados em sequencias para salvamento posterior
        props.handleContribuicao(indexSeq, indexContribuicao, e);

        //atualizando variavel contribuicoes do component
        const id = e.target.id.split('_')[0];
        let newContribuicoes = [...contribuicoes];
        newContribuicoes[indexContribuicao] = {
            ...contribuicoes[indexContribuicao],
            [id]: e.target.value
        }
        setContribuicoes(newContribuicoes);
    }

    function factoryDadoContribuicao(){
        return {
            competencia: null,
            remuneracao: "0,00"
        }
    }

    function addContribuicao(index){
        //inserindo item na variavel contribuicoes do component
        let newContribuicoes = [...contribuicoes, factoryDadoContribuicao()];
        setContribuicoes(newContribuicoes);

        //inserindo contribuiçaõ na sequencia para salvamento posterior
        props.addContribuicao(index);
        //console.log(sequencias);
    }

    function maskRemuneracao(value){
        if(!value){
            return value;
        }
        value = value.replace(/\D/g, "");
        value = parseFloat(value/100);

        return value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.")
    }

    function formatDate(value){
        if(!value){
            return value;
        }
        value = value.replace(/\D/g, "");
        value = ("000000"+value).slice(-6);
        return value.replace(/(\d{2})(\d{4})/g, "$1/$2");
    }

    function remuneracaoInferiorMinimo(contribuicao){
        //console.log('salariosMinimos', salarios);
        if(!contribuicao.competencia){
            return false;
        }
        //console.log(contribuicao.remuneracao);
        let remuneracao = contribuicao.remuneracao.replace(',', '');
        //console.log(remuneracao);
        remuneracao = remuneracao.replace('.', '');
        remuneracao = remuneracao.replace('.', '');
        remuneracao = remuneracao.replace('.', '');
        //console.log(remuneracao);
        remuneracao = parseFloat(remuneracao)/100;
        //console.log(remuneracao);
        let ano = contribuicao.competencia.substr(3);
        let mesAno = contribuicao.competencia;
        //console.log(remuneracao, salarios[ano]);
        //return remuneracao < salarios[ano];
        //console.log(contribuicao.remuneracao, mesAno, salarios[mesAno], remuneracao < salarios[mesAno]);
        /*if(remuneracao < salarios[mesAno]){
            console.log(remuneracao, mesAno, salarios[mesAno], remuneracao < salarios[mesAno]);
        }*/
        return remuneracao < salarios[mesAno];
    }

    function remuneracaoSuperiorTeto(contribuicao){
        if(!contribuicao.competencia){
            return false;
        }
        let remuneracao = contribuicao.remuneracao.replace(',', '');
        remuneracao = remuneracao.replace('.', '');
        remuneracao = remuneracao.replace('.', '');
        remuneracao = remuneracao.replace('.', '');
        remuneracao = parseFloat(remuneracao)/100;
        let mesAno = contribuicao.competencia;
        return remuneracao > salariosTetos[mesAno];
    }

    /*function getSalarioMinimo(mesAnoSalario){
        console.log("=========="+mesAnoSalario+"==========");
        if(mesAnoSalario in salarios){
            console.log(mesAnoSalario, salarios[mesAnoSalario]);
            return salarios[mesAnoSalario];
        }
        let anoSalario = mesAnoSalario.split('/')[1];
        for(let mesAnoSalarioMinimo in salarios){
            let anoSalarioMinimo = mesAnoSalarioMinimo.split('/')[1];
            if(anoSalario === anoSalarioMinimo){
                console.log(mesAnoSalarioMinimo);
            }
        }
        console.log("=========================");
    }*/

    return (
        <div>
            {contribuicoes.map((item, key) => {
                return (
                    <div key={"contrib_"+key}>
                        <MDBRow>
                            <MDBCol size="5">
                                <div className="div-md-form">
                                    <MDBInput
                                        label=""
                                        id={"competencia_"+key}
                                        name={"competencia_"+key}
                                        type="text"
                                        /*containerClass={errors.nome ? "invalid-input" : ""}*/
                                        onChange={handleContribuicao(indexSeq, key)}
                                        /*value={formatDate(item.competencia)}*/
                                        value={item.competencia}
                                    />
                                </div>
                            </MDBCol>
                            <MDBCol size="4">
                                <div className="div-md-form">
                                    <MDBInput
                                        label=""
                                        id={"remuneracao_"+key}
                                        name={"remuneracao_"+key}
                                        type="text"
                                        /*containerClass={errors.nome ? "invalid-input" : ""}*/
                                        onChange={handleContribuicao(indexSeq, key)}
                                        value={maskRemuneracao(item.remuneracao)}
                                    />
                                </div>
                            </MDBCol>
                            <MDBCol size="1">
                                <MDBIcon
                                    style={{display: remuneracaoInferiorMinimo(item) ? '' : 'none'}}
                                    icon="exclamation-triangle" className="text-warning"
                                    title="Remuneração abaixo do salário mínimo"
                                />
                                <MDBIcon
                                    style={{display: remuneracaoSuperiorTeto(item) ? '' : 'none'}}
                                    icon="exclamation-triangle" className="text-warning"
                                    title="Remuneração acima do salário de teto"
                                />
                            </MDBCol>
                            <MDBCol size="2" className="text-center">
                                <MDBIcon style={{cursor: 'pointer'}} onClick={() => removeContribuicao(key)} className="text-danger" icon="trash"/>
                            </MDBCol>
                        </MDBRow>
                    </div>
                );
            })}
            <MDBRow>
                <MDBCol className="text-right">
                    <MDBBtn color="primary" size="sm" onClick={() => addContribuicao(indexSeq)}><MDBIcon icon="plus"/>&nbsp;&nbsp;&nbsp;Nova Contribuicao</MDBBtn>
                </MDBCol>
            </MDBRow>
        </div>
    );
};



export default Contribuicoes;
