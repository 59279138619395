import React, {useEffect, useState} from 'react';
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import PageHeader from "../../../components/PageHeader";
import Dados from "./dados";
import AddButton from "../../../components/Buttons/AddButton";
import {useLocation} from "react-router-dom/cjs/react-router-dom";

const Usuario = () => {

    const module = {singular: "Usuário", plural: "Usuários", gender: 1}
    const [crudComponent, setCrudComponent] = useState(<Dados />);
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        console.log(currentPath);
    }, [location]);


    return (
        <MDBContainer fluid>
            <MDBRow>
                <MDBCol>
                    <PageHeader title={module.singular} />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    {crudComponent}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default Usuario;