import React, {useEffect, useState} from 'react';
import {
    MDBBadge,
    MDBBtn,
    MDBCardHeader,
    MDBCol,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow, MDBTable, MDBTableBody, MDBTableHead
} from "mdbreact";
import {MDBCard, MDBCardBody} from "../styles";
import InfoCalculos from "./infoCalculos";
import {PDFDownloadLink, Document, Page, pdf} from '@react-pdf/renderer';
import PdfRegra from "./pdfRegra";
import Regra1PDF from "./regra1PDF";
import PDF from "./pdfRegra";
import Regra3PDF from "./regra3PDF";
import {ajusteSalarioMinimoTeto} from "../../../../utils/salarios";

const Regra1 = (props) => {

    const [regras, setRegras] = useState(props.regras);
    const [corDireitoSim, setCorDireitoSim] = useState("");
    const [corDireitoNao, setCorDireitoNao] = useState("");
    const [calculos, setCalculos] = useState({});
    const [modalRmi, setModalRmi] = useState(false);
    const [modalPdfRegra, setModalPdfRegra] = useState(false);
    const [direito, setDireito] = useState(false);
    const [rmi, setRmi] = useState(0);
    const [salarioBeneficio, setSalarioBeneficio] = useState(0);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [pdfDocument, setPdfDocument] = useState(null);

    useEffect(() => {
        setRegras(props.regras);
        //console.log('props regras use effect', props.regras);
    }, [props.regras]);

    useEffect(() => {
        //console.log('state regras use effect', props.regras);
        if(regras){
            setDireito(regras['REGRA1'].direito);
            setPdfDocument(
                <Regra1PDF regras={regras} falta={props.falta} vinculos={props.vinculos}
                           calculos={props.calculos} handleRmi={props.handleRmi} direito={regras['REGRA1'].direito}
                           handleSalarioBeneficio={props.handleSalarioBeneficio} data={props.data}/>
            );
        }
    }, [regras]);

    useEffect(() => {
        setCalculos(props.calculos);
        if(props.regras){
            calcRmi(props.calculos);
        }
        //console.log(props.calculos);
    }, [props.calculos]);

    useEffect(() => {
        if(loadingPdf){
            generatePdf();
        }
    }, [loadingPdf]);

    if(!regras){
        return (<div>&nbsp;</div>)
    }

    function calcRmiOff(){
        return 0;
    }

    function calcRmi(calculos){
        if(regras === undefined){
            return;
        }
        /*
        - Fator Previdênciario:
		    Não é obrigatório usar quando
		        - idade + tempo contribuição = 86 (mulher) e 96 (homem)
			        Pode usar de forma facultativa (quando for positivo, superior a 1)
		        - no caso de direito adquirido até 31/12/2018
			        - idade + tempo contribuição = 85 (mulher) e 95 (homem)
	    - Média 80
        */
        let newSalarioBeneficio = calculos.media80;

        let newRmi = 0;

        let sexo = regras['REGRA1'].sexo;
        let pontos = regras['REGRA1'].pontos;

        if((sexo === 1 && pontos >= 96) || (sexo === 2 && pontos >= 86)){
            newRmi = calculos.fator > 1 ? newSalarioBeneficio * calculos.fator : newSalarioBeneficio;
            setRmi(newRmi);
            setSalarioBeneficio(newSalarioBeneficio);
            return;
        }
        newRmi = newSalarioBeneficio * calculos.fator;
        newSalarioBeneficio = ajusteSalarioMinimoTeto(newSalarioBeneficio, props.salariosMinimos, props.salariosTetos);
        newRmi = ajusteSalarioMinimoTeto(newRmi, props.salariosMinimos, props.salariosTetos);
        props.handleRmi(props.data, 'REGRA1', newRmi);
        props.handleSalarioBeneficio(props.data, 'REGRA1', newSalarioBeneficio);
        setRmi(newRmi);
        setSalarioBeneficio(newSalarioBeneficio);
    }

    async function generatePdf(){
        if(regras){
            const blob = await pdf(pdfDocument).toBlob();
            saveAs(blob, 'regra1.pdf');
        }
        setLoadingPdf(false);
    }

    return (
        <div>

            <MDBModal isOpen={modalPdfRegra} centered size="sm">
                <MDBModalHeader>PDF Regra</MDBModalHeader>
                <MDBModalBody style={{maxHeight: '100px', overflowY: 'scroll'}}>
                    <p>&nbsp;</p>
                    {
                        modalPdfRegra ?
                            (
                                <Regra1PDF regras={props.regras} falta={props.falta}
                                           calculos={props.calculos} handleRmi={props.handleRmi} handleSalarioBeneficio={props.handleSalarioBeneficio} data={props.data}/>
                            ) : null
                    }
                    {/* Fator:  0 - Não aplica | 1 - aplica | 2 - aplica se for positivo (maior q 1) | 3 - condição especial da Regra 1*/}
                    {/* Coeficiente: 0 - Não aplica | 1 - aplica o coeficente60 | 2 - aplica o coeficiente70 */}
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="default" onClick={() => setModalPdfRegra(false)}>Fechar</MDBBtn>
                </MDBModalFooter>
            </MDBModal>

            <MDBModal isOpen={modalRmi} centered size="lg">
                <MDBModalHeader>Cálculos</MDBModalHeader>
                <MDBModalBody style={{maxHeight: '500px', overflowY: 'scroll'}}>
                    <p>&nbsp;</p>
                    {
                        Object.keys(calculos).length > 0 /*&& regras['REGRA1'].direito*/ ?
                            (
                                <InfoCalculos
                                    calculos={calculos}
                                    media="80"
                                    fator="3"
                                    coeficiente="0"
                                    rmi={rmi}
                                    pontos={regras['REGRA1'].pontos}
                                    sexo={regras['REGRA1'].sexo}
                                />

                            ) : null
                    }
                    {/* Fator:  0 - Não aplica | 1 - aplica | 2 - aplica se for positivo (maior q 1) | 3 - condição especial da Regra 1*/}
                    {/* Coeficiente: 0 - Não aplica | 1 - aplica o coeficente60 | 2 - aplica o coeficiente70 */}
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="default" onClick={() => setModalRmi(false)}>Fechar</MDBBtn>
                </MDBModalFooter>
            </MDBModal>

            <MDBRow>
                <MDBCol>
                    <MDBCard style={{opacity: regras['REGRA1'].direito ? 1 : 0.7}}>
                        <div className="row">
                            <div className="col-md-2 col-sm-2 text-center">
                                <div className={"box-regra-alert p-2 "+ (regras['REGRA1'].direito ? 'box-regra-alert-on' : 'box-regra-alert-off')} >
                                    <br/>
                                    <div style={{display: regras['REGRA1'].direito ? '' : 'none'}}>
                                        <MDBIcon far icon="check-circle" className="fa-4x"/> <br/> <div style={{fontSize: '15px', lineHeight: '18px', margin: '12px 0'}}>Possui direito ao benefício</div>
                                    </div>
                                    <div style={{display: regras['REGRA1'].direito ? 'none' : ''}}>
                                        <MDBIcon far icon="clock" className="fa-4x" /> <br/> <div style={{fontSize: '15px', lineHeight: '18px', margin: '12px 0'}}>Não possui direito ao benefício</div>
                                    </div>
                                    <div className="m-3">
                                        {
                                            loadingPdf ?
                                                <MDBIcon icon="circle-notch" spin size="2x" /> :
                                                <MDBIcon
                                                    className="red-text"
                                                    icon="file-pdf"
                                                    size="2x"
                                                    title="Exportar para PDF"
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => setLoadingPdf(true)}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-10 col-sm-10">
                                <div className="box-regra-header">
                                    <div className="row">
                                        <div className="col-md-7 col-sm-12 xs-center">
                                            <strong>&nbsp;&nbsp;Aposentadoria por tempo de contribuição (Direito Adquirido)</strong>
                                        </div>

                                        <div className="col-md-2 col-sm-6 col-6">
                                            <div className="text-right xs-left">
                                                <div style={{fontSize: '12px', display: /*regras['REGRA1'].direito*/ true ? '' : 'none'}}>
                                                    Salário de Benefício
                                                </div>
                                                <div>
                                                    {
                                                        Object.keys(calculos).length > 0 /*&& regras['REGRA1'].direito*/ ?
                                                            (
                                                                <div className="rmi-v2">
                                                                    R$ {new Intl.NumberFormat('pt-BR', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }).format(salarioBeneficio)}
                                                                </div>
                                                            ) : (<span>&nbsp;</span>)
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-right col-md-3 col-sm-6 col-6">
                                            <div style={{fontSize: '12px', display: /*regras['REGRA1'].direito*/ true ? '' : 'none'}}>
                                                RMI
                                            </div>
                                            <div>
                                                {
                                                    Object.keys(calculos).length > 0 /*&& regras['REGRA1'].direito*/ ?
                                                        (
                                                            <div className="rmi-v2">
                                                                R$ {new Intl.NumberFormat('pt-BR', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }).format(rmi)}
                                                                &nbsp;<MDBIcon icon="info-circle" onClick={() => setModalRmi(true)} style={{cursor: 'pointer'}} />
                                                            </div>
                                                        ) : (<span>&nbsp;</span>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr style={{margin: 5, padding: 0}}/>

                                <div className="row box-regra-itens">
                                    <div className="col-md-4">
                                        <div className="grey lighten-2 p-3 min-h">
                                            <strong>Carência</strong>
                                            <span className="regra-falta" style={{display: direito ? 'none' : ''}}>
                                                {regras['REGRA1'].falta.contribuicoes > 0 ? "faltam "+regras['REGRA1'].falta.contribuicoes : " "}
                                            </span>
                                            <h2 className="regra-possui text-center" style={{fontSize: '35px', fontWeight: 'bold'}}>{regras['REGRA1'].atual.contribuicoes}</h2>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="grey lighten-2 p-3 min-h">
                                            <strong>Tempo de Contribuição</strong><br/>
                                            <span className="regra-falta" style={{display: direito ? 'none' : ''}}>
                                             {props.falta(regras['REGRA1'].falta.tempo, 'contribuições')}</span>
                                            <br/>
                                            <span className="regra-possui">{regras['REGRA1'].atual.tempo}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">

                                    </div>
                                </div>


                                <hr style={{margin: 5, padding: 0}}/>

                                <div className="row mt-2 ml-2 mr-2 mb-2" style={{fontSize: '15px'}}>
                                    <div className="col-md-6 col-sm-12">
                                        <strong>Regra: </strong>{regras['REGRA1'].regra.tempo} anos de tempo de contribuição e {regras['REGRA1'].regra.contribuicoes} de carência.
                                    </div>
                                    <div className="col-md-6 col-sm-12 text-right col-sm md-t-l">
                                        <span className="regra-obs-v2"><MDBIcon icon="exclamation-triangle" /> OBS: São consideradas apenas as contribuições feitas até {regras['REGRA1'].regra.dataLimite}</span>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </MDBCard>
                </MDBCol>
            </MDBRow>


            {/*Layout de Regra Antigo Desativado*/}
            <MDBRow style={{display: 'none'}}>
                <MDBCol>
                    <MDBCard style={{opacity: regras['REGRA1'].direito ? 1 : 0.8}}>
                        <MDBCardHeader color="primary" className={"text-center "+(!regras['REGRA1'].direito ? "primary-color-dark" : "success-color-dark")}>
                            <strong>Aposentadoria por tempo de contribuição (Direito Adquirido)</strong>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol color={(regras['REGRA1'].direito ? "success" : "")} className="text-center">
                                    <p className="texto-direito-beneficio">
                                        De acordo com a simulação&nbsp;
                                        {(regras['REGRA1'].direito ? <strong style={{color: "#279e11"}}>possui </strong> : <strong style={{color: "#a80606"}}>não possui </strong>)}
                                        direito ao benefício
                                    </p>
                                    <p className="regra-obs">OBS: São consideradas apenas as contribuições feitas até {regras['REGRA1'].regra.dataLimite}</p>
                                    {
                                        Object.keys(calculos).length > 0 /*&& regras['REGRA1'].direito*/ ?
                                            (
                                                <div className="text-center">
                                                    <p className="rmi">
                                                        RMI: R$ {new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(rmi)}
                                                        &nbsp;<MDBIcon icon="info-circle" onClick={() => setModalRmi(true)} style={{cursor: 'pointer'}} />
                                                    </p>
                                                </div>
                                            ) : null
                                    }
                                </MDBCol>

                            </MDBRow>
                            <hr/>
                            <MDBRow >
                                <MDBCol></MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <p><strong>Regra:</strong></p>
                                    <p>Ter {regras['REGRA1'].regra.tempo} anos de contribuições</p>
                                    <p>Ter {regras['REGRA1'].regra.contribuicoes} contribuições mensais</p>
                                </MDBCol>
                                <MDBCol>
                                    <p><strong>Você Possui:</strong></p>
                                    <p>{regras['REGRA1'].atual.tempo} de contribuições</p>
                                    <p>{regras['REGRA1'].atual.contribuicoes} contribuições mensais</p>
                                </MDBCol>
                                <MDBCol style={{display: regras['REGRA1'].direito ? 'none' : ''}}>
                                    <p><strong>Falta: </strong></p>
                                    {props.falta(regras['REGRA1'].falta.tempo, 'contribuições')}
                                    <p>
                                        {
                                            regras['REGRA1'].falta.contribuicoes > 0 ?
                                                regras['REGRA1'].falta.contribuicoes+" contribuições mensais" :
                                                " - "
                                        }
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>

        </div>

    );
};

export default Regra1;
