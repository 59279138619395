import React, {useEffect, useState} from 'react';
import api from "../../../services/api";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBContainer,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader
} from "mdbreact";
import List from "../../../components/List";
import { status } from "./options";

const ListCalculo = (props) => {

    const head = ['Id', 'Nome', 'Data', 'Status', ''];
    const columns = ['id', 'nome', 'data', 'status'];
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState(false);
    const [deleteId, setDeleteId] = useState(false);

    useEffect(() => {
        list();
    }, []);

    async function list(search = ''){
        setLoading(true);
        try{
            const result = await api.get('/api/calculo', {params: {search: search}});
            let items = result.data;
            for (let i in items){
                //Descomente esse if para a edição ficar bloqueada quando o cálculo já tiver sido feito.
                //Isso irá impedir do usuário ficar usando o mesmo registro de cálculo várias vezes.
                /*if(items[i].status > 0){
                    items[i].hideEdit = true;
                }*/
                items[i].status = status[items[i].status];
                items[i].nome = items[i].cliente.nome;
                let data = new Date(items[i].created_at);
                let mes = data.getMonth() + 1
                items[i].data = ((data.getDate() )) + "/" + (("0"+mes).slice(-2)) + "/" + data.getFullYear();
            }
            //console.log(items);
            setItems(items)
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
        }
    }

    function edit(id){
        //console.log(id);
        props.edit(id);
    }

    function askDelete(item){
        setDeleteTitle(item.title);
        setDeleteId(item.id);
        setDeleteModal(true);
    }

    async function deleteItem(id){
        setDeleteLoading(true);
        try{
            const result = await api.delete('/api/calculo/'+id);
            setItems(result.data)
            setDeleteLoading(false);
        }catch (error){
            console.log(error);
            setDeleteLoading(false);
        }
    }

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBModal isOpen={deleteModal} centered>
                <MDBModalHeader>Excluir</MDBModalHeader>
                <MDBModalBody>
                    Deseja excluir o item {deleteTitle}?
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="default" onClick={() => setDeleteModal(false)}>Não</MDBBtn>
                    <MDBBtn color="danger" onClick={() => deleteItem(deleteId)}>Sim</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
            <MDBCard>
                <MDBCardBody>
                    <List
                        items={items}
                        head={head}
                        columns={columns}
                        loading={loading}
                        list={list}
                        edit={edit}
                        askDelete={askDelete}
                        showEdit={true}
                        showDelete={false}
                        extraIcons={[
                            {
                                icon: 'file-signature',
                                fnc: function(id){
                                    props.calculado(id);
                                }
                            },
                            {
                                icon: 'file-alt',
                                fnc: function(id){
                                    props.relatorio(id);
                                }
                            },
                            {
                                icon: 'copy',
                                fnc: function(id){
                                    props.duplicar(id);
                                }
                            }
                        ]}
                    />
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default ListCalculo;
