import React, {useEffect, useState} from 'react';
import {MDBBadge, MDBCol, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {MDBIcon} from "../../../components/Buttons/DeleteButton/styles";
import {formatDate} from "../../../utils/date";
import VinculosPdf from "./vinculosPDF";
import {Document, pdf} from "@react-pdf/renderer";

const Vinculos = (props) => {

    const [vinculos, setVinculos] = useState([]);
    const [loadingPdfVinculos, setLoadingPdfVinculos] = useState(false);
    const [pdfVinculos, setPdfVinculos] = useState(null);

    useEffect(() =>{
        if(props.vinculos){
            setVinculos(props.vinculos);
        }
    }, [props.vinculos]);

    useEffect(() => {
        if(loadingPdfVinculos){
            generatePdf();
        }
    }, [loadingPdfVinculos]);

    useEffect(() => {
        setPdfVinculos(
            <Document>
                <VinculosPdf vinculos={vinculos}/>
            </Document>
        );
    }, [vinculos]);

    async function generatePdf(){
        if(vinculos){
            const blob = await pdf(pdfVinculos).toBlob();
            saveAs(blob, 'vinculos.pdf');
        }
        setLoadingPdfVinculos(false);
    }

    return (
        <MDBTable small fixed>
            <MDBTableHead color="indigo darken-4" style={{color: '#FFF'}}>
                <tr>
                    <th width="330">Vínculo.</th>
                    <th className="text-center">Início</th>
                    <th className="text-center">Fim</th>
                    <th>Tempo Normal</th>
                    <th>Tempo Especial</th>
                    <th className="text-center">Contribuições Período</th>
                    <th className="text-center">Contribuições Registradas</th>
                    {
                        props.showIconPdf ? (
                            <th className="text-center">

                                    {
                                        loadingPdfVinculos ?
                                            <MDBIcon icon="circle-notch" spin size="2x" /> :
                                            <MDBIcon

                                                icon="file-pdf"
                                                size="2x"
                                                title="Exportar para PDF"
                                                style={{cursor: 'pointer'}}
                                                onClick={() => setLoadingPdfVinculos(true)}
                                            />
                                    }

                            </th>
                        ) : null
                    }

                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {
                    vinculos.map((item, index) => {
                        let contribuicoesDivergentes = item.qtdContribuicoesPorData !== item.qtdContribuicoesRegistradas;
                        return (
                            [<tr key={"periodos" + index}>
                                <td height="50"><strong>{item.origem}</strong></td>
                                <td className="text-center">
                                    {formatDate(item.inicio)}
                                    {/*<div style={{display: item.concomitante ? '' : 'none'}}>
                                                        <MDBBadge color="amber" className="custom-badge-warning">Concomitante</MDBBadge>
                                                    </div>*/}
                                </td>
                                <td className={"text-center " + (item.obs_fim ? ' alert-fim-automatico' : '')}>{formatDate(item.fim)}</td>
                                <td>
                                    <div>{item.tempo}</div>
                                </td>
                                <td>
                                    <div>{item.tempoEspecial}</div>
                                </td>
                                <td className="text-center">
                                    {item.qtdContribuicoesPorData}&nbsp;
                                    <MDBIcon
                                        style={{
                                            display: contribuicoesDivergentes ? '' : 'none',
                                            fontSize: '12px'
                                        }}
                                        icon="exclamation-triangle" className="text-warning"
                                    />
                                </td>
                                <td className="text-center">
                                    {item.qtdContribuicoesRegistradas}&nbsp;
                                    <MDBIcon
                                        style={{
                                            display: contribuicoesDivergentes ? '' : 'none',
                                            fontSize: '12px'
                                        }}
                                        icon="exclamation-triangle" className="text-warning"
                                    />
                                </td>
                                <td className="text-center">
                                  &nbsp;
                                </td>
                            </tr>,
                                <tr key={"concomitantes" + index} className="tr-concomitante"
                                    style={{display: item.concomitante ? '' : 'none'}}>
                                    <td>&nbsp;</td>
                                    <td colSpan="2">
                                        <MDBBadge color="amber" className="custom-badge-warning"
                                                  style={{
                                                      fontSize: '10px',
                                                      width: '100%',
                                                      position: 'relative',
                                                      top: '-15px'
                                                  }}>Concomitante</MDBBadge>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>,
                                item.obs_fim ? (
                                    <tr className="tr-obs-vinculo">
                                        <td colSpan="7">{item.obs_fim}</td>
                                    </tr>
                                ) : null,

                            ]
                        );
                    })
                }
            </MDBTableBody>
        </MDBTable>
    );
};

export default Vinculos;