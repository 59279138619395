import React, {useEffect, useState} from 'react';
import api from "../../../services/api";
import {
    MDBAlert,
    MDBBadge,
    MDBBtn,
    MDBCardHeader,
    MDBCardText,
    MDBCardTitle,
    MDBCol, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBNav, MDBNavItem, MDBNavLink,
    MDBRow, MDBTabContent,
    MDBTable,
    MDBTableBody,
    MDBTableHead, MDBTabPane
} from "mdbreact";
import {MDBCard, MDBCardBody} from "./styles";
import './styles.css';
import {MDBIcon} from "../../../components/Buttons/DeleteButton/styles";
import calculateAge, { formatDate } from "../../../utils/date";
import Regras from './regras';
import DataCalculo from "./dataCalculo";
import Contribuicoes from "./contribuicoes";
import Calculos from "./calculos";
import ChartSalarios from "./charts/chartSalarios";
import ChartVinculos from "./charts/chartVinculos";
import CreateButton from "../../../components/Buttons/CreateButton";
import {Document, pdf} from "@react-pdf/renderer";
import Regra3PDF from "./regras/regra3PDF";
import VinculosPdf from "./vinculosPDF";
import Vinculos from "./vinculos";
//import Vinculos from "./vinculos";

import imgUser from "../../../assets/user-h.png";
import imgUserM from "../../../assets/user-m.png";

const Calcular = (props) => {

    //const [calculoId, setCalculoId] = useState(props.calculoId);
    const [modalDatasAposentadoria, setModalDatasAposentadoria] = useState(false);
    const [datasAposentadoria, setDatasAposentadoria] = useState([]);
    const [inicioDataCalculo, setInicioDataCalculo] = useState(null);
    const [fimDataCalculo, setFimDataCalculo] = useState(null);
    const [ultimaDataContribuicao, setUltimaDataContribuicao] = useState(null);
    const [fatorDataCalculo, setFatorDataCalculo] = useState(null);
    const [remuneracaoDataCalculo, setRemuneracaoDataCalculo] = useState(null);
    const [medias, setMedias] = useState(null)
    const [tempoIdade, setTempoIdade] = useState(null)
    const [tempoContribuicao, setTempoContribuicao] = useState(null)
    const [totalContribuicoes, setTotalContribuicoes] = useState(null)
    const [totalContribuicoesAte2019, setTotalContribuicoesAte2019] = useState(null)
    const iconInfoCarencia = <MDBIcon icon="info-circle" style={{fontSize: '12px'}}
                                      title="Necessário ter 180 meses de carência"/>
    const [datasCalculos, setDatasCalculos] = useState([]);
    const [vinculos, setVinculos] = useState([]);
    const [regras, setRegras] = useState(null);
    const [loadingTempoContribuicao, setLoadingTempoContribuicao] = useState(false);
    const [activeTabItem, setActiveTabItem] = useState("1");
    const [activeTabItemRegras, setActiveTabItemRegras] = useState(null);
    const [calculos, setCalculos] = useState(null);
    const [calculosDatas, setCalculosDatas] = useState([]);
    const [salariosMeses, setSalariosMeses] = useState({'meses': [], 'salarios': []});
    const [salariosMinimos, setSalariosMinimos] = useState({'meses': [], 'salarios': []});
    const [totaisVinculos, setTotaisVinculos] = useState({'vinculos': [], 'totais': []});
    const [existeContribuicoesDivergentes, setExisteContribuicoesDivergentes] = useState(false);
    const [cliente, setCliente] = useState({nome: '', cpf: ''});
    const [saving, setSaving] = useState(false);
    const [loadingPdfVinculos, setLoadingPdfVinculos] = useState(false);
    const [pdfVinculos, setPdfVinculos] = useState(null);
    const toggle = tab => e => {
        if (activeTabItem !== tab) {
            setActiveTabItem(tab)
        }
    };

    const [generateSalariosImage, setGenerateSalariosImage] = useState(false);
    const [salariosImage, setSalariosImage] = useState(false);
    const [generateVinculosImage, setGenerateVinculosImage] = useState(false);
    const [vinculosImage, setVinculosImage] = useState(false);


    useEffect(() => {
        //console.log(formatDate('1980-05-10'));
        let date = new Date().toISOString();
        //console.log(date);
        date = date.substr(0, 10);
        //console.log(date);
        const tempDatasAposentadoria = [...datasAposentadoria, {
            data: date
        }];
        setDatasAposentadoria(tempDatasAposentadoria);
    }, []);

    useEffect(() => {
        //setCalculoId(props.calculoId);
        if (props.calculoId > 0 && props.etapa === 2) {
            calcularTempo(props.calculoId, datasAposentadoria);
        }
    }, [props.calculoId, props.etapa]);

    useEffect(() => {
        if(loadingPdfVinculos){
            generatePdf();
        }
    }, [loadingPdfVinculos]);

    useEffect(() => {
        setPdfVinculos(
            <Document>
                <VinculosPdf vinculos={vinculos}/>
            </Document>
        );
    }, [vinculos]);

    async function generatePdf(){
        //if(regras){
        if(vinculos){
            const blob = await pdf(pdfVinculos).toBlob();
            saveAs(blob, 'vinculos.pdf');
        }
        setLoadingPdfVinculos(false);
    }

    async function calcular() {
        const result = await api.post('/api/calcular', {calculoId: calculoId});
        //console.log(result.data);
        setMedias(result.data);
    }

    //{data: "2025-01-01", inicio:'2021-05-01', fator: 1}
    function addDatasAposentadoria() {
        setModalDatasAposentadoria(false);
        const tempDatasAposentadoria = [...datasAposentadoria, {
            inicio: inicioDataCalculo,
            data: fimDataCalculo,
            fator: fatorDataCalculo,
            remuneracao: remuneracaoDataCalculo
        }];
        setDatasAposentadoria(tempDatasAposentadoria);
        calcularTempo(props.calculoId, tempDatasAposentadoria);
    }

    async function calcularTempo(id, datasAposentadoria = []) {

        setLoadingTempoContribuicao(true);
        const result = await api.post('/api/calcular-tempo', {calculoId: id, datasAposentadoria: datasAposentadoria})

        //console.log(result.data);
        setDatasCalculos(result.data.temposTotais);
        setVinculos(result.data.vinculos);
        props.setTemposVinculos(result.data.vinculos);
        //console.log(result.data.vinculos);
        setUltimaDataContribuicao(result.data.vinculos[result.data.vinculos.length - 1].fim)
        setFatorDataCalculo(result.data.vinculos[result.data.vinculos.length - 1].fator)
        setRegras(result.data.regras);
        setCalculos(result.data.calculos);
        setCalculosDatas(result.data.calculos2);
        setCliente(result.data.cliente);

        let meses = [];
        let salarios = [];
        let salariosMinimos = [];
        for (let i in result.data.salariosMeses) {
            meses.push(i);
            salarios.push(result.data.salariosMeses[i]);
            salariosMinimos.push(result.data.salariosMinimos[i]);
        }
        let tempSalariosMeses = {meses: meses, salarios: salarios};
        let tempSalariosMinimos = {meses: meses, salarios: salariosMinimos};
        //console.log('salariosMeses', tempSalariosMeses);
        //console.log('salarioasMinimos', tempSalariosMinimos);
        setSalariosMeses(tempSalariosMeses);
        props.setSalariosMeses(tempSalariosMeses);//passa pro component de insert para ser repassado para o de exportar
        setSalariosMinimos(tempSalariosMinimos);
        props.setSalariosMinimosMeses(tempSalariosMinimos);//passa pro component de insert para ser repassado para o de exportar

        let vinculos = [];
        let totais = [];
        for (let i in result.data.totaisVinculos) {
            vinculos.push(result.data.totaisVinculos[i].vinculo);
            totais.push(result.data.totaisVinculos[i].total);
        }
        let tempTotaisVinculos = {vinculos: vinculos, totais: totais};
        //console.log(tempTotaisVinculos);
        setTotaisVinculos(tempTotaisVinculos);
        props.setTotaisVinculos(tempTotaisVinculos);//passa pro component de insert para ser repassado para o de exportar

        result.data.vinculos.find((item) => {
            if (item.qtdContribuicoesPorData !== item.qtdContribuicoesRegistradas) {
                setExisteContribuicoesDivergentes(true);
            }
        });

        setLoadingTempoContribuicao(false);
        //setTempoIdade(result.data.idade);
        //setTempoContribuicao(result.data.tempo_contribuicao);
        //setTotalContribuicoes(result.data.total_contribuicoes)
        //setTotalContribuicoesAte2019(result.data.total_contribuicoes_13112019)
    }

    function handleRmiRegra(data, regra, rmi) {
        if(data){
            //console.log(regra, rmi, regras);
            let newRegras = regras;
            newRegras[data][regra].rmi = rmi;
            setRegras(newRegras);
        }
    }

    function handleSalarioBeneficio(data, regra, salarioBeneficio) {
        if(data){
            //console.log(regra, salarioBeneficio, regras);
            let newRegras = regras;
            newRegras[data][regra].salarioBeneficio = salarioBeneficio;
            setRegras(newRegras);
        }
    }

    function regrasComponent() {
        //console.log('REGRAS', regras);
        if (regras) {
            return <Regras
                activeTabItem={activeTabItemRegras}
                datas={datasAposentadoria}
                regras={regras}
                calculosDatas={calculosDatas}
                handleRmiRegra={handleRmiRegra}
                handleSalarioBeneficio={handleSalarioBeneficio}
                vinculos={vinculos}
                salariosMinimos={props.salariosMinimos}
                salariosTetos={props.salariosTetos}
            />
        }
        return null;
    }

    function calculosComponent() {
        //console.log('Calculos', calculos);
        if (calculos) {
            return <Calculos calculos={calculos} calculos2={calculosDatas}/>
        }
        return null;
    }

    function handleInicioDataCalculo(e) {
        setInicioDataCalculo(e.target.value);
    }

    function handleFimDataCalculo(e) {
        setFimDataCalculo(e.target.value);
    }

    function handleFatorDataCalculo(e) {
        setFatorDataCalculo(e.target.value);
    }

    function handleRemuneracaoDataCalculo(e) {
        setRemuneracaoDataCalculo(e.target.value);
    }

    function handleValidate() {
        setGenerateSalariosImage(true);
        setGenerateVinculosImage(true);
        save();
    }

    async function save() {
        setSaving(true);
        //console.log('save', regras);
        const result = await api.post('/api/salvar-calculos', {
            regras: regras,
            datas: datasAposentadoria,
            calculos: calculosDatas,
            calculo_id: props.calculoId,
        });
        //console.log('result', result);
        setSaving(false);
        props.setEtapa(3);
        // if(result.data.return){
        //     //setEtapa(3);
        // }

    }

    function getSalariosImage(image){
        setSalariosImage(image);
        props.setChartSalariosImage(image);
    }

    function getVinculosImage(image){
        setVinculosImage(image);
        props.setChartVinculosImage(image);
    }

    return (
        <div>

            <MDBModal isOpen={modalDatasAposentadoria} centered size="lg">
                <MDBModalHeader>Nova Data para Cálculo</MDBModalHeader>
                <MDBModalBody style={{maxHeight: '500px', overflowY: 'scroll'}}>
                    <p>Será criado um período fictício para projetar o cálculo do tempo em uma data futura.</p>
                    <MDBRow>
                        <MDBCol size="4">
                            <MDBInput
                                label="Inicio"
                                type="date"
                                id="inicio"
                                onChange={handleInicioDataCalculo}
                                valueDefault={ultimaDataContribuicao}
                            />
                        </MDBCol>
                        <MDBCol size="4">
                            <MDBInput
                                label="Fim"
                                type="date"
                                id="fim"
                                onChange={handleFimDataCalculo}
                            />
                        </MDBCol>
                        <MDBCol size="1">
                            <MDBInput
                                label="Fator"
                                type="text"
                                id="fator"
                                onChange={handleFatorDataCalculo}
                                value={fatorDataCalculo}
                            />
                        </MDBCol>
                        <MDBCol size="3">
                            <MDBInput
                                label="Remuneração"
                                type="text"
                                id="remuneracao"
                                onChange={handleRemuneracaoDataCalculo}
                                value={remuneracaoDataCalculo}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="default" onClick={() => addDatasAposentadoria(false)}>Calcular Nova Data</MDBBtn>
                    <MDBBtn color="default" onClick={() => setModalDatasAposentadoria(false)}>Cancelar</MDBBtn>
                </MDBModalFooter>
            </MDBModal>

            <div>
                <MDBRow>
                    <MDBCol size="6">
                        <div className="box-cliente">
                            <img src={1 !== 2 ? imgUser :  imgUserM} alt="" title="" width="100"/>
                            <p><strong>Nome:</strong> {cliente.nome}</p>
                            <p><strong>CPF:</strong> {cliente.cpf}</p>
                            <p><strong>Nascimento:</strong> {cliente.nascimento ? formatDate(cliente.nascimento) : null}</p>
                            <p><strong>Idade:</strong> {cliente.nascimento ? calculateAge(cliente.nascimento) : null}</p>
                        </div>
                        {/*<div>
                            <div className="dateStyle">
                                <h2>18</h2>
                                <p>anos</p>
                            </div>
                            <div className="dateStyle">
                                <h2>09</h2>
                                <p>meses</p>
                            </div>
                            <div className="dateStyle">
                                <h2>21</h2>
                                <p>dias</p>
                            </div>
                        </div>*/}
                    </MDBCol>
                    <MDBCol size="6">
                        <div style={{textAlign: 'right', display: (saving ? 'none' : '')}}>
                            <CreateButton text="Salvar e Continuar" onClick={handleValidate}/>
                        </div>
                        <div style={{textAlign: 'right', display: (saving ? '' : 'none')}}>
                            <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                                <MDBIcon icon="spinner" spin/>&nbsp;&nbsp;Salvar e Continuar
                            </MDBBtn>
                        </div>

                    </MDBCol>
                </MDBRow>

                <br/>
            </div>
            {/*<div>
                <h4 className="text-center" style={{marginTop: '15px'}}>Tempo de Contribuição e Simulação das Regras</h4>
                <hr/>
            </div>*/}
            <br/>
            <div className="text-center" style={{display: 'none'}}>
                <MDBBtn onClick={() => calcularTempo(props.calculoId, datasAposentadoria)}>Calcular Tempo e
                    Contribuições</MDBBtn>
                <br/><br/>
            </div>
            <div style={{display: loadingTempoContribuicao ? '' : 'none'}} className="text-center">
                <MDBIcon icon="spinner" spin style={{fontSize: "64px"}}/>
                <br/><br/>
                <h4>Processando os dados ...</h4>
                <br/>
            </div>

            <MDBNav className="nav-tabs nav-pills nav-fill" style={{display: loadingTempoContribuicao ? 'none' : ''}}>
                <MDBNavItem>
                    <MDBNavLink link to="#" active={activeTabItem === "1"} onClick={toggle("1")} role="tab">
                        Tempos e Períodos
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink link to="#" active={activeTabItem === "2"} onClick={toggle("2")} role="tab">
                        Regras
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem style={{display: 'none'}}>
                    <MDBNavLink link to="#" active={activeTabItem === "3"} onClick={toggle("3")} role="tab">
                        Cálculos
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink link to="#" active={activeTabItem === "4"} onClick={toggle("4")} role="tab">
                        Gráficos
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink link to="#" active={activeTabItem === "5"} onClick={toggle("5")} role="tab">
                        Observações
                    </MDBNavLink>
                </MDBNavItem>

            </MDBNav>

            <MDBTabContent activeItem={activeTabItem}>
                <MDBTabPane tabId="1" role="tabpanel">
                    <br/><br/>
                    <div style={{display: existeContribuicoesDivergentes ? '' : 'none'}}>
                        <MDBAlert color="warning">
                            <MDBIcon icon="exclamation-triangle"/>&nbsp;&nbsp;&nbsp;Existem vínculos cujo número de
                            contribuições calculadas pelo período diferentes do número de contribuições registradas.
                        </MDBAlert>
                    </div>

                    <div style={{
                        display: loadingTempoContribuicao && datasCalculos.length > 0 ? 'none' : '',
                        fontSize: '14px'
                    }}>

                        <MDBRow>
                            {
                                datasCalculos.map((item, index) => {
                                    //console.log(item);
                                    if (item.data != "2019-11-13") {
                                        return (
                                            <MDBCol key={"data" + index} size={3}>
                                                <DataCalculo setActiveTabItemRegras={setActiveTabItemRegras}
                                                             setActiveTabItem={setActiveTabItem} item={item}/>
                                            </MDBCol>
                                        );
                                    }
                                })
                            }
                            <MDBCol>
                                <br/>
                                <div className="btn btn-primary text-center" style={{cursor: 'pointer'}}
                                         onClick={() => setModalDatasAposentadoria(true)}>
                                    <MDBIcon icon="plus-circle" style={{fontSize: "20px"}}/> &nbsp;&nbsp;
                                    Adicionar uma nova data para cálculo
                                </div>
                            </MDBCol>
                        </MDBRow>

                        {/*BLOCO DESATIVADO*/}
                        <MDBRow style={{display: "none"}}>
                            <MDBCol size="2" className="d-sm-block d-none">
                                <h5>&nbsp;</h5>
                                <p><strong>Tempo</strong></p>
                                <p><strong>Idade</strong></p>
                                <p><strong>Contribuições</strong></p>
                            </MDBCol>
                            {
                                datasCalculos.map((item, index) => {
                                    if (item.data != "2019-11-13") {
                                        return (
                                            <MDBCol key={"data" + index} sm="2" xs="12">
                                                {
                                                    item.totalContribuicoesPorData ?
                                                        <div>
                                                            <h5 className="custom-primary-color-text"
                                                                style={{fontSize: '16px'}}>
                                                                <strong>{formatDate(item.data)}</strong>
                                                            </h5>
                                                            <p><strong className="d-sm-none">Tempo</strong> {item.tempo}
                                                            </p>
                                                            <p><strong className="d-sm-none">Idade</strong> {item.idade}
                                                            </p>
                                                            <p><strong
                                                                className="d-sm-none">Contribuições</strong> {item.totalContribuicoesPorData}
                                                            </p>
                                                            {/*<p><strong>Contribuicoes Registradas:</strong>  {item.totalContribuicoesRegistradas}</p>*/}
                                                        </div>
                                                        :
                                                        <div style={{color: '#8e8e8e'}}>
                                                            <h6>{formatDate(item.data)}</h6>
                                                            <p style={{fontSize: "12px"}}>A datas de contribuições são
                                                                superiores a este período</p>
                                                        </div>
                                                }
                                                <hr className="d-sm-none d-block"/>
                                            </MDBCol>
                                        );
                                    }

                                })
                            }
                        </MDBRow>
                        {/*FIM BLOCO DESATIVADO*/}

                        <br/>
                        <MDBRow>
                            <MDBCol>
                                <Vinculos vinculos={vinculos} showIconPdf={true}/>
                                {/*<MDBTable small fixed>
                                    <MDBTableHead color="blue darken-4" style={{color: '#FFF'}}>
                                        <tr>
                                            <th width="330">Vínculo</th>
                                            <th className="text-center">Início</th>
                                            <th className="text-center">Fim</th>
                                            <th>Tempo Normal</th>
                                            <th>Tempo Especial</th>
                                            <th className="text-center">Contribuições Período</th>
                                            <th className="text-center">Contribuições Registradas</th>
                                            <th className="text-center">
                                                <MDBCol size="1" className="text-right">
                                                    {
                                                        loadingPdfVinculos ?
                                                            <MDBIcon icon="circle-notch" spin size="2x" /> :
                                                            <MDBIcon
                                                                className="red-text"
                                                                icon="file-pdf"
                                                                size="2x"
                                                                title="Exportar para PDF"
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => setLoadingPdfVinculos(true)}
                                                            />
                                                    }
                                                </MDBCol>
                                            </th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {
                                            vinculos.map((item, index) => {
                                                let contribuicoesDivergentes = item.qtdContribuicoesPorData !== item.qtdContribuicoesRegistradas;
                                                return (
                                                    [<tr key={"periodos" + index}>
                                                        <td height="50"><strong>{item.origem}</strong></td>
                                                        <td className="text-center">
                                                            {formatDate(item.inicio)}
                                                            <div style={{display: item.concomitante ? '' : 'none'}}>
                                                        <MDBBadge color="amber" className="custom-badge-warning">Concomitante</MDBBadge>
                                                    </div>
                                                        </td>
                                                        <td className={"text-center " + (item.obs_fim ? ' alert-fim-automatico' : '')}>{formatDate(item.fim)}</td>
                                                        <td>
                                                            <div>{item.tempo}</div>
                                                        </td>
                                                        <td>
                                                            <div>{item.tempoEspecial}</div>
                                                        </td>
                                                        <td className="text-center">
                                                            {item.qtdContribuicoesPorData}&nbsp;
                                                            <MDBIcon
                                                                style={{
                                                                    display: contribuicoesDivergentes ? '' : 'none',
                                                                    fontSize: '12px'
                                                                }}
                                                                icon="exclamation-triangle" className="text-warning"
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            {item.qtdContribuicoesRegistradas}&nbsp;
                                                            <MDBIcon
                                                                style={{
                                                                    display: contribuicoesDivergentes ? '' : 'none',
                                                                    fontSize: '12px'
                                                                }}
                                                                icon="exclamation-triangle" className="text-warning"
                                                            />
                                                        </td>
                                                    </tr>,
                                                        <tr key={"concomitantes" + index} className="tr-concomitante"
                                                            style={{display: item.concomitante ? '' : 'none'}}>
                                                            <td>&nbsp;</td>
                                                            <td colSpan="2">
                                                                <MDBBadge color="amber" className="custom-badge-warning"
                                                                          style={{
                                                                              fontSize: '10px',
                                                                              width: '100%',
                                                                              position: 'relative',
                                                                              top: '-15px'
                                                                          }}>Concomitante</MDBBadge>
                                                            </td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                        </tr>,
                                                        item.obs_fim ? (
                                                            <tr className="tr-obs-vinculo">
                                                                <td colSpan="7">{item.obs_fim}</td>
                                                            </tr>
                                                        ) : null,

                                                    ]
                                                );
                                            })
                                        }
                                    </MDBTableBody>
                                </MDBTable>*/}
                                <hr/>
                                <div className="box-tempo-total-vinculos">
                                    {
                                        datasCalculos[4] ? datasCalculos[4].tempo : (<div>&nbsp;</div>)
                                    }
                                </div>
                                <div>
                                    <br/><br/><br/><br/>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>

                </MDBTabPane>

                <MDBTabPane tabId="2" role="tabpanel">
                    <br/>
                    <div>
                        {regrasComponent()}
                    </div>
                </MDBTabPane>

                <MDBTabPane tabId="3" role="tabpanel">
                    <br/>
                    <div>
                        {calculosComponent()}
                    </div>
                </MDBTabPane>

                <MDBTabPane tabId="4" role="tabpanel">
                    <br/>
                    <MDBRow>
                        <MDBCol size="6">
                            <ChartSalarios meses={salariosMeses.meses} salarios={salariosMeses.salarios}
                                           generateImage={generateSalariosImage} getImage={getSalariosImage}
                                           salariosMinimos={salariosMinimos.salarios}/>
                            <img style={{display: salariosImage ? '' : 'none'}} src={salariosImage}/>
                            <MDBBtn style={{display:'none'}} onClick={() => {
                                setGenerateSalariosImage(true);
                                setGenerateVinculosImage(true);
                            }}>Teste Salários Image</MDBBtn>
                        </MDBCol>
                        <MDBCol size="6">
                            <ChartVinculos vinculos={totaisVinculos.vinculos} totais={totaisVinculos.totais}
                                           generateImage={generateVinculosImage} getImage={getVinculosImage}/>
                            <img style={{display: vinculosImage ? '' : 'none'}} src={vinculosImage} alt="vinculos-image"/>
                        </MDBCol>
                    </MDBRow>
                </MDBTabPane>

                <MDBTabPane tabId="5" role="tabpanel">
                    <br/>
                    <div>

                    </div>
                </MDBTabPane>
            </MDBTabContent>


        </div>

    );
}

export default Calcular;
