import axios from 'axios';

const api = axios.create({
    //baseURL: 'http://localhost:8007',
    //baseURL: 'https://icdsprev-api-homologa.desenv.net.br/',
    baseURL: 'https://api.icds-homologa.desenv.net.br/',
});

export default api;





