import React, {useEffect, useState} from 'react';
import {
    MDBBadge,
    MDBBtn,
    MDBCardHeader,
    MDBCol,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow, MDBTable, MDBTableBody, MDBTableHead
} from "mdbreact";
import {MDBCard, MDBCardBody} from "../styles";
import InfoCalculos from "./infoCalculos";
import { Page, Text, View, Document, StyleSheet,PDFDownloadLink, Image } from '@react-pdf/renderer';
import PdfRegra from "./pdfRegra";
import iconClose from "../../../../assets/icons/times-circle-solid.svg"
import { styles } from "../stylesPDF";
import TopPdf from "../../../../components/pdf/topPdf";
import {dateToday, formatDate} from "../../../../utils/date";
import VinculosPdf from "../vinculosPDF";


const Regra1PDF = (props) => {

    const [regras, setRegras] = useState(props.regras);
    const [corDireitoSim, setCorDireitoSim] = useState("");
    const [corDireitoNao, setCorDireitoNao] = useState("");
    const [calculos, setCalculos] = useState({});
    const [modalRmi, setModalRmi] = useState(false);
    const [direito, setDireito] = useState(false);
    const [rmi, setRmi] = useState(0);
    const [salarioBeneficio, setSalarioBeneficio] = useState(0);

    useEffect(() => {
        setRegras(props.regras);
        if(props.regras){
            setDireito(props.regras['REGRA1'].direito);
        }
    }, [props.regras]);

    useEffect(() => {
        setCalculos(props.calculos);
        if(props.regras){
            calcRmi(props.calculos);
        }
        //console.log(props.calculos);
    }, [props.calculos]);


    if(!props.regras){
        return (
            <Document>
                <Page size="A4" style={styles.page} wrap>
                    <View style={styles.section}>
                        <Text>&nbsp;</Text>
                    </View>
                </Page>
            </Document>
        )
    }

    function calcRmiOff(){
        return 0;
    }

    function calcRmi(calculos){
        if(regras === undefined){
            return;
        }
        /*
        - Fator Previdênciario:
		    Não é obrigatório usar quando
		        - idade + tempo contribuição = 86 (mulher) e 96 (homem)
			        Pode usar de forma facultativa (quando for positivo, superior a 1)
		        - no caso de direito adquirido até 31/12/2018
			        - idade + tempo contribuição = 85 (mulher) e 95 (homem)
	    - Média 80
        */
        let newSalarioBeneficio = calculos.media80;

        let newRmi = 0;

        let sexo = regras['REGRA1'].sexo;
        let pontos = regras['REGRA1'].pontos;

        if((sexo === 1 && pontos >= 96) || (sexo === 2 && pontos >= 86)){
            newRmi = calculos.fator > 1 ? newSalarioBeneficio * calculos.fator : newSalarioBeneficio;
            setRmi(newRmi);
            setSalarioBeneficio(newSalarioBeneficio);
            return;
        }
        newRmi = newSalarioBeneficio * calculos.fator;
        props.handleRmi(props.data, 'REGRA1', newRmi);
        props.handleSalarioBeneficio(props.data, 'REGRA1', newSalarioBeneficio);
        setRmi(newRmi);
        setSalarioBeneficio(newSalarioBeneficio);
    }

    return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <TopPdf info={"Relatório - Regra 1 - "+dateToday()}/>
                        <View style={styles.card}>
                            <View style={styles.cardHeader}>
                                <View style={{flex: 2}}>
                                    {
                                        direito ? (
                                            <View style={[styles.badge, styles.backSuccess]}>
                                                <Text>Possui direito</Text>
                                            </View>
                                        ) : (
                                            <View style={[styles.badge, styles.backDanger]}>
                                                {/*<View><Image style={styles.icon}  src={iconClose} /></View>*/}
                                                <View><Text>Não Possui direito</Text></View>
                                            </View>
                                        )
                                    }
                                </View>
                                <View style={[styles.tituloRegra, {flex: 5}]}>
                                    <Text>&nbsp;&nbsp;Aposentadoria por tempo de contribuição</Text>
                                </View>
                                <View style={{flex: 2}}>
                                    {
                                        Object.keys(calculos).length > 0 && regras['REGRA1'].direito ?
                                            (
                                                <Text style={styles.rmiV2}>
                                                    S. Benefício: R$ {new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format(salarioBeneficio)}
                                                </Text>
                                            ) : null
                                    }
                                </View>
                                <View style={{flex: 1}}>
                                    {
                                        Object.keys(calculos).length > 0 && regras['REGRA1'].direito ?
                                            (
                                                <Text style={styles.rmiV2}>
                                                    RMI: R$ {new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format(rmi)}
                                                </Text>
                                            ) : null
                                    }
                                </View>
                            </View>
                            <View style={styles.cardBody}>
                                <View style={styles.table}>

                                    <View style={[styles.tableRow]}>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={[styles.tableCell]}>Tempo de Contribuição</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={styles.tableCell}>Carência</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={[styles.tableRow]}>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={[styles.tableCell]}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={[styles.tableCell, styles.falta, {display: direito ? 'none' : ''}]}>
                                                {props.falta(regras['REGRA1'].falta.tempo, 'contribuições')}
                                            </Text>
                                            <Text style={[styles.tableCell, styles.atual]}>
                                                {regras['REGRA1'].atual.tempo}
                                            </Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={[styles.tableCell, styles.falta, {display: direito ? 'none' : ''}]}>
                                                {regras['REGRA1'].falta.contribuicoes > 0 ? "faltam "+regras['REGRA1'].falta.contribuicoes : " "}
                                            </Text>
                                            <Text style={[styles.tableCell, styles.atual]}>
                                                {regras['REGRA1'].atual.contribuicoes}
                                            </Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={[styles.tableRow]}>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={[styles.tableCell]}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={{borderTop: 1, borderColor: '#ccc', paddingTop: 8}}>
                                        <Text style={styles.tableCell}><strong>Regra: </strong>{regras['REGRA1'].regra.tempo} anos de tempo de contribuição e {regras['REGRA1'].regra.contribuicoes} de carência <br/></Text>
                                        <Text style={styles.tableCell}>OBS: São consideradas apenas as contribuições feitas até {regras['REGRA1'].regra.dataLimite}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={styles.br}>
                            <Text>&nbsp;</Text>
                        </View>

                        <View>
                            {
                                Object.keys(props.calculos).length > 0 && props.direito ?
                                    (
                                        <View style={styles.table}>
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, {width: "40%"}]}>
                                                    <Text style={styles.tableCell}>Média 80% maiores</Text>
                                                    <Text style={styles.tableCell}>R$ {props.calculos.media80.toString().replace('.', ',')}</Text>
                                                </View>
                                                {
                                                    (regras['REGRA1'].sexo === 1 && regras['REGRA1'].pontos < 96) ||
                                                    (regras['REGRA1'].sexo === 2 && regras['REGRA1'].pontos < 86) ?
                                                        (
                                                            <View style={[styles.tableCol, {width: "40%"}]}>
                                                                <Text style={styles.tableCell}>Fator Previdenciário &nbsp;&nbsp;</Text>
                                                                <Text style={styles.tableCell}>{props.calculos.fator}</Text>
                                                            </View>
                                                        ) : null
                                                }
                                            </View>
                                        </View>
                                    ) : null
                            }

                        </View>

                        {
                            Object.keys(props.calculos).length > 0 && props.direito ?
                                (
                                    <View>
                                        <View style={styles.table}>
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, {width: "100%"}]}>
                                                    <Text style={[styles.tableCell, styles.tituloSalarios]}>Salários utilizados no cálculo</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, {width: "25%"}]}>
                                                    <Text style={styles.tableCell}>Competência</Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "25%"}]}>
                                                    <Text style={styles.tableCell}>Original</Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "25%"}]}>
                                                    <Text style={styles.tableCell}>Índice</Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "25%"}]}>
                                                    <Text style={styles.tableCell}>Atualizado</Text>
                                                </View>
                                            </View>
                                            {
                                                props.calculos.remuneracoes.map((remuneracao, index) => {
                                                    /*let acumulado = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(remuneracao.acumulado);*/

                                                    let original = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(remuneracao.original);

                                                    let indice = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 6,
                                                        maximumFractionDigits: 6
                                                    }).format(remuneracao.indice);

                                                    let atualizado = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(remuneracao.atualizado);

                                                    return (
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>{remuneracao.competencia}</Text>
                                                            </View>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>R$ {original}</Text>
                                                            </View>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>{indice}</Text>
                                                            </View>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>R$ {atualizado}</Text>
                                                            </View>
                                                        </View>
                                                    );

                                                })
                                            }
                                        </View>
                                    </View>
                                ) : null
                        }


                    </View>

                </Page>
                {
                    props.vinculos ? (
                        <VinculosPdf vinculos={props.vinculos} />
                    ) : null
                }
            </Document>
    );
};

export default Regra1PDF;
