import styled from "styled-components";
import {MDBIcon, MDBLink} from "mdbreact";

const StyledMDBLink = styled(MDBLink)`
    padding: 0;
`;
StyledMDBLink.defaultProps = MDBLink.defaultProps;

const StyledMDBIcon = styled(MDBIcon)`
    font-size: 20px;   
`;
StyledMDBIcon.defaultProps = MDBIcon.defaultProps;

export {
    StyledMDBLink as MDBLink,
    StyledMDBIcon as MDBIcon,
};