import React, {useEffect, useState} from 'react';
import {
    MDBBadge,
    MDBBtn,
    MDBCardHeader,
    MDBCol,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow, MDBTable, MDBTableBody, MDBTableHead
} from "mdbreact";
import {MDBCard, MDBCardBody} from "../styles";
import InfoCalculos from "./infoCalculos";
import { styles } from "../stylesPDF";
import {Document, Page, PDFDownloadLink, Text, View} from "@react-pdf/renderer";
import TopPdf from "../../../../components/pdf/topPdf";
import {dateToday} from "../../../../utils/date";
import VinculosPdf from "../vinculosPDF";

const Regra6 = (props) => {

    const [regras, setRegras] = useState(props.regras);
    const [calculos, setCalculos] = useState({});
    const [modalRmi, setModalRmi] = useState(false);
    const [direito, setDireito] = useState(false);
    const [rmi, setRmi] = useState(0);
    const [salarioBeneficio, setSalarioBeneficio] = useState(0);

    useEffect(() => {
        setRegras(props.regras);
        if(props.regras){
            setDireito(props.regras['REGRA6'].direito);
        }
    }, [props.regras]);

    useEffect(() => {
        setCalculos(props.calculos);
        if(props.regras){
            calcRmi(props.calculos);
        }
        //console.log(props.calculos);
    }, [props.calculos]);

    if(!props.regras){
        return (
            <Document>
                <Page size="A4" style={styles.page} wrap>
                    <View style={styles.section}>
                        <Text>&nbsp;</Text>
                    </View>
                </Page>
            </Document>
        )
    }

    function calcRmiOff(calculos){
        return 0;
    }

    function calcRmi(calculos){
        if(regras === undefined || calculos === undefined){
            return;
        }
        if(calculos.coeficiente === undefined){
            return;
        }
        /*
        - Tem fator
	    - Média 100
	    - Não aplica coeficiente (ou seja 100%)
        */
        //console.log(calculos)
        let newSalarioBeneficio = calculos.media100;
        //console.log('Regra 6 - salarioBeneficio', salarioBeneficio);
        let newRmi = newSalarioBeneficio * calculos.fator;
        //console.log(rmi);
        props.handleRmi(props.data, 'REGRA6', newRmi);
        props.handleSalarioBeneficio(props.data, 'REGRA6', newSalarioBeneficio);
        setRmi(newRmi);
        setSalarioBeneficio(newSalarioBeneficio);
        //return rmi;
    }

    return (
            <Document>
                <Page size="A4" style={styles.page} wrap>
                    <View style={styles.section}>
                        <TopPdf info={"Relatório - Regra 6 - "+dateToday()}/>

                        <View style={styles.card}>
                            <View style={styles.cardHeader}>
                                <View style={{flex: 2}}>
                                    {
                                        props.direito ? (
                                            <View style={[styles.badge, styles.backSuccess]}>
                                                <Text>Possui direito</Text>
                                            </View>
                                        ) : (
                                            <View style={[styles.badge, styles.backDanger]}>
                                                {/*<View><Image style={styles.icon}  src={iconClose} /></View>*/}
                                                <View><Text>Não Possui direito</Text></View>
                                            </View>
                                        )
                                    }
                                </View>
                                <View style={[styles.tituloRegra, {flex: 5}]}>
                                    <Text>&nbsp;&nbsp;Regra de Transição 3 - art. 17, EC 103 (Pedágio 50%)</Text>
                                </View>
                                <View style={{flex: 2}}>
                                    {
                                        Object.keys(props.calculos).length > 0 && regras['REGRA6'].direito ?
                                            (
                                                <Text style={styles.rmiV2}>
                                                    S. Benefício: R$ {new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format(salarioBeneficio)}
                                                </Text>
                                            ) : null
                                    }
                                </View>
                                <View style={{flex: 1}}>
                                    {
                                        Object.keys(props.calculos).length > 0 && regras['REGRA6'].direito ?
                                            (
                                                <Text style={styles.rmiV2}>
                                                    RMI: R$ {new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format(rmi)}
                                                </Text>
                                            ) : null
                                    }
                                </View>
                            </View>
                            <View style={styles.cardBody}>
                                <View style={styles.table}>

                                    <View style={[styles.tableRow]}>
                                        <View style={[styles.tableCol, {width: "49%"}]}>
                                            <Text style={[styles.tableCell]}>Tempo de Contribuição</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "49%"}]}>
                                            <Text style={[styles.tableCell]}>{regras['REGRA6'].condicao1 ? 'Carência' : ' '}</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "1%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "1%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={[styles.tableRow]}>
                                        <View style={[styles.tableCol, {width: "49%"}]}>
                                            <Text style={[styles.tableCell]}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "49%"}]}>
                                            <Text style={[styles.tableCell]}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "1%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "1%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCol, {width: "49%"}]}>
                                            <Text style={[styles.tableCell, styles.falta, {display: regras['REGRA6'].condicao1 ? 'none' : ''}]}>
                                                {props.falta(regras['REGRA6'].falta.tempo, 'contribuições')}
                                            </Text>
                                            <Text style={[styles.tableCell, styles.atual]}>
                                                {regras['REGRA6'].tempo13112019} em {regras['REGRA6'].regraCondicao1.dataCondicao}
                                            </Text>
                                            <Text style={[styles.tableCell, {fontSize: 11}]}>Condicao 1</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "49%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "1%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "1%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={[styles.tableRow]}>
                                        <View style={[styles.tableCol, {width: "49%"}]}>
                                            <Text style={[styles.tableCell]}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "49%"}]}>
                                            <Text style={[styles.tableCell]}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "1%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "1%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    {
                                        regras['REGRA6'].condicao1 ? (
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, {width: "49%"}]}>
                                                    <Text style={[styles.tableCell, styles.falta, {display: props.direito ? 'none' : ''}]}>
                                                        {props.falta(regras['REGRA6'].falta.tempo, 'contribuições')}
                                                    </Text>
                                                    <Text style={[styles.tableCell, styles.atual]}>
                                                        {regras['REGRA6'].atual.tempo}
                                                    </Text>
                                                    <Text style={[styles.tableCell, {fontSize: 11}]}>Condicao 2</Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "49%"}]}>
                                                    <Text style={[styles.tableCell, styles.falta, {display: props.direito ? 'none' : ''}]}>
                                                        {regras['REGRA6'].falta.contribuicoes > 0 ? "faltam "+regras['REGRA6'].falta.contribuicoes : " "}
                                                    </Text>
                                                    <Text style={[styles.tableCell, styles.atual]}>
                                                        {regras['REGRA6'].atual.contribuicoes}
                                                    </Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "1%"}]}>
                                                    <Text style={styles.tableCell}>&nbsp;</Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "1%"}]}>
                                                    <Text style={styles.tableCell}>&nbsp;</Text>
                                                </View>
                                            </View>
                                        ) : null
                                    }


                                    <View style={[styles.tableRow]}>
                                        <View style={[styles.tableCol, {width: "39%"}]}>
                                            <Text style={[styles.tableCell]}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "39%"}]}>
                                            <Text style={[styles.tableCell]}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "20%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "1%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "1%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={{borderTop: 1, borderColor: '#ccc', paddingTop: 8}}>
                                        <Text style={styles.tableCell}>
                                            <strong>Regra (condição 1): </strong>
                                            {regras['REGRA6'].regraCondicao1.tempo} anos de contribuições em {regras['REGRA6'].regraCondicao1.dataCondicao}
                                        </Text>
                                        <Text style={styles.tableCell}>
                                            <strong>Regra: </strong>
                                            {regras['REGRA6'].regraCondicao2['tempo']} anos de contribuições e&nbsp;
                                            {
                                                props.hidePedagio(regras['REGRA6'].pedagio) ? null :
                                                " + Pedágio de " + regras['REGRA6'].pedagio['anos'] + " anos, " +
                                                regras['REGRA6'].pedagio['meses'] + "meses e " + regras['REGRA6'].pedagio['dias'] + "dias e "
                                            }
                                            {regras['REGRA6'].regraCondicao2.contribuicoes} de carência
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={styles.br}>
                            <Text>&nbsp;</Text>
                        </View>


                        <View>
                            {
                                Object.keys(props.calculos).length > 0 && props.direito ?
                                    (
                                        <View style={styles.table}>
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, {width: "50%"}]}>
                                                    <Text style={styles.tableCell}>Média 100% maiores</Text>
                                                    <Text style={styles.tableCell}>R$ {props.calculos.media100.toString().replace('.', ',')}</Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "50%"}]}>
                                                    <Text style={styles.tableCell}>Fator Previdenciário &nbsp;&nbsp;</Text>
                                                    <Text style={styles.tableCell}>{props.calculos.fator}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    ) : null
                            }

                        </View>

                        <View style={styles.br}>
                            <Text>&nbsp;</Text>
                        </View>

                        {
                            Object.keys(props.calculos).length > 0 && props.direito ?
                                (
                                    <View>
                                        <View style={styles.table}>
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, {width: "100%"}]}>
                                                    <Text style={[styles.tableCell, styles.tituloSalarios]}>Salários utilizados no cálculo</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, styles.tableHead, {width: "25%"}]}>
                                                    <Text style={[styles.tableCell, {fontWeight: 'bold'}]}><strong>Competência</strong></Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "25%"}]}>
                                                    <Text style={[styles.tableCell, styles.tableHead]}>Original</Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "25%"}]}>
                                                    <Text style={[styles.tableCell, styles.tableHead]}>Índice</Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "25%"}]}>
                                                    <Text style={[styles.tableCell, styles.tableHead]}>Atualizado</Text>
                                                </View>
                                            </View>
                                            {
                                                props.calculos.remuneracoes.map((remuneracao, index) => {
                                                    /*let acumulado = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(remuneracao.acumulado);*/

                                                    let original = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(remuneracao.original);

                                                    let indice = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 6,
                                                        maximumFractionDigits: 6
                                                    }).format(remuneracao.indice);

                                                    let atualizado = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(remuneracao.atualizado);


                                                    return (
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>{remuneracao.competencia}</Text>
                                                            </View>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>R$ {original}</Text>
                                                            </View>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>{indice}</Text>
                                                            </View>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>R$ {atualizado}</Text>
                                                            </View>
                                                        </View>
                                                    );

                                                })
                                            }
                                        </View>
                                    </View>
                                ) : null
                        }


                    </View>

                </Page>
                {
                    props.vinculos ? (
                        <VinculosPdf vinculos={props.vinculos} />
                    ) : null
                }
            </Document>
    );
};

export default Regra6;
