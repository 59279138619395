import React, {useState} from 'react';
import {MDBBtn} from "mdbreact";
import api from "../../../services/api";
import {useHistory} from "react-router-dom";
import {MDBIcon} from "../../../components/Buttons/DeleteButton/styles";

const PlanoExpirado = () => {

    const [renovando, setRenovando] = useState(false);
    const history = useHistory();

    async function renovarPlano(){
        setRenovando(true)
        try{
            const result = await api.get('/api/renovar-plano/');
            console.log(result.data);
            setRenovando(false);
            history.push('/login');
            //handleNotify({type: 'success', text: ''});
        }catch (error){
            console.log(error);
            setRenovando(false);
            //handleNotify({type: 'error', text: ''});
        }
    }

    return (
        <div className="text-center">
            <br/><br/>
            <h4>Seu plano expirou!</h4>
            <MDBBtn onClick={renovarPlano} style={{display: renovando ? 'none' : ''}}>Renovar Plano</MDBBtn>
            <MDBBtn disabled={true} style={{display: renovando ? '' : 'none'}}><MDBIcon icon="spinner" spin/> Renovar Plano</MDBBtn>
        </div>
    );
};

export default PlanoExpirado;