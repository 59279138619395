import {validateCNPJ, validateCpf} from "../../../utils/validate";

export default function validate(values) {
    let errors = {};

    //console.log('===========================================');
    //console.log('VALIDATE', values);

    if (values.codigoEmp) {
        let codigo = values.codigoEmp.replace(/\D/g, "");
        if(codigo.length === 14){
            if(!validateCNPJ(values.codigoEmp)){
                errors.codigoEmp = 'CNPJ Inválido';
            }
        }
        //errors.codigoEmp = 'Informe o CNPJ';
    }
    /*if(!validateCNPJ(values.codigoEmp)){
        errors.codigoEmp = 'CNPJ Inválido';
    }*/
    if (!values.origemVinculo) {
        errors.origemVinculo = 'Informe a Origem do Vínculo';
    }
    if (!values.dataInicio) {
        errors.dataInicio = 'Informe a Data Início';
    }
    /*if (!values.dataFim) {
        errors.dataFim = 'Informe a Data Fim';
    }*/
    //console.log(values.remuneracoes);
    if (values.remuneracoes.length === 0){
        errors.contribuicoes = 'Adicione pelo menos uma contribuição';
    }else{
        for (let i in values.remuneracoes){
            if(!values.remuneracoes[i].competencia){
                errors.contribuicoes = 'Existem contribuições sem dados';
            }
            if(values.remuneracoes[i].remuneracao === "0,00"){
                errors.contribuicoes = 'Existem contribuições sem dados';
            }
            if(!values.remuneracoes[i].remuneracao){
                errors.contribuicoes = 'Existem contribuições sem dados';
            }
        }
    }

    if (!values.fator) {
        errors.fator = 'Informe o Fator';
    }

    /*if (!/\d{3}\.\d{3}\.\d{3}-\d{2}/.test(values.cpf)) {
        errors.cpf = 'Cpf Inválido';
    }*/

    return errors;
};
