import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#FFF'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    br: {
        padding: 0,
    },
    cliente: {
        /*position: 'relative',*/
        /*display: 'flex',*/
        /*flexDirection: 'column',*/
        wordWrap: 'break-word',
        backgroundColor: '#EEEEEE',
        backgroundClip: 'border-box',
        padding: '20px',
        left: 0,

        flexDirection:"row",
    },
    clienteLeft: {
        backgroundColor: '#EEEEEE',
        flex: 1,
        fontSize: '25px',
    },
    clienteRignt: {
        flex: 1,
        textAlign: 'right',
    },

    flexDirection: {
        wordWrap: 'break-word',
        backgroundClip: 'border-box',
        flexDirection:"row",
    },
    flex: {
        flex: 1,
    },
    flexR: {
        flex: 1,
    },
    hr: {
        border: 1,
        borderBottom: "solid",
        borderColor: '#EEEEEE',
        marginTop: '5px',
        marginBottom: '5px',

    },
    clienteNome: {
        textAlign: 'left',
        fontSize: '16px',
    },
    clienteCpf: {
        textAlign: 'left',
        fontSize: '12px',
    },
    clienteDados: {
        textAlign: 'center',
        fontSize: '14px',
    },
    card: {
        /*position: 'relative',*/
        /*display: 'flex',*/
        /*flexDirection: 'column',*/
        width: '100%',
        wordWrap: 'break-word',
        backgroundColor: '#fff',
        backgroundClip: 'border-box',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#ccc',
        borderRadius: '5px',
    },
    cardHeader: {
        flexDirection: 'row',
        padding: '10px',
        fontSize: '10px',
        backgroundColor: 'rgba(0,0,0,.03)',
    },
    cardBody: {
        padding: '10px',
    },
    badge: {
        display: "inline-block",
        padding: "3px 1px",
        fontWeight: "bolder",
        lineHeight: "1",
        textAlign: "center",
        whiteSpace: "nowrap",
        verticalAlign: "baseline",
        borderRadius: "2px",
    },
    backSuccess:{
        color: '#fff',
        backgroundColor: '#00c851',
    },
    backDanger:{
        color: '#fff',
        backgroundColor: 'rgba(255,53,71,0.81)',
    },
    tituloRegra:{
        fontSize: '12px',
    },
    icon:{
        width: '10px',
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        /*textAlign: 'left',*/
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
        /*textAlign: 'left',*/
        border: 0,
    },
    tableCol: {
        /*width: "25%",*/
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        /*textAlign: 'left',*/
        border: 0,
    },
    tableCell: {
        /*margin: "auto",
        marginTop: 5,*/
        fontSize: 12,
        padding: 3,
        border: 0,
    },
    tableHead: {
        fontWeight: 'bold',
    },
    tituloSalarios: {
        backgroundColor: '#2bbbad',
        color: '#fff',
        padding: 5,
        textAlign: 'center',
    },
    atual: {
        fontSize: 14,
    },
    falta: {
        fontSize: 11,
    },
    rmiV2: {
        fontColor: "#0099C",
        fontWeight: "bold",
        fontsize: "14px",
    },
    bg:{
        backgroundColor: '#EEEEEE',
        padding: '10px',
        fontSize: "16px",
    },
    bgImg:{
        marginRight: '10px',
    }

});