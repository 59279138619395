import React, {useEffect, useState} from 'react';
import sequencia from './sequencia';
import Sequencia from "./sequencia";

const Sequencias = (props) => {

    const [sequencias, setSequencias] = useState(props.sequencias);

    useEffect(() => {
        setSequencias(props.sequencias);
    }, [props.sequencias]);

    return(
        <div>
            {sequencias.map((sequencia, index) => {
                return (
                    /*<Sequencia key={'seq'+index} index={index} sequencia={sequencia} inicio={sequencia.dataInicio} fim={sequencia.dataFim}*/
                    <Sequencia key={'seq_'+sequencia.seq} index={index} sequencia={sequencia}
                               showContribuicoes={props.showContribuicoes}
                               removeSequencia={props.removeSequencia}
                               handleSequencia={props.handleSequencia}
                               handleEspecialSequencia={props.handleEspecialSequencia}
                               updateErrors={props.updateErrors}
                               salariosMinimos={props.salariosMinimos}
                               salariosTetos={props.salariosTetos}
                    />
                );
            })}
        </div>
    );
};

export default Sequencias;
