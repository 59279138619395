import React, {useEffect, useState} from 'react';
import {MDBBtn, MDBCard, MDBCardText, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import { MDBCardBody } from "./styles";
import validate from "./validate";
import {salarios} from "./salarios";
import {optionsEspecial} from "./options";
import {SingleSelect} from "react-select-material-ui";

const Sequencia = (props) => {

    const [key, setKey] = useState(props.index);
    const [sequencia, setSequencia] = useState(props.sequencia);
    const [inicio, setInicio] = useState(props.inicio);
    const [fim, setFim] = useState(props.fim);
    const [errors, setErrors] = useState({});
    const [infoErrors, setInfoErrors] = useState(false);
    const [salarios, setSalarios] = useState({});
    const [salariosTetos, setSalariosTetos] = useState({});

    useEffect(() => {
        //console.log("=======================");
        //console.log(props.index, props.sequencia);
        setKey(props.index);
        setSequencia(props.sequencia);
        setInicio(props.inicio);
        setFim(props.fim);
        setErrors(validate(props.sequencia));
        //console.log('errors', errors);
    //}, [props.index, props.sequencia]);
    }, [props.index, props.sequencia, props.inicio, props.fim]);

    useEffect(() => {
        console.log('useEffect [errors]', errors);
        //props.updateErrors.updateErrorsSequencia(key, Object.keys(errors).length > 0);
        props.updateErrors.updateErrorsSequencia(sequencia.seq, Object.keys(errors).length > 0);
    }, [errors]);

    useEffect(() => {
        if(Object.keys(props.salariosMinimos).length > 0){
            //console.log('constribuicoes.js', props.salariosMinimos);
            setSalarios(props.salariosMinimos);
        }
    }, [props.salariosMinimos])

    useEffect(() => {
        if(Object.keys(props.salariosTetos).length > 0){
            //console.log('constribuicoes.js', props.salariosMinimos);
            setSalariosTetos(props.salariosTetos);
        }
    }, [props.salariosTetos])

    function removeSequencia(seq){
        props.removeSequencia(seq);
    }

    const handleSequencia = (index) => (e) => {
        //console.log(index);
        //console.log(e);
        props.handleSequencia(index, e);
    }

    const handleEspecialSequencia = (index) => (value) => {
        //console.log(index);
        //console.log(e);
        props.handleEspecialSequencia(index, value);
    }

    const handleDateSequencia = (index) => (e) => {
        //console.log(index);
        //console.log(e.target.value);
        props.handleSequencia(index, e);
        //if(){
            //props.handleSequencia(index, e);
        //}
    }


    /*function validadeSequencia(sequencia){
        if (!validateContribuicoes(contribuicoes)){
            return false;
        }
        if (!sequencia.dataInicio || !sequenciaDataFim){
            return false;
        }

        return true;
    }

    function validateContribuicoes(contribuicoes){
        return contribuicoes.length > 0;
    }*/

    function descriptionErrors(){
        let text = [];
        let qtd = Object.keys(errors).length;
        let count = 0;
        for(let i in errors){
            text.push(<span key={'error_'+i}>{errors[i]}</span>)
            if(count < qtd){
                text.push(<br/>)
            }
            count++;
        }
        return text;
    }

    function remuneracaoInferiorMinimo(contribuicao){
        //let remuneracao = parseFloat(contribuicao.remuneracao.replace(',', ''))/100;
        //let ano = contribuicao.competencia ? contribuicao.competencia.substr(3) : "";
        //return remuneracao < salarios[ano];
        let remuneracao = contribuicao.remuneracao.replace(',', '');
        remuneracao = remuneracao.replace('.', '');
        remuneracao = remuneracao.replace('.', '');
        remuneracao = remuneracao.replace('.', '');
        remuneracao = parseFloat(remuneracao)/100;
        let mesAno = contribuicao.competencia ? contribuicao.competencia : "";
        return remuneracao < salarios[mesAno];
    }

    function remuneracaoSuperiorTeto(contribuicao){
        if(!contribuicao.competencia){
            return false;
        }
        let remuneracao = contribuicao.remuneracao.replace(',', '');
        remuneracao = remuneracao.replace('.', '');
        remuneracao = remuneracao.replace('.', '');
        remuneracao = remuneracao.replace('.', '');
        remuneracao = parseFloat(remuneracao)/100;
        let mesAno = contribuicao.competencia;
        return remuneracao > salariosTetos[mesAno];
    }

    function remuneracoesInferioresMinimo(contribuicoes){
        for(let i in contribuicoes){
            if(remuneracaoInferiorMinimo(contribuicoes[i])){
                return true;
            }
        }
        return false;
    }

    function remuneracoesSuperioresTeto(contribuicoes){
        for(let i in contribuicoes){
            if(remuneracaoSuperiorTeto(contribuicoes[i])){
                return true;
            }
        }
        return false;
    }


    return (
        <MDBRow>
            <MDBCol>
                <MDBContainer fluid>
                    {/*<MDBCard style={{marginTop: '15px'}} border={Object.keys(errors).length === 0 ? '' : 'danger'}>*/}
                    <div style={{marginTop: '15px', paddingLeft: '10px', borderLeft: "solid 6px #CCCCCC", borderColor: (Object.keys(errors).length === 0 ? "#CCCCCC" : "#ff3547")}}>
                        <div className={"float-right text-right"+(Object.keys(errors).length === 0 ? " d-none d-sm-none" : "")}>
                            <a onClick={() => setInfoErrors(!infoErrors)}><MDBIcon icon={(!infoErrors ? "exclamation-triangle" : "times")} className="text-danger"/></a>
                            <MDBCard m="50" className={"text-left"+(!infoErrors ? " d-none d-sm-none" : "")}
                                     style={{position: 'absolute', right: 0, zIndex: '9999', padding: '10px'}}>
                                <MDBCardBody>
                                    <MDBCardText>
                                        {descriptionErrors()}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                        <MDBRow>
                            {/*<MDBCol md="12" className={"text-right"+(Object.keys(errors).length === 0 ? " d-none d-sm-none" : "")}>
                                <a onClick={() => setInfoErrors(!infoErrors)}><MDBIcon icon="exclamation-triangle" className="text-danger"/></a>
                                <MDBCard m="50" className={"text-left"+(!infoErrors ? " d-none d-sm-none" : "")}>
                                    <MDBCardBody>
                                        <MDBCardText>
                                            {descriptionErrors()}
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>*/}
                            <MDBCol md="2" sm="6">
                                <MDBInput label="Código Emp." id={"codigoEmp_"+key} name={"codigoEmp_"+key}
                                          onChange={handleSequencia(key)}
                                          containerClass={errors.codigoEmp ? "invalid-input" : ""}
                                          value={sequencia.codigoEmp}
                                          style={{fontSize: '14px'}}
                                />
                            </MDBCol>

                            <MDBCol md="3" sm="6">
                                <MDBInput label="Origem" id={"origemVinculo_"+key} name={"origemVinculo_"+key}
                                          onChange={handleSequencia(key)}
                                          containerClass={errors.origemVinculo ? "invalid-input" : ""}
                                          value={sequencia.origemVinculo}
                                          style={{fontSize: '14px'}}
                                />
                            </MDBCol>
                            <MDBCol md="2" sm="6">
                                <MDBInput
                                    label="Data Início"
                                    type="date"
                                    id={"dataInicio_"+sequencia.seq}
                                    onChange={handleDateSequencia(key)}
                                    containerClass={errors.dataInicio ? "invalid-input" : ""}
                                    valueDefault={sequencia.dataInicio}
                                    style={{fontSize: '14px'}}
                                />
                            </MDBCol>
                            <MDBCol md="2" sm="6">
                                <MDBInput
                                    label="Data Fim"
                                    type="date"
                                    id={"dataFim_"+key}
                                    onChange={handleDateSequencia(key)}
                                    containerClass={errors.dataFim ? "invalid-input" : ""}
                                    /*valueDefault={fim}*/
                                    valueDefault={sequencia.dataFim}
                                    style={{fontSize: '14px'}}
                                />
                            </MDBCol>
                            <MDBCol md="2" sm="6">
                                <SingleSelect
                                    label="Especial" id={"especial_"+key} name={"especial_"+key}
                                    options={optionsEspecial}
                                    onChange={handleEspecialSequencia(key)}
                                    value={sequencia.especial}
                                />
                            </MDBCol>
                            <MDBCol md="1" sm="6">
                                <MDBInput label="Fator" id={"fator_"+key} name={"fator_"+key}
                                          onChange={handleSequencia(key)}
                                          containerClass={errors.fator ? "invalid-input" : ""}
                                          value={sequencia.fator}
                                          style={{fontSize: '14px'}}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <MDBBtn size="sm" onClick={() => props.showContribuicoes(key)} style={{border: errors.contribuicoes ? 'solid 1px red' : ''}}>
                                    <MDBIcon icon="list"/>&nbsp;&nbsp;Contribuições
                                </MDBBtn>
                                <MDBIcon
                                    style={{display: remuneracoesInferioresMinimo(sequencia.remuneracoes) ? '' : 'none'}}
                                    icon="exclamation-triangle" className="text-warning"
                                    title="Este vínculo possui remuneracoes abaixo do salário mínimo"
                                />
                                <MDBIcon
                                    style={{display: remuneracoesSuperioresTeto(sequencia.remuneracoes) ? '' : 'none'}}
                                    icon="exclamation-triangle" className="text-warning"
                                    title="Este vínculo possui remuneracoes acima do salário de teto"
                                />
                            </MDBCol>
                            <MDBCol className="text-right"><MDBBtn color="danger" size="sm" onClick={() => removeSequencia(sequencia.seq)}><MDBIcon icon="trash"/>&nbsp;&nbsp;Excluir</MDBBtn></MDBCol>
                            {/*<MDBCol className="text-right"><MDBIcon icon="trash" title="Remover Vínculo"/></MDBCol>*/}
                        </MDBRow>
                    </div>
                    <hr/>
                </MDBContainer>
            </MDBCol>
        </MDBRow>
    );
};

export default Sequencia;
