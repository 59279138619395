import React, {useEffect, useRef, useState} from "react";
import { Line } from "react-chartjs-2";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import {Link} from "react-router-dom";
import {sleep} from "../../../../utils/diversos";

const ChartSalarios = (props) => {

    const [dataLine, setDataLine] = useState({});
    const [chart, setChart] = useState(null);
    const chartRef = useRef(null);

    useEffect(() => {
        //console.log(props);
        let tempDataLine = {
            //labels: ["January", "February", "March", "April", "May", "June", "July"],
            labels: props.meses,
            datasets: [
                {
                    label: "Salários",
                    fill: true,
                    lineTension: 0.3,
                    backgroundColor: "rgba(184, 185, 210, .3)",
                    borderColor: "rgb(122,122,128)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: "miter",
                    borderWidth: 1,
                    pointBorderColor: "rgb(35, 26, 136)",
                    pointBackgroundColor: "rgb(255, 255, 255)",
                    pointBorderWidth: 2,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "rgb(0, 0, 0)",
                    pointHoverBorderColor: "rgba(220, 220, 220, 1)",
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: props.salarios,
                },
                {
                    label: "Salários Mínimos",
                    fill: true,
                    lineTension: 0.3,
                    backgroundColor: "rgba(16,152,158, 0)",
                    borderColor: "rgb(16,152,158)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: "miter",
                    borderWidth: 1,
                    pointBorderColor: "rgb(16,152,158)",
                    pointBackgroundColor: "rgb(255, 255, 255)",
                    pointBorderWidth: 2,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "rgb(0, 0, 0)",
                    pointHoverBorderColor: "rgba(220, 220, 220, 1)",
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: props.salariosMinimos,
                },
            ]
        }
        setDataLine(tempDataLine);
    }, [props.meses, props.salariosMinimos]);

    useEffect(() => {
        //console.log('CHART SALÁRIOS',dataLine);
        let line = (<Line ref={chartRef} data={dataLine} options={{ responsive: true }}/>);
        setChart(line);
    }, [dataLine]);

    /*useEffect(() => {
        if (chart && dataLine) {
            const base64Image = chartRef.current.chartInstance.toBase64Image();
            setImage(base64Image);
        }
    }, [chart, dataLine]);*/

    useEffect(() => {
        if(chart && dataLine){
            const base64Image = chartRef.current.chartInstance.toBase64Image();
            props.getImage(base64Image);
        }
    }, [props.generateImage]);


    return (
        <MDBCard>
            <MDBCardBody>
                <MDBCardTitle>
                    <MDBRow>
                        <MDBCol size="12"><strong>Salários</strong></MDBCol>
                        {/*<MDBCol className="text-right" style={{fontSize: "12px", fontWeight: 'bold'}}>
                            <span>7D</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>1M</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>3M</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>1A</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>MÊS</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>ANO</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </MDBCol>*/}
                    </MDBRow>
                </MDBCardTitle>
                <hr/>
                {chart}
                {/*<img src={image} alt="salarios"/>*/}
                <div style={{fontSize: '12px', color:"#5c5c5c"}}><br/>Estão sendo considerados apenas os salários a partir de Julho/1994</div>
            </MDBCardBody>
        </MDBCard>
    );
}

export default ChartSalarios;
