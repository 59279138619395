import React from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardTitle,
    MDBIcon,
    MDBRow,
    MDBCol,
    MDBContainer,
    MDBBtn
} from "mdbreact";
import dados from "../../../assets/dados.png";
import calculos from "../../../assets/calculos.png";


const Card = (props) => {
    let img = dados;
    if(props.img!=='dados'){
        img = calculos;
    }

    return (
        <MDBContainer style={{ marginTop: "1.5rem"}}>
            <MDBCard>
                <MDBCardBody className="text-center">
                    <img src={img} alt={props.title} title={props.title} />
                    <h2 className="mt-3">{props.title}</h2>
                    <p style={{maxWidth: "320px", margin: "auto"}} className="mb-3">{props.teaser}</p>
                    <MDBBtn color={props.color} onClick={props.clickBtn1}>
                        <MDBIcon icon={props.icon} />&nbsp;&nbsp;&nbsp;{props.btn}
                    </MDBBtn>
                    <MDBBtn color={props.color2}  style={{display: props.btn2 ? '' : 'none'}} onClick={props.clickBtn2}>
                        <MDBIcon icon={props.icon2} />&nbsp;&nbsp;&nbsp;{props.btn2}
                    </MDBBtn>
                    {
                        props.btn3 ? (
                            <MDBBtn color={props.color2} onClick={props.clickBtn3}>
                                <MDBIcon icon={props.icon3} />&nbsp;&nbsp;&nbsp;{props.btn3}
                            </MDBBtn>
                        ) : null
                    }
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
}

export default Card;
