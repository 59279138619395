import React, {useEffect, useRef, useState} from "react";
import { Doughnut  } from "react-chartjs-2";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import {Link} from "react-router-dom";

const ChartVinculos = (props) => {

    const [data, setData] = useState({});
    const [chart, setChart] = useState(null);
    const chartRef = useRef(null);

    useEffect(() => {
        //console.log('PROPS CHART VINCULOS', props);
        let tempData = {
            //labels: ["January", "February", "March", "April", "May", "June", "July"],
            labels: props.vinculos,
            datasets: [
                {
                    data: props.totais,
                    backgroundColor: [
                        '#00C851','#33b5e5',
                        '#FF8800','#ff4444',
                        '#007E33','#9933CC',
                        '#0d47a1','#263238',
                        '#9e9d24','#795548',
                        '#00897b','#40c4ff',
                        '#827717','#00c853',
                        '#e65100','#8d6e63',
                        '#59698d','#ffab00',
                        '#ffab40','#1b5e20',
                        '#3949ab','#ba68c8',
                    ],
                },
            ]
        }
        setData(tempData);
    }, [props.vinculos]);

    useEffect(() => {
        //console.log('CHART VINCULOS', data);
        let doughnut = (<Doughnut ref={chartRef} data={data} options={
            {
                responsive: true,
                legend: {
                    position: 'top',
                    labels:{
                        padding: 15,
                        textAlign: 'left',
                        color: 'rgba(100,100,100, 1)'
                    },


                }
            }
        } />);
        setChart(doughnut);
    }, [data]);

    useEffect(() => {

        if(chart && data){
            const base64Image = chartRef.current.chartInstance.toBase64Image();
            props.getImage(base64Image);
        }
    }, [props.generateImage]);

    return (
        <MDBCard>
            <MDBCardBody>
                <MDBCardTitle>
                    <MDBRow>
                        <MDBCol><strong>Vínculos</strong></MDBCol>
                        {/*<MDBCol className="text-right" style={{fontSize: "12px", fontWeight: 'bold'}}>
                            <span>7D</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>1M</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>3M</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>1A</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>MÊS</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>ANO</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </MDBCol>*/}
                    </MDBRow>
                </MDBCardTitle>
                <hr/>
                {chart}
                <div style={{fontSize: '12px', color:"#5c5c5c"}}><br/>.</div>
            </MDBCardBody>
        </MDBCard>
    );
}

export default ChartVinculos;
