import React from 'react';
import { MDBLink, MDBIcon } from "./styles";

const EditButton = (props) => {

    return (
        <MDBLink to={props.to} onClick={props.onClick}>
            <MDBIcon icon="edit" alt="editar" title="editar"/>
        </MDBLink>
    );
};

export default EditButton;

