import React, {useEffect, useState} from 'react';
import {MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import PageHeader from "../../../components/PageHeader";
import {MDBCard, MDBCardBody} from "../Calculo/styles";
import {optionsSexo} from "../Cliente/options";
import {SingleSelect} from "react-select-material-ui";
import api from "../../../services/api";
import './styles.css';
import ListCliente from "../Cliente/list";
import AddButton from "../../../components/Buttons/AddButton";
import ListButton from "../../../components/Buttons/ListButton";
import Insert from "../Cliente/insert";
import Edit from "../Cliente/edit";
import ListConteudo from "./list";
import View from "./view";

const Conteudo = () => {

    const module = {singular: "Escola de Planejamento", plural: "Escola de Planejamento", gender: 1}
    const [crudComponent, setCrudComponent] = useState(<ListConteudo view={showView} />);
    const listButton = <ListButton module="" onClick={showList}/>;

    const [button, setButton] = useState(null);

    function showList(){
        setCrudComponent(<ListConteudo view={showView} />);
        setButton(null);
    }

    function showView(id){
        //console.log('show edit');
        setCrudComponent(<View id={id} />);
        setButton(listButton);
    }

    return (
        <MDBContainer fluid>
            <MDBRow>
                <MDBCol>
                    <PageHeader title={module.singular} button={button}/>
                </MDBCol>
            </MDBRow>
            {crudComponent}
            <br/><br/>
        </MDBContainer>
    );
};


export default Conteudo;