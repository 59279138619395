import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCol,
    MDBInput, MDBModal,
    MDBModalBody, MDBModalFooter,
    MDBModalHeader,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdbreact";
import api from "../../../services/api";
import EditButton from "../../../components/Buttons/EditButton";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import {MDBIcon} from "../../../components/Buttons/DeleteButton/styles";

const ListCnis = (props) => {

    const [items, setItems] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState(false);
    const [deleteId, setDeleteId] = useState(false);

    useEffect(() => {
        list();
    }, []);

    async function list(search = ''){
        try{
            const result = await api.get('/api/list-cnis', {
                params: {
                    search: search,
                    columns: [
                        {name: 'arquivo', operator: 'like', percent: 2},
                        {name: 'nome', operator: 'like', percent: 2},
                        {name: 'cpf', operator: 'like', percent: 1},
                    ]
                }
            });
            setItems(result.data);
            console.log(result.data);
        }catch (e){

        }
    }

    function handleKeyDownSearch(e){
        if(e.key === 'Enter'){
            list(e.target.value);
        }
    }

    function askDelete(item){
        setDeleteTitle(item.arquivo);
        setDeleteId(item.id);
        setDeleteModal(true);
    }

    async function deleteItem(id){
        setDeleteLoading(true);
        try{
            const result = await api.delete('/api/cnis/'+id);
            list();
            setDeleteLoading(false);
            setDeleteModal(false);
        }catch (error){
            console.log(error);
            setDeleteLoading(false);
        }
    }

    return (
        <div>
            <MDBModal isOpen={deleteModal} centered>
                <MDBModalHeader>Excluir</MDBModalHeader>
                <MDBModalBody>
                    Deseja excluir o item {deleteTitle}?
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="default" onClick={() => setDeleteModal(false)}>Não</MDBBtn>
                    <MDBBtn color="danger" style={{display: (deleteLoading ? 'none' : '')}} onClick={() => deleteItem(deleteId)}>Sim</MDBBtn>
                    <MDBBtn color="danger" outline disabled style={{display: (deleteLoading ? '' : 'none')}}><MDBIcon icon="spinner" spin/> Sim</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
            <MDBInput hint="Pesquisar" type="text" containerClass="mt-0"  onKeyDown={handleKeyDownSearch}/>
            <MDBTable responsive hover>
                <MDBTableHead>
                    <tr>
                        <th>Arquivo</th>
                        <th>Nome</th>
                        <th>Cpf</th>
                        <th>&nbsp;</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        items.map((item, index) => {
                            return (
                                <tr key={'cnis_'+index}>
                                    <td onClick={() => props.selectCnis(item.id)} style={{cursor: 'pointer'}}>{item.arquivo}</td>
                                    <td onClick={() => props.selectCnis(item.id)} style={{cursor: 'pointer'}}>{item.nome}</td>
                                    <td onClick={() => props.selectCnis(item.id)} style={{cursor: 'pointer'}}>{item.cpf}</td>
                                    <td><DeleteButton onClick={() => askDelete(item)}/></td>
                                </tr>
                            )
                        })
                    }
                </MDBTableBody>
            </MDBTable>
        </div>
    );
};

export default ListCnis;