import React, {useEffect, useState} from 'react';
import {
    MDBBadge,
    MDBBtn,
    MDBCardHeader,
    MDBCol,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow, MDBTable, MDBTableBody, MDBTableHead
} from "mdbreact";
import {MDBCard, MDBCardBody} from "../styles";
import InfoCalculos from "./infoCalculos";
import { styles } from "../stylesPDF";
import {Document, Page, PDFDownloadLink, Text, View} from "@react-pdf/renderer";
import VinculosPdf from "../vinculosPDF";

const Regra2 = (props) => {

    const [regras, setRegras] = useState(props.regras);
    const [calculos, setCalculos] = useState({});
    const [modalRmi, setModalRmi] = useState(false);
    const [direito, setDireito] = useState(false);
    const [rmi, setRmi] = useState(0);
    const [salarioBeneficio, setSalarioBeneficio] = useState(0);

    useEffect(() => {
        setRegras(props.regras);
        if(props.regras){
            setDireito(props.regras['REGRA2'].direito);
        }
    }, [props.regras]);

    useEffect(() => {
        setCalculos(props.calculos);
        //console.log(props.calculos);
    }, [props.calculos]);

    if(!props.regras){
        return (
            <Document>
                <Page size="A4" style={styles.page} wrap>
                    <View style={styles.section}>
                        <Text>&nbsp;</Text>
                    </View>
                </Page>
            </Document>
        )
    }

    function calcRmiOff(){
        return 0;
    }

    function calcRmi(calculos){
        /*
        - Fator Previdênciario:
		    - aplica o fator toda vez que for positivo
	    - Média 80
	    - Coeficente 70% + 1% por cada grupo de 12 contribuicoes mensais (limitado a 100%)
        */
        //console.log(calculos)
        let newSalarioBeneficio = calculos.media80;
        //console.log('Regra 2 - newSalarioBeneficio', newSalarioBeneficio);
        let calculo = calculos.fator > 1 ? newSalarioBeneficio * calculos.fator : salarioBeneficio;
        //console.log(calculo);
        let coeficiente = calculos.coeficiente70.coeficiente > 100 ? 100 : calculos.coeficiente70.coeficiente;
        //console.log(coeficiente);
        let newRmi = calculo * calculos.coeficiente70.coeficiente / 100;
        //console.log(rmi);
        props.handleRmi(props.data, 'REGRA2', newRmi);
        props.handleSalarioBeneficio(props.data, 'REGRA2', newSalarioBeneficio);
        setRmi(newRmi);
        setSalarioBeneficio(newSalarioBeneficio);
    }

    return (

            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.card}>
                            <View style={styles.cardHeader}>
                                <View style={{flex: 2}}>
                                    {
                                        props.direito ? (
                                            <View style={[styles.badge, styles.backSuccess]}>
                                                <Text>Possui direito</Text>
                                            </View>
                                        ) : (
                                            <View style={[styles.badge, styles.backDanger]}>
                                                {/*<View><Image style={styles.icon}  src={iconClose} /></View>*/}
                                                <View><Text>Não Possui direito</Text></View>
                                            </View>
                                        )
                                    }
                                </View>
                                <View style={[styles.tituloRegra, {flex: 5}]}>
                                    <Text>&nbsp;&nbsp;Aposentadoria por Idade (Direito Adquirido)</Text>
                                </View>
                                <View style={{flex: 2}}>
                                    {
                                        Object.keys(props.calculos).length > 0 && props.direito ?
                                            (
                                                <Text style={styles.rmiV2}>
                                                    S. Benefício: R$ {new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format(salarioBeneficio)}
                                                </Text>
                                            ) : null
                                    }
                                </View>
                                <View style={{flex: 1}}>
                                    {
                                        Object.keys(props.calculos).length > 0 && props.direito ?
                                            (
                                                <Text style={styles.rmiV2}>
                                                    RMI: R$ {new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format(rmi)}
                                                </Text>
                                            ) : null
                                    }
                                </View>
                            </View>
                            <View style={styles.cardBody}>
                                <View style={styles.table}>

                                    <View style={[styles.tableRow]}>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={[styles.tableCell]}>Idade</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={styles.tableCell}>Carência</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={[styles.tableRow]}>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={[styles.tableCell]}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={[styles.tableCell, styles.falta, {display: direito ? 'none' : ''}]}>
                                                {props.falta(regras['REGRA2'].falta.idade, 'idade')}
                                            </Text>
                                            <Text style={[styles.tableCell, styles.atual]}>
                                                {regras['REGRA2'].atual.idade}
                                            </Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={[styles.tableCell, styles.falta, {display: direito ? 'none' : ''}]}>
                                                {regras['REGRA2'].falta.contribuicoes > 0 ? "faltam "+regras['REGRA2'].falta.contribuicoes : " "}
                                            </Text>
                                            <Text style={[styles.tableCell, styles.atual]}>
                                                {regras['REGRA2'].atual.contribuicoes}
                                            </Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={[styles.tableRow]}>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={[styles.tableCell]}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "40%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                        <View style={[styles.tableCol, {width: "10%"}]}>
                                            <Text style={styles.tableCell}>&nbsp;</Text>
                                        </View>
                                    </View>

                                    <View style={{borderTop: 1, borderColor: '#ccc', paddingTop: 8}}>
                                        <Text style={styles.tableCell}><strong>Regra: </strong>{regras['REGRA2'].regra.idade} anos de idade e {regras['REGRA2'].regra.contribuicoes} de carência <br/></Text>
                                        <Text style={styles.tableCell}>OBS: São consideradas apenas as contribuições feitas até {regras['REGRA2'].regra.dataLimite}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={styles.br}>
                            <Text>&nbsp;</Text>
                        </View>


                        <View>
                            {
                                Object.keys(props.calculos).length > 0 && props.direito ?
                                    (
                                        <View style={styles.table}>
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, {width: "40%"}]}>
                                                    <Text style={styles.tableCell}>Média 80% maiores</Text>
                                                    <Text style={styles.tableCell}>R$ {props.calculos.media80.toString().replace('.', ',')}</Text>
                                                </View>
                                                {
                                                    (regras['REGRA2'].sexo === 1 && regras['REGRA2'].pontos < 96) ||
                                                    (regras['REGRA2'].sexo === 2 && regras['REGRA2'].pontos < 86) ?
                                                        (
                                                            <View style={[styles.tableCol, {width: "40%"}]}>
                                                                <Text style={styles.tableCell}>Fator Previdenciário &nbsp;&nbsp;</Text>
                                                                <Text style={styles.tableCell}>{props.calculos.fator}</Text>
                                                            </View>
                                                        ) : null
                                                }
                                            </View>
                                        </View>
                                    ) : null
                            }

                        </View>

                        {
                            Object.keys(props.calculos).length > 0 && props.direito ?
                                (
                                    <View>
                                        <View style={styles.table}>
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, {width: "25%"}]}>
                                                    <Text style={styles.tableCell}>Competência</Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "25%"}]}>
                                                    <Text style={styles.tableCell}>Original</Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "25%"}]}>
                                                    <Text style={styles.tableCell}>Índice</Text>
                                                </View>
                                                <View style={[styles.tableCol, {width: "25%"}]}>
                                                    <Text style={styles.tableCell}>Atualizado</Text>
                                                </View>
                                            </View>
                                            {
                                                props.calculos.remuneracoes.map((remuneracao, index) => {
                                                    /*let acumulado = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(remuneracao.acumulado);*/

                                                    let original = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(remuneracao.original);

                                                    let indice = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 6,
                                                        maximumFractionDigits: 6
                                                    }).format(remuneracao.indice);

                                                    let atualizado = new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(remuneracao.atualizado);

                                                    return (
                                                        <View style={styles.tableRow}>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>{remuneracao.competencia}</Text>
                                                            </View>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>R$ {original}</Text>
                                                            </View>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>{indice}</Text>
                                                            </View>
                                                            <View style={[styles.tableCol, {width: "25%"}]}>
                                                                <Text style={styles.tableCell}>R$ {atualizado}</Text>
                                                            </View>
                                                        </View>
                                                    );

                                                })
                                            }
                                        </View>
                                    </View>
                                ) : null
                        }


                    </View>

                </Page>
                {
                    props.vinculos ? (
                        <VinculosPdf vinculos={props.vinculos} />
                    ) : null
                }
            </Document>

    );
};

export default Regra2;
