export const meses = [
    {abrev: 'Jan', extenso: 'Janeiro'},
    {abrev: 'Fev', extenso: 'Fevereiro'},
    {abrev: 'Mar', extenso: 'Março'},
    {abrev: 'Abr', extenso: 'Abril'},
    {abrev: 'Mai', extenso: 'Maio'},
    {abrev: 'Jun', extenso: 'Junho'},
    {abrev: 'Jul', extenso: 'Julho'},
    {abrev: 'Ago', extenso: 'Agosto'},
    {abrev: 'Set', extenso: 'Setembro'},
    {abrev: 'Out', extenso: 'Outubro'},
    {abrev: 'Nov', extenso: 'Novembro'},
    {abrev: 'Dez', extenso: 'Dezembro'},
]