import React, {useEffect, useState} from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
    MDBTable
} from "mdbreact";
import {Link} from "react-router-dom";
import api from "../../../services/api";

const ItemCliente = (props) => {
    return (
        <MDBRow>
            <MDBCol>
                <h6>{props.item.nome}</h6>
                <p style={{fontSize: '11px'}}>{props.item.cpf}</p>
            </MDBCol>
            <MDBCol className="text-right">
                <span style={{fontSize: '13px'}}>{props.item.data}</span>
                <p style={{fontSize: '12px'}}>{props.item.hora}</p>
            </MDBCol>
        </MDBRow>
    );
}


const Clientes = () => {

    const lista = [
        {nome: "Bruno Passos", cpf: '111.111.111-11', data: "16/11/2020", hora: "20:52"},
        {nome: "Relison Galvão", cpf: '222.222.222-22', data: "11/11/2020", hora: "20:52"},
        {nome: "João Silva", cpf: '333.333.333-33', data: "20/10/2020", hora: "20:52"},
        {nome: "Maria Silva", cpf: '444.444.444-44', data: "11/10/2020", hora: "20:52"},
    ];

    const [clientes, setClientes] = useState(lista);
    const [loading, setLoading] = useState(false);

    const clientesElement = clientes.map((item, index) =>{
        return (
            <div key={"dashboard_cliente_"+index}>
                <ItemCliente item={item}/>
                <hr style={{display: index < clientes.length-1 ? '' : 'none', margin: '-6px 0 9px 0'}}/>
            </div>
        );
    })

    useEffect(() => {
        //list();
    }, []);

    async function list(search = ''){
        setLoading(true);
        try{
            const result = await api.get('/api/ultimos-cliente');
            setClientes(result.data)
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <a href="clientes" className="float-right box-card-btn float-right indigo darken-4" style={{color: '#FFFFFF', marginTop: '-5px', position: 'relative'}}>Adicionar</a>
                    <MDBCardTitle style={{fontSize: '1.2rem'}}>Últimos clientes</MDBCardTitle>
                    <hr/>
                    {clientesElement}
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Clientes;