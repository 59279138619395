import React, {useEffect, useState} from 'react';
import Regra1Relatorio from "./regras/regra1";
import Regra2Relatorio from "./regras/regra2";
import Regra3Relatorio from "./regras/regra3";
import Regra4Relatorio from "./regras/regra4";
import Regra5Relatorio from "./regras/regra5";
import Regra6Relatorio from "./regras/regra6";
import Regra6RelatorioOriginal from "./regras/Original/regra6";
import Regra7Relatorio from "./regras/regra7";
import Regra8Relatorio from "./regras/regra8";

const RegraRelatorio = (props) => {

    const [elemento, setElemento] = useState();

    useEffect(() => {
        montarRegra(props.regra);
    }, [props.regra]);

    function montarRegra(regra){
        //console.log(regra)
        let componentRegra = null;
        if(regra.regra === 1){
            componentRegra = <Regra1Relatorio regra={regra} removeButton={props.removeButton} removeElement={props.removeElement} data={props.data}/>
        }
        if(regra.regra === 2){
            componentRegra = <Regra2Relatorio regra={regra} removeButton={props.removeButton} removeElement={props.removeElement} data={props.data}/>
        }
        if(regra.regra === 3){
            componentRegra = <Regra3Relatorio regra={regra} removeButton={props.removeButton} removeElement={props.removeElement} data={props.data}/>
        }
        if(regra.regra === 4){
            componentRegra = <Regra4Relatorio regra={regra} removeButton={props.removeButton} removeElement={props.removeElement} data={props.data}/>
        }
        if(regra.regra === 5){
            componentRegra = <Regra5Relatorio regra={regra} removeButton={props.removeButton} removeElement={props.removeElement} data={props.data}/>
        }
        if(regra.regra === 6){
            componentRegra = <Regra6Relatorio regra={regra} hidePedagio={hidePedagio} removeButton={props.removeButton} removeElement={props.removeElement} data={props.data}/>
            /*componentRegra = (
                <div>
                    <Regra6Relatorio regra={regra} hidePedagio={hidePedagio} removeButton={props.removeButton} removeElement={props.removeElement} data={props.data}/>
                    <Regra6RelatorioOriginal regra={regra} hidePedagio={hidePedagio} removeButton={props.removeButton} removeElement={props.removeElement} data={props.data}/>
                </div>
            );*/
        }
        if(regra.regra === 7){
            componentRegra = <Regra7Relatorio regra={regra} removeButton={props.removeButton} removeElement={props.removeElement} data={props.data}/>
        }
        if(regra.regra === 8){
            componentRegra = <Regra8Relatorio regra={regra} hidePedagio={hidePedagio} removeButton={props.removeButton} removeElement={props.removeElement} data={props.data}/>
        }
        setElemento(componentRegra);
    }

    function hidePedagio(tempo){
        //console.log(tempo);
        //console.log(tempo.replace(/\D/gim, ''));
        return false;
        //return (tempo.anos < 0 || tempo.meses < 0 || tempo.dias < 0) || (tempo.anos === 0 && tempo.meses === 0 && tempo.dias < 0)
    }

    return (
        <div>
            {elemento}
        </div>
    );
};

export default RegraRelatorio;
