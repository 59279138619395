import React, {useEffect, useState} from 'react';
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import PageHeader from "../../../components/PageHeader";
import ListCalculo from "../Calculo/list";
import InsertCalculo from "./insert";
import AddButton from "../../../components/Buttons/AddButton";
import ListButton from "../../../components/Buttons/ListButton";
//import Insert from "../Calculo/insert";
import Edit from "../Calculo/edit";
import Card from "./card";
import NovoCalculo from "./novoCalculo";
import api from "../../../services/api";

const Calculo = () => {

    const module = {singular: "Cálculo", plural: "Cálculos", gender: 1}
    //const [crudComponent, setCrudComponent] = useState(<InsertCalculo />);
    const [crudComponent, setCrudComponent] = useState(<NovoCalculo showInsert={showInsert} showList={showList} setDadosCnis={setDadosCnis}/>);
    /*const addButton = <AddButton module={module.singular} gender={module.gender} onClick={showInsert} />;*/
    const addButton = <AddButton module={module.singular} gender={module.gender} onClick={showNovoCalculo} />;
    const listButton = <ListButton module={module.plural} onClick={showList}/>;
    const [button, setButton] = useState(null);
    const [button2, setButton2] = useState(null);
    const [cnis, setCnis] = useState(null);//DADOS VINDOS DO COMPONENT DE NOVO CALCULO

    useEffect(() => {
        if(cnis){
            showInsert();
        }
    }, [cnis]);

    function showInsert(){
        //console.log('show insert');
        setCrudComponent(<InsertCalculo cnis={cnis} etapa={1}/>);
        setButton(addButton);
        setButton2(listButton);
    }


    function showList(){
        setCrudComponent(<ListCalculo edit={showEdit} calculado={showCalculado} relatorio={showRelatorio} duplicar={duplicar}/>);
        setButton(addButton);
        setButton2(null);
    }

    function showEdit(id){
        console.log('show edit');
        //setCrudComponent(<Edit id={id} />);
        setCrudComponent(<InsertCalculo id={id} etapa={1}/>);
        setButton(addButton);
        setButton2(listButton);
    }

    //Vai para Tela de Montagem do relatório
    function showCalculado(id){
        setCrudComponent(<InsertCalculo id={id} etapa={3}/>);
        setButton(addButton);
        setButton2(listButton);
    }

    //Vai para Tela de Montagem do relatório
    function showRelatorio(id){
        setCrudComponent(<InsertCalculo id={id} etapa={4}/>);
        setButton(addButton);
        setButton2(listButton);
    }

    //duplica os dados de insert e vai para a tela de edição
    async function duplicar(id){
        //setDuplicarLoading(true);
        try{
            const result = await api.get('/api/duplicar-calculo/'+id);
            console.log(result.data);
            setCrudComponent(<InsertCalculo id={result.data.id} etapa={1}/>);
            //setDuplicarLoading(false);
        }catch (error){
            console.log(error);
            //setDuplicarLoading(false);
        }

    }

    //Vai para tela de exportação do relatório
    function showRelatorio(id){
        setCrudComponent(<InsertCalculo id={id} etapa={4}/>);
        setButton(addButton);
        setButton2(listButton);
    }

    function setDadosCnis(dadosCnis){
        setCnis(dadosCnis);
    }

    function showNovoCalculo(){
        setCrudComponent(<NovoCalculo showInsert={showInsert} showList={showList} setDadosCnis={setDadosCnis}/>);
        setButton(null);
        setButton2(null);
    }



    return (
        <MDBContainer fluid>
            <MDBRow>
                <MDBCol>
                    <PageHeader title={module.singular} button={button} button2={button2}/>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    {crudComponent}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default Calculo;

