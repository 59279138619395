import {validateCel, validateCpf, validateEmail} from "../../../utils/validate";

export default function validate(values) {
    let errors = {};

    if (!values.slug) {
        errors.slug = 'Informe o escritório';
    }

    if (!values.name) {
        errors.name = 'Informe o nome';
    }

    if(values.name){
        let partes = values.name.split(' ');
        if(partes.length < 2){
            errors.name = 'Informe nome e sobrenome';
        }else if(!partes[1]){
            errors.name = 'Informe nome e sobrenome';
        }
    }

    /*if (!/\d{3}\.\d{3}\.\d{3}-\d{2}/.test(values.cpf)) {
        errors.cpf = 'Cpf Inválido';
    }*/

    if(!values.cpf){
        errors.cpf = 'Informe o CPF';
    }else if(!validateCpf(values.cpf)){
        errors.cpf = 'CPF Inválido';
    }

    if(!values.cel){
        errors.cel = 'Informe o Cel';
    }else if(!validateCel(values.cel)){
        errors.cel = 'Cel inválido';
    }

    if (!values.email) {
        errors.email = 'Informe o email';
    }else if(!validateEmail(values.email)){
        errors.email = 'E-mail inválido';
    }

    if (!values.password) {
        errors.password = 'Informe a senha';
    }else if(values.password.length < 6){
        errors.password = 'A senha deve ter no mínimo 6 caracteres';
    }

    /*if (!/\d{3}\.\d{3}\.\d{3}-\d{2}/.test(values.cpf)) {
        errors.cpf = 'Cpf Inválido';
    }*/

    return errors;
};
