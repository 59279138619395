export const fatores = [
    {
        'sexo': 1, //Masculino
        'especial': [
            {'tipo': 0, 'fator': '1'},
            {'tipo': 15, 'fator': '2,33'},
            {'tipo': 20, 'fator': '1,75'},
            {'tipo': 25, 'fator': '1,4'},
        ]
    },
    {
        'sexo': 2, //Feminino
        'especial': [
            {'tipo': 0, 'fator': '1'},
            {'tipo': 15, 'fator': '2'},
            {'tipo': 20, 'fator': '1,5'},
            {'tipo': 25, 'fator': '1,2'}
        ]
    },
]

