import React from 'react';
import {MDBBtn, MDBIcon} from "mdbreact";

const AddButton = (props) => {

    //console.log('add', props);

    const text = props.gender === 1 ? 'Novo' : 'Nova';

    return (
        <MDBBtn  color="dark-green" className="px-4" onClick={props.onClick}>
            <MDBIcon icon="plus" />&nbsp;&nbsp;&nbsp;&nbsp;{text} {props.module}
        </MDBBtn>
    );
};

export default AddButton;

