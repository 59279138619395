import React from 'react';
import {View, Text} from "@react-pdf/renderer";
import {styles} from "../../pages/private/Calculo/stylesPDF";

const TopPdf = (props) => {

    const styles = {
        container: {
            flexDirection: 'row',
            backgroundColor: '#4285f4',
            color: '#fff',
            padding: 10,
            marginBottom: 10,
        },
        logo: {
            fontSize: 22,
        },
        info: {
            fontSize:  11,
            textAlign: 'right',
            paddingTop: 8,
        },
        br: {
          padding: 10,
        }
    }

    return (
        <View style={styles.container} fixed>
            <View style={[styles.logo, {flex: 3}]}>
                <Text style={{fontWeight: 'bold'}}>ICDS Prev</Text>
            </View>
            <View style={[styles.info, {flex: 2}]}>
                <Text>{props.info}</Text>
            </View>
        </View>
    );
};

export default TopPdf;

