import React, {createContext, useEffect, useState} from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBIcon
} from "mdbreact";
import Card from "./card";
import Chart from "./chart";
import Clientes from "./clientes";
import PageHeader from "../../../components/PageHeader";
import api from "../../../services/api";
import {meses} from "../../../utils/meses";
import Limites from "./limites";

const Dashboard = () => {

    const [loadingTotalClientes, setLoadingTotalClientes] = useState(false);
    const [loadingTotalCalculos, setLoadingTotalCalculos] = useState(false);
    const [loadingTotalCalculosMes, setLoadingTotalCalculosMes] = useState(false);
    const [loadingTotalCalculosMeses, setLoadingTotalCalculosMeses] = useState(false);
    const [totalClientes, setTotalClientes] = useState(0);
    const [totalCalculos, setTotalCalculos] = useState(0);
    const [totalCalculosMes, setTotalCalculosMes] = useState(0);
    const [totalCalculosMeses, setTotalCalculosMeses] = useState([]);
    const [mes, setMes] = useState('');
    const [labels, setLabels] = useState(['a', 'b', 'c', 'd', 'e', 'f']);
    const [data, setData] = useState([10,20,15,8,30,45]);

    useEffect(() => {
        let mes = '';
        getTotalClientes();
        getTotalCalculos();
        getTotalCalculosMes();
        getTotalCalculosMeses(6);
    }, []);

    async function getTotalClientes(){
        setLoadingTotalClientes(true);
        const result = await api.get('/api/total-clientes');
        setTotalClientes(result.data);
        setLoadingTotalClientes(false);
    }

    async function getTotalCalculos(){
        setLoadingTotalCalculos(true);
        const result = await api.get('/api/get-total-calculos-user');
        setTotalCalculos(result.data);
        setLoadingTotalCalculos(false);
    }

    async function getTotalCalculosMes(){
        let date = new Date();
        setMes(meses[date.getMonth()].extenso);
        setLoadingTotalCalculos(true);
        const result = await api.get('/api/get-total-calculos-user-mes');
        setTotalCalculosMes(result.data);
        setLoadingTotalCalculos(false);
    }

    async function getTotalCalculosMeses(qtd){
        setLoadingTotalClientes(true);
        const result = await api.get('/api/get-total-calculos-user-meses/'+qtd);
        let newTotalCalculosMeses = result.data;
        let newLabels = [];
        let newData = [];
        for(let i in newTotalCalculosMeses){
            newLabels.push(meses[newTotalCalculosMeses[i].month-1].abrev+'/'+newTotalCalculosMeses[i].year);
            newData.push(newTotalCalculosMeses[i].qtd);
        }
        setLabels(newLabels);
        setData(newData);
        setTotalCalculosMeses(newTotalCalculosMeses);
        setLoadingTotalCalculosMeses(false);
    }


    return(
        <MDBContainer fluid>
            <MDBRow>
                <MDBCol>
                    <PageHeader title="Dashboard" />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    <Limites/>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol xl="4" lg="4" md="6" sm="6">
                    {Card({title: 'Clientes', content: totalClientes, add: 'clientes', icon: 'fa-users', color: '#3700B3', color2: '#2E0097'})}
                </MDBCol>
                <MDBCol xl="4" lg="4" md="6" sm="6">
                    {Card({title: 'Cálculos', content: totalCalculos, add: 'calculos', icon: 'fa-calculator', color: '#6200EE', color2: '#5200C7'})}
                </MDBCol>
                <MDBCol xl="4" lg="4" md="6" sm="6">
                    {Card({title: 'Cálculos '+mes, content: totalCalculosMes, add: 'calculos', icon: 'fa-calculator', color: '#19B2A3', color2: '#048D7F'})}
                </MDBCol>
            </MDBRow>
            <MDBRow style={{marginTop: '1.5rem'}}>
                <MDBCol xl="7" lg="7" md="12">
                        {
                            totalCalculosMeses.length > 0 ? (
                                <Chart labels={labels} data={data} height={'200px'} />
                            ) : null
                        }
                </MDBCol>
                <MDBCol xl="5" lg="5" md="12">
                    <Clientes />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default Dashboard;


