import {validateCel, validateCep, validateCNPJ, validateCpf, validateEmail} from "../../../utils/validate";

export default function validate(values) {
    let errors = {};

    if (!values.nome) {
        errors.nome = 'Informe o nome';
    }

    /*if (!/\d{3}\.\d{3}\.\d{3}-\d{2}/.test(values.cpf)) {
        errors.cpf = 'CPF Inválido';
    }*/

    if(!values.cpf){
        errors.cpf = 'Informe o CPF';
    }else if(!validateCpf(values.cpf)){
        errors.cpf = 'CPF Inválido';
    }

    if (!values.sexo) {
        errors.sexo = 'Informe o sexo';
    }

    if (values.email) {
        if(!validateEmail(values.email)){
            errors.email = 'E-mail inválido';
        }
    }

    if (values.cel) {
        if(!validateCel(values.cel)){
            errors.cel = 'Cel inválido';
        }
    }

    if (values.tel) {
        if(!validateCel(values.tel)){
            errors.tel = 'Tel inválido';
        }
    }

    if (values.tel2) {
        if(!validateCel(values.tel2)){
            errors.tel2 = 'Tel2 inválido';
        }
    }

    if (values.cep) {
        if(!validateCep(values.cep)){
            errors.cep = 'Cep inválido';
        }
    }

    if (!values.nascimento) {
        errors.nascimento = 'Informe o nascimento';
    }else{
        console.log(values.nascimento);
        const strData = values.nascimento;
        const partesData = strData.split("/");
        const nascimento = new Date(partesData[0], partesData[1] - 1, partesData[2]);

        if (!nascimento > new Date()) {
            errors.nascimento = 'Data inválida';
        }
    }

    /*if (!values.estado_civil) {
        errors.estado_civil = 'Informe o estado civil';
    }*/

    return errors;
};