import React, {createContext, useEffect, useState} from 'react';
import {
    MDBAlert,
    MDBBtn,
    MDBCard,
    MDBCardHeader, MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBInput,
    MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader,
    MDBRow, MDBTable, MDBTableHead
} from "mdbreact";
import { MDBCardBody } from "./styles";
import './styles.css';
import api from "../../../services/api";
import {SingleSelect} from "react-select-material-ui";
import {optionsSexo} from "../Cliente/options";
import {Link} from "react-router-dom";
import Sequencia from "./sequencia";
import Contribuicoes from "./contribuicoes";
import Sequencias from "./sequencias";
import CreateButton from "../../../components/Buttons/CreateButton";
import { useHistory } from "react-router-dom";
import Calcular from "./calcular";
import Relatorio from "./relatorio";
import tiposVinculos from "./tiposVinculos";
import validateCliente from "./validateCliente";
import { salarios } from "./salarios";
import { fatores } from "./fatores"
import ExportarRelatorio from "./relatorio/exportar";
import Card from "./card";
import {maskCNPJ, maskCpf} from "../../../utils/mask";
import {descriptionErrors} from "../../../utils/validate";

import imgUser from "../../../assets/user-h.png";
import imgUserM from "../../../assets/user-m.png";


//ESTE COMPONENTE É UTILIZADO TANTO PARA INSERÇÃO QUANTO EDIÇÃO
const InsertCalculo = (props) => {

    //const history = useHistory();
    const [etapa, setEtapa] = useState(1);
    const [calculo, setCalculo] = useState({
        tipo_calculo_id: 1
    });
    const [cnisFile, setCnisFile] = useState(null);
    const [segurado, setSegurado] = useState({
        id: null,
        nome: null,
        cpf: null,
        nascimento: null,
        sexo: null
    });
    const [avisoSeguradoExistente, setAvisoSeguradoExistente] = useState(false);
    const [sequencias, setSequencias] = useState([]);
    const [errorsSegurado, setErrorsSegurado] = useState({error:true});
    const [firstHandleSegurado, setFirstHandleSegurado] = useState(false);
    const [infoErrorsSegurado, setInfoErrorsSegurado] = useState(false);
    const [errorsSequencia, setErrorsSequencia] = useState({});
    const [hasErrors, setHasErrors] = useState(true);
    const optionsVinculo = {

    }
    const [modalContribuicoes, setModalContribuicoes] = useState(false);
    const [titleContribuicoes, setTitleContribuicoes] = useState([]);
    const [contribuicoes, setContribuicoes] = useState([]);
    const [indexSeqContribuicoes, setIndexSeqContribuicoes] = useState(0);
    const [loadingCnis, setLoadingCnis] = useState(false);
    const [saving, setSaving] = useState(false);
    const [calculoId, setCalculoId] = useState(0);
    const [loadingEdit, setLoadingEdit] = useState(false);

    const [temposVinculos, setTemposVinculos] = useState([]);
    const [chartSalariosImage, setChartSalariosImage] = useState(null);
    const [chartVinculosImage, setChartVinculosImage] = useState(null);

    const [salariosMinimos, setSalariosMinimos] = useState([]);
    const [salariosTetos, setSalariosTetos] = useState([]);
    //Usado para pegar os salários obtidos no component de calcular a fim de passar parao componente de exporta
    //para poder utilizar no gráfico de salários
    const [salariosMeses, setSalariosMeses] = useState();
    const [salariosMinimosMeses, setSalariosMinimosMeses] = useState();//é usado para o gráfico de salários
    const [totaisVinculos, setTotaisVinculos] = useState();//é usado para o gráfico de vinculos

    useEffect(() => {
        if(props.id){
            get(props.id);
        }
        getSalarios();
        getSalariosTetos();
    }, []);


    useEffect(() => {
        if(props.cnis){
            loadDadosCnis(props.cnis);
        }
    }, [props.cnis]);

    useEffect(() => {
        definirFatores();
    }, [segurado]);

    useEffect(() => {
        console.log('etapa', props.etapa);
        setEtapa(props.etapa);
    }, [props.etapa]);

    function definirFatores(){

    }

    useEffect(() => {
        //console.log('useEffect errorsSequencia', errorsSequencia);
        setHasErrors(verifyErrors());
    }, [errorsSegurado, errorsSequencia]);

    //Apenas para testar a validação, poderá ser excluído posteriormente
    useEffect(() => {
        //console.log(hasErrors);
    }, [hasErrors]);

    async function getCliente(cpf){
        try{
            const result = await api.get('/api/get-cliente-cpf/'+cpf);
            let cliente = result.data;
            const tempSegurado = {
                ...segurado,
                id: cliente.id,
                sexo: cliente.sexo,
                nome: cliente.nome,
                cpf: cpf,
                nascimento: cliente.nascimento
            };
            setSegurado(tempSegurado);
            setErrorsSegurado(validateCliente(tempSegurado));
        }catch (error){
            console.log(error);
        }
    }

    async function getSalarios(){
        try{
            const result = await api.get('/api/get-salario-minimo');
            //console.log(result.data);
            setSalariosMinimos(result.data);
        }catch (error){
            console.log(error);
        }
    }

    async function getSalariosTetos(){
        try{
            const result = await api.get('/api/get-salario-teto');
            //console.log(result.data);
            setSalariosTetos(result.data);
        }catch (error){
            console.log(error);
        }
    }

    function handleSegurado(event){
        setFirstHandleSegurado(true);//Para identificar a primeira alteração no form. Necessário para mostrar o alerta de erros apenas depois disso.
        let { value, id } = event.target;
        if(id==='cpf'){
            value = maskCpf(value);
            if(value.length === 14){
                getCliente(value)
            }
        }
        const tempSegurado = {...segurado, [id]: value};
        setSegurado(tempSegurado);
        //console.log(validateCliente(tempSegurado));
        setErrorsSegurado(validateCliente(tempSegurado));
    }


    function handleSexo(value){

        const tempSegurado = {...segurado, sexo: value};
        setSegurado(tempSegurado);
        //console.log(validateCliente(tempSegurado));
        setErrorsSegurado(validateCliente(tempSegurado));

        let newSequencias = [...sequencias];

        for(let k in sequencias){
            let especial = sequencias[k].especial;
            for (let i in fatores){
                if(fatores[i]['sexo'] === value){
                    for(let j in fatores[i]['especial']){
                        if(fatores[i]['especial'][j]['tipo'] === especial){
                            let fator = fatores[i]['especial'][j]['fator'];
                            newSequencias[k] = {...sequencias[k], ['fator']: fator}
                        }
                    }
                }
            }
        }

        setSequencias(newSequencias);

    }

    function factoryDadoSequencia(){
        return {
            seq: sequencias.length+1,
            codigoEmp: null,
            origemVinculo: null,
            tipoFiliadoVinculo: 0,
            dataInicio: null,
            dataFim: null,
            especial: 0,
            fator: 1,
            remuneracoes: []
        }
    }

    function factoryDadoContribuicao(){
        return {
            competencia: null,
            remuneracao: "0,00"
        }
    }

    function selectCnis(){
        setCnisFile(null);
        document.getElementById('cnisFile').value = null;
        document.getElementById('cnisFile').click()
    }

    function handleCnis(e){
        setCnisFile(e.target.files[0]);
    }

    useEffect(() => {
        //console.log('cnis alterado', cnisFile);
        if(cnisFile){
            importCnis();
        }
    }, [cnisFile])

    function handleValidate(){
        save();
    }

    function updateErrorsSequencia(seq, hasErrors){
        //console.log('updateErrorsSequencia', seq, sequencias);
        setErrorsSequencia({...errorsSequencia, [seq]: hasErrors});
    }

    function removeDeletedSequenciaFromErrors(seq){
        let newErrorsSequencia = errorsSequencia;
        //console.log(newErrorsSequencia);
        newErrorsSequencia[seq] = false;
        //console.log(newErrorsSequencia);
        setErrorsSequencia(newErrorsSequencia);
        setHasErrors(verifyErrors());
    }

    function verifyErrors(){
        //console.log("Erros", errorsSequencia);
        //console.log('erros segurado', errorsSegurado);
        //console.log('erros sequencia', errorsSequencia);
        //console.log('tem erros', hasErrors);

        //verificar erros nos vinculos (sequencias) - TEMPORARIAMENTE DESATIVADO PARA ACERTOS
        for (let i in errorsSequencia){
            if(errorsSequencia[i]){
                return true;
            }
        }

        //verificar erros no segurado
        if(Object.keys(errorsSegurado).length > 0){
            return true;
        }

        return false;
    }

    async function save(){
        setSaving(true);
        const result = await api.post('/api/calculo', {calculo: calculo, segurado: segurado, vinculos: sequencias, id:calculoId});
        //console.log('result', result);
        //console.log('result.data', result.data);
        //console.log('result.data.return', result.data.return);
        //console.log('result.data.return.id', result.data.return.id);
        setSaving(false);
        if(result.data.return){
            setCalculoId(result.data.return.id);
            setEtapa(2);
        }

    }

    async function importCnis(){

        setSequencias([]);
        setErrorsSequencia({});
        setLoadingCnis(true);

        const formData = new FormData();
        formData.append(
            "cnisFile",
            cnisFile,
            cnisFile.name
        );

        const result = await api.post('/api/import-cnis', formData);
        /*const dados = result.data.cnis.dados;
        const cliente = result.data.cliente;
        let tempSequencias = result.data.cnis.sequencias;*/
        let data = result.data;
        loadDadosCnis(data);
    }


    function loadDadosCnis(data){
        const dados = data.cnis.dados;
        const cliente = data.cliente;
        let tempSequencias = data.cnis.sequencias;

        const arrayDate = dados.nascimento.split('/');
        const nascimento = arrayDate[2]+"-"+arrayDate[1]+"-"+arrayDate[0]
        const tempSegurado = {
            ...segurado,
            id: cliente ? cliente.id : null,
            sexo: cliente ? cliente.sexo : null,
            nome: cliente ? cliente.nome : dados.nome,
            cpf: cliente ? cliente.cpf : dados.cpf,
            nascimento: nascimento
        };
        setSegurado(tempSegurado);
        setErrorsSegurado(validateCliente(tempSegurado));

        //console.log('Cliente existe: '+!!cliente)
        setAvisoSeguradoExistente(!!cliente);
        for(let i in tempSequencias){
            //console.log(tempSequencias[i]);
            tempSequencias[i].obsFim = '';

            const arrayInicio = tempSequencias[i].dataInicio.split('/');
            tempSequencias[i].dataInicio = arrayInicio[2]+"-"+arrayInicio[1]+"-"+arrayInicio[0]

            const arrayFim = tempSequencias[i].dataFim ? tempSequencias[i].dataFim.split('/') : null;
            tempSequencias[i].dataFim = arrayFim ? arrayFim[2]+"-"+arrayFim[1]+"-"+arrayFim[0] : null

            tempSequencias[i].especial = 0;
            tempSequencias[i].fator = 1;
        }

        setSequencias(tempSequencias);

        setLoadingCnis(false);
    }

    //utilizado para trazer os dados de cnis salvo anteriormente
    async function get(id){

        setLoadingEdit(true);
        setCalculoId(id);


        const result = await api.get('/api/calculo/'+id);
        //console.log(result.data);
        const cliente = result.data.cliente;
        let nascimento = cliente.nascimento;
        let tempSegurado = {
            ...segurado,
            id: cliente ? cliente.id : null,
            sexo: cliente ? cliente.sexo : null,
            nome: cliente ? cliente.nome : dados.nome,
            cpf: cliente ? maskCpf(cliente.cpf) : dados.cpf,
            nascimento: nascimento
        }
        setSegurado(tempSegurado);
        setErrorsSegurado(validateCliente(tempSegurado));

        let tempSequencias = result.data.vinculos;
        for(let i in tempSequencias){

            tempSequencias[i].seq = i;

            tempSequencias[i].codigoEmp = tempSequencias[i].codigo;
            tempSequencias[i].origemVinculo = tempSequencias[i].origem;
            tempSequencias[i].tipoFiliadoVinculo = tempSequencias[i].tipo_filiado;
            tempSequencias[i].obsFim = tempSequencias[i].obs_fim;

            //const arrayInicio = tempSequencias[i].inicio.split('/');
            //tempSequencias[i].inicio = arrayInicio[2]+"-"+arrayInicio[1]+"-"+arrayInicio[0]
            tempSequencias[i].dataInicio = tempSequencias[i].inicio.substr(0);

            //const arrayFim = tempSequencias[i].fim ? tempSequencias[i].fim.split('/') : null;
            //tempSequencias[i].fim = arrayFim ? arrayFim[2]+"-"+arrayFim[1]+"-"+arrayFim[0] : null
            tempSequencias[i].dataFim = tempSequencias[i].fim.substr(0);


            tempSequencias[i].remuneracoes = tempSequencias[i].contribuicoes;

            for(let j in tempSequencias[i].remuneracoes){
                tempSequencias[i].remuneracoes[j].remuneracao = new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(tempSequencias[i].remuneracoes[j].remuneracao);
            }
        }
        //console.log(tempSequencias);

        setSequencias(tempSequencias);



    }


    function addSequencia(){
        setSequencias([...sequencias, factoryDadoSequencia()]);
    }

    function removeSequencia(seq){
        //console.log('removeSequencia', seq);
        const newSequencia = sequencias.filter((item) => item.seq !== seq);
        removeDeletedSequenciaFromErrors(seq);
        setSequencias(newSequencia);
        //console.log(newSequencia);
    }

    function handleSequencia(index, e){
        //console.log(e);
        let newSequencias = [...sequencias];
        let value = e.target.value;
        const id = e.target.id.split('_')[0];
        //console.log(id);
        if(id==='codigoEmp'){
            value  = value.replace(/\D/g, "");
            if(value.length === 14) {
                value = maskCNPJ(value);
            }
        }
        newSequencias[index] = {...sequencias[index], [id]: value}
        setSequencias(newSequencias);
        //console.log(sequencias);
    }

    function handleEspecialSequencia(index, value){
        //console.log(value);
        let newSequencias = [...sequencias];
        //const value = e.target.value;
        const id = 'especial';

        let fator = newSequencias[index].fator;
        for (let i in fatores){
            if(fatores[i]['sexo'] === segurado.sexo){
                for(let j in fatores[i]['especial']){
                    if(fatores[i]['especial'][j]['tipo'] === value){
                        fator = fatores[i]['especial'][j]['fator'];
                    }
                }
            }
        }

        newSequencias[index] = {...sequencias[index], [id]: value, ['fator']: fator}
        setSequencias(newSequencias);
        //console.log(sequencias);
    }

    function showContribuicoes(index){
        setIndexSeqContribuicoes(index);
        setContribuicoes(sequencias[index].remuneracoes);
        setTitleContribuicoes(sequencias[index].origemVinculo)
        setModalContribuicoes(true);
    }

    function addContribuicao(index){
        //console.log(index);
        let newSequencias = [...sequencias];
        newSequencias[index] = {
            ...sequencias[index],
            ['remuneracoes']: [...sequencias[index].remuneracoes, factoryDadoContribuicao()]
        }
        setSequencias(newSequencias);
        setContribuicoes(newSequencias[index].remuneracoes);
        //console.log(sequencias);
    }

    function removeContribuicao(indexSeq, indexContribuicao){
        let newSequencias = [...sequencias];
        newSequencias[indexSeq] = {
            ...sequencias[indexSeq],
            ['remuneracoes']: newSequencias[indexSeq].remuneracoes.filter((item, index) => index !== indexContribuicao)
        }
        setSequencias(newSequencias);
        setContribuicoes(newSequencias[indexSeq].remuneracoes);
    }

    function handleContribuicao(indexSeq, indexContribuicao, e){
        const value = e.target.value;
        const id = e.target.id.split('_')[0];

        let newContribuicoes = [...sequencias[indexSeq].remuneracoes];
        newContribuicoes[indexContribuicao] = {
            ...sequencias[indexSeq].remuneracoes[indexContribuicao],
            [id]: value
        }

        let newSequencias = [...sequencias];
        newSequencias[indexSeq] = {
            ...sequencias[indexSeq],
            ['remuneracoes']: newContribuicoes
        }

        setSequencias(newSequencias);
        setContribuicoes(newSequencias[indexSeq].remuneracoes);
        //console.log(sequencias);
    }

    function dados(){
        if(calculoId > 0){
            get(calculoId);
            setEtapa(1);
        }
    }




    return (
            <MDBContainer fluid>
                <ul className="stepper stepper-horizontal">
                    <li className={'step ' + (etapa === 1 ? 'active' : 'completed')}>
                        {/*<a onClick={() => dados()}>*/}
                        <a>
                            <span className="circle">1</span>
                            <span className="label">Dados</span>
                        </a>
                    </li>
                    <li className={etapa === 2 ? 'active' : (etapa > 2 ? 'completed' : '')}>
                        {/*<a  onClick={() => setEtapa(2)}>*/}
                        <a>
                            <span className="circle">2</span>
                            <span className="label">Cálculos</span>
                        </a>
                    </li>
                    <li className={etapa === 3 ? 'active' : (etapa > 3 ? 'completed' : '')}>
                        {/*<a onClick={() => setEtapa(3)}>*/}
                        <a>
                            <span className="circle">3</span>
                            <span className="label">Relatório</span>
                        </a>
                    </li>
                    <li className={etapa === 4 ? 'active' : (etapa > 3 ? 'completed' : '')}>
                        <a>
                            <span className="circle">4</span>
                            <span className="label">Exportar</span>
                        </a>
                    </li>
                </ul>
                {/*ETAPA 1=======================================================================================================*/}
                <div style={{display: etapa === 1 ? '' : 'none'}}>

                    <MDBModal isOpen={modalContribuicoes} centered>
                        <MDBModalHeader>{titleContribuicoes}</MDBModalHeader>
                        <MDBModalBody style={{maxHeight: '500px', overflowY: 'scroll'}}>
                            <Contribuicoes
                                indexSeq={indexSeqContribuicoes}
                                constribuicoes={contribuicoes}
                                removeContribuicao={removeContribuicao}
                                handleContribuicao={handleContribuicao}
                                salariosMinimos={salariosMinimos}
                                salariosTetos={salariosTetos}
                                addContribuicao={addContribuicao}
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="default" onClick={() => setModalContribuicoes(false)}>Fechar</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>

                    <MDBRow>
                        <MDBCol xl="3" lg="3" md="12" sm="12">
                            {/*/////////////*/}
                            <MDBRow>
                                <MDBCol xl="5" lg="4" md="4" sm="3"><hr/></MDBCol>
                                <MDBCol xl="2" lg="4" md="4" sm="6" className="text-center grey-text pt-1">Dados</MDBCol>
                                <MDBCol xl="5" lg="4" md="4" sm="3"><hr/></MDBCol>
                            </MDBRow>

                            <p className="warning-color text-center" style={{borderRadius: '5px', padding: '10px', display: (avisoSeguradoExistente ? '' : 'none')}}>Este segurado já está cadastrado e foi importado do Banco de dados!</p>
                            {/*{(avisoSeguradoExistente ? <p className="warning-color text-center" style={{borderRadius: '5px', padding: '10px'}}>Este segurado já está cadastrado e foi importado do Banco de dados!</p> : <br/>)}*/}

                            <div className={"float-right text-right"+(Object.keys(errorsSegurado).length === 0 || !firstHandleSegurado ? " d-none d-sm-none" : "")}>
                                <a onClick={() => setInfoErrorsSegurado(!infoErrorsSegurado)}><MDBIcon icon={(!infoErrorsSegurado ? "exclamation-triangle" : "times")} className="text-danger"/></a>
                                <MDBCard m="50" className={"text-left"+(!infoErrorsSegurado ? " d-none d-sm-none" : "")}
                                         style={{position: 'absolute', right: 0, zIndex: '9999', padding: '10px'}}
                                >
                                    <MDBCardBody>
                                        <MDBCardText>
                                            {descriptionErrors(errorsSegurado)}
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </div>
                            <MDBRow>
                                <MDBCol md="12" sm="12" className="text-center">
                                    <img src={segurado.sexo !== 2 ? imgUser :  imgUserM} alt="" title="" width="50%"/>
                                </MDBCol>
                                <MDBCol md="12" sm="12" style={{display: calculoId ? '' : 'none'}}>
                                    <br/>
                                    <strong>Id: {calculoId}</strong>
                                </MDBCol>
                                <MDBCol md="12" sm="12">
                                    <MDBInput label="CPF" id="cpf" name="cpf"
                                              onChange={handleSegurado}
                                              containerClass={errorsSegurado.cpf ? "invalid-input" : ""}
                                              value={segurado.cpf}
                                    />
                                </MDBCol>
                                <MDBCol md="12" sm="12" style={{display: segurado.cpf && !errorsSegurado.cpf ? '' : 'none'}}>
                                    <MDBRow>
                                        <MDBCol md="12" sm="12">
                                            <MDBInput label="Nome" id="nome" name="nome"
                                                      onChange={handleSegurado}
                                                      containerClass={errorsSegurado.nome ? "invalid-input" : ""}
                                                      value={segurado.nome}
                                            />
                                        </MDBCol>
                                        <MDBCol md="12" sm="12">
                                            <MDBInput
                                                label="Nascimento"
                                                type="date"
                                                id="nascimento"
                                                onChange={handleSegurado}
                                                containerClass={errorsSegurado.nascimento ? "invalid-input" : ""}
                                                value={segurado.nascimento}
                                            />
                                        </MDBCol>
                                        <MDBCol md="12" sm="12">
                                            <SingleSelect
                                                label="Sexo"
                                                options={optionsSexo}
                                                onChange={handleSexo}
                                                className={errorsSegurado.sexo ? "invalid-select" : ""}
                                                value={segurado.sexo}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>

                            </MDBRow>
                            <br/>
                            <MDBRow style={{display: Object.keys(errorsSegurado).length === 0 ? '' : 'none'}}>
                                <MDBCol className="text-center">
                                    <MDBBtn color="primary" size="sm" onClick={addSequencia}><MDBIcon icon="plus"/>&nbsp;&nbsp;&nbsp;Novo Vínculo</MDBBtn>
                                </MDBCol>
                            </MDBRow>

                            {/*/////////////*/}
                        </MDBCol>
                        <MDBCol xl="9" lg="9" md="12" sm="12">
                            <MDBRow>
                                <MDBCol xl="5" lg="4" md="4" sm="3"><hr/></MDBCol>
                                <MDBCol xl="2" lg="4" md="4" sm="6" className="text-center grey-text pt-1">Vínculos</MDBCol>
                                <MDBCol xl="5" lg="4" md="4" sm="3"><hr/></MDBCol>
                            </MDBRow>
                            <Sequencias
                                sequencias={sequencias}
                                showContribuicoes={showContribuicoes}
                                removeSequencia={removeSequencia}
                                handleSequencia={handleSequencia}
                                handleEspecialSequencia={handleEspecialSequencia}
                                updateErrors={{updateErrorsSequencia}}
                                salariosMinimos={salariosMinimos}
                                salariosTetos={salariosTetos}
                            />


                            <br/>


                            <MDBRow style={{display: sequencias.length === 0 || loadingCnis || hasErrors ? 'none' : '' }}>
                                <MDBCol className="text-right">
                                    <div style={{display: (saving ? 'none' : '')}}>
                                        <CreateButton text="Salvar e Continuar" onClick={handleValidate} />
                                    </div>
                                    <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                                        <MDBIcon icon="spinner" spin />&nbsp;&nbsp;Salvar e Continuar
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>

                        </MDBCol>
                    </MDBRow>




                </div>
                {/*FIM ETAPA 1===================================================================================================*/}

                {/*ETAPA 2=======================================================================================================*/}
                <div style={{display: etapa === 2 ? '' : 'none'}}>
                    <Calcular
                        calculoId={calculoId}
                        setEtapa={setEtapa}
                        etapa={etapa}
                        setTemposVinculos={setTemposVinculos}
                        setChartSalariosImage={setChartSalariosImage}
                        setChartVinculosImage={setChartVinculosImage}
                        setSalariosMeses={setSalariosMeses}
                        setSalariosMinimosMeses={setSalariosMinimosMeses}
                        salariosMinimos={salariosMinimos}
                        salariosTetos={salariosTetos}
                        setTotaisVinculos={setTotaisVinculos}
                    />
                </div>
                {/*FIM ETAPA 2===================================================================================================*/}

                {/*ETAPA 3=======================================================================================================*/}
                <div style={{display: etapa === 3 ? '' : 'none'}}>
                    <Relatorio
                        calculoId={calculoId}
                        setEtapa={setEtapa}
                        etapa={etapa}
                        vinculos={temposVinculos}
                        setChartSalariosImage={setChartSalariosImage}
                        setChartVinculosImage={setChartVinculosImage}
                        chartSalariosImage={chartSalariosImage}
                        chartVinculosImage={chartVinculosImage}
                        salariosMeses={salariosMeses}
                        salariosMinimosMeses={salariosMinimosMeses}
                        salariosMinimos={salariosMinimos}
                        salariosTetos={salariosTetos}
                        totaisVinculos={totaisVinculos}
                    />
                </div>
                {/*FIM ETAPA 3===================================================================================================*/}

                {/*ETAPA 4=======================================================================================================*/}
                {
                    etapa === 4 ? (
                        <div style={{display: etapa === 4 ? '' : 'none'}}>
                            <ExportarRelatorio
                                calculoId={calculoId}
                                vinculos={temposVinculos}
                                chartSalariosImage={chartSalariosImage}
                                chartVinculosImage={chartVinculosImage}
                                salariosMinimos={salariosMinimos}
                                salariosTetos={salariosTetos}
                                totaisVinculos={totaisVinculos}
                            />
                        </div>
                    ) : (<div>&nbsp;</div>)
                }
                {/*<div style={{display: etapa === 4 ? '' : 'none'}}>
                    <ExportarRelatorio calculoId={calculoId} />
                </div>*/}
                {/*FIM ETAPA 4===================================================================================================*/}

            </MDBContainer>
    );
};

export default InsertCalculo;



