import React, {useContext, useEffect, useState} from 'react';

import { Route, Switch } from 'react-router-dom';

import PublicLayout from "./components/layout/public/PublicLayout";
import PrivateLayout from "./components/layout/private/PrivateLayout";

import AuthContext from "./store/auth";
import Home from './pages/public/Home/index';
import Login from './pages/public/Login/index';
import Dashboard from './pages/private/Dashboard/index';
import Cliente from "./pages/private/Cliente";
import Calculo from "./pages/private/Calculo";
import Signup from "./pages/public/Signup";
import Planos from "./pages/public/Planos";
import RelatorioRegra from "./pages/private/Calculo/RelatorioRegra";
import api from "./services/api";
import PlanoExpirado from "./pages/private/PlanoExpirado";
import Conteudo from "./pages/private/Conteudo";
import Usuario from "./pages/private/Usuario";


const Routes = () => {

    const publicRoutes = ['/', '/login', '/planos', '/assinar'];
    const { signed, planoExpirado } = useContext(AuthContext);

    console.log('signed', signed);
    console.log('planoExpirado', planoExpirado);

    const privateRoutes = signed
        ? (
            <div>
                <PrivateLayout>
                    {
                        planoExpirado ? (
                            <Route exact path='/plano-expirado' component={PlanoExpirado}/>
                        ) : (
                            <div>
                                <Route exact path='/dashboard' component={Dashboard}/>
                                <Route exact path='/clientes' component={Cliente}/>
                                <Route exact path='/calculos' component={Calculo}/>
                                <Route exact path='/conteudos' component={Conteudo}/>
                                <Route exact path='/usuario' component={Usuario}/>
                            </div>
                        )
                    }
                </PrivateLayout>
            </div>
        )
        : (
            <Route path="/" component={Login}/>
        );

    return(
        <Switch>

            <Route exact path={publicRoutes}>
                <PublicLayout>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/login' component={Login}/>
                    <Route exact path='/assinar' component={Signup}/>
                    <Route exact path='/planos' component={Planos}/>
                </PublicLayout>
            </Route>

            { privateRoutes }

            <Route
                render={function() {
                    return <h1>Página não encontrada</h1>
                }}
            />
        </Switch>
    );
}

export default Routes;
