export const optionsSexo = [
    {value: 1, label: "Masculino"},
    {value: 2, label: "Feminino"},
];

export const optionsEscolaridade = [
    {value: 0, label: "Nenhum"},
    {value: 1, label: "Ensino Fundamental Incompleto"},
    {value: 2, label: "Ensino Fundamental Completo"},
    {value: 3, label: "Ensino Médio Incompleto"},
    {value: 4, label: "Ensino Médio Completo"},
    {value: 5, label: "Ensino Superior Incompleto"},
    {value: 6, label: "Ensino Superior Completo"},
    {value: 7, label: "Pós Graduado"},
    {value: 8, label: "Mestrado"},
    {value: 9, label: "Doutorado"},
    {value: 10, label: "Pós Doutorado"},
];

export const optionsEstadoCivil = [
    {value: 0, label: "Nenhum"},
    {value: 1, label: "Solteiro"},
    {value: 2, label: "Casado"},
    {value: 3, label: "Divorciado"},
    {value: 4, label: "Separado"},
    {value: 5, label: "Viúvo"},
];
