import styled from "styled-components";
import { MDBCard, MDBCardBody } from 'mdbreact';

const StyledMDBCardBody = styled(MDBCardBody)`
  padding: 2px 8px;
`;
StyledMDBCardBody.defaultProps = MDBCardBody.defaultProps;

const StyledMDBCard = styled(MDBCard)`
  margin-top: 20px;
`;
StyledMDBCard.defaultProps = MDBCard.defaultProps;

export {
  StyledMDBCardBody as MDBCardBody,
  StyledMDBCard as MDBCard,
};
