import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBIcon,
    MDBInput,
    MDBRow
} from "mdbreact";
import {SingleSelect} from "react-select-material-ui";
import { optionsSexo, optionsEscolaridade, optionsEstadoCivil } from './options';
import api from "../../../services/api";
import CreateButton from "../../../components/Buttons/CreateButton";
import validate from "./validate";
import Notify from "../../../components/Notify";
import {maskCel, maskCep, maskCpf, maskTel} from "../../../utils/mask";

const Edit = (props) => {

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});
    const [notify, setNotify] = useState({type:null, text:null});

    useEffect(() => {
        get(props.id);
    }, [props]);

    useEffect(() => {
        console.log(errors);
        if (Object.keys(errors).length === 0 && saving) {
            save();
        }
        setSaving(false);
    }, [errors]);

    function handleValidate(){
        setErrors(validate(form));
        setSaving(true);
    }

    function handleForm(event){
        let { value, id } = event.target;

        if(id==='cpf'){
            value = maskCpf(value);
        }
        if(id==='cel'){
            value = maskCel(value);
        }
        if(id==='tel'){
            value = maskTel(value);
        }
        if(id==='tel2'){
            value = maskTel(value);
        }
        if(id==='cep'){
            value = maskCep(value);
        }

        const newForm = {
            ...form,
            [id]: value
        }
        setForm(newForm);
        setErrors(validate(newForm));
    }

    function handleSexo(value){
        setForm({
            ...form,
            sexo: value
        })
    }

    function handleEstadoCivil(value){
        setForm({
            ...form,
            estado_civil: value
        })
    }

    function handleNotify(notify){
        setNotify(notify);
        setNotify({type: null, text:null});
    }

    async function get(id){
        setLoading(true);
        try{
            const result = await api.get('/api/cliente/'+id);
            //console.log(result.data);
            setForm(result.data);
            setLoading(false);
        }catch (error){
            console.log(error);
            setLoading(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    async function save(){
        try{
            const result = await api.put('/api/cliente/'+props.id, {cliente: form});
            //console.log(result.data);
            if(!result.data.valid){
                //notifyWarning(result.data.return);
                handleNotify({type: 'warning', text: result.data.return});
                setSaving(false);
                return;
            }
            setSaving(false);
            handleNotify({type: 'success', text: ''});
        }catch (error){
            console.log(error);
            setSaving(false);
            handleNotify({type: 'error', text: ''});
        }
    }

    const submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    return (
        <MDBContainer fluid style={{marginTop: "1.5rem" }}>
            <MDBCard>
                <MDBCardBody>
                    <Notify notify={notify} />
                    <div className="text-center" style={{display: (loading ? '' : 'none')}}>
                        <MDBIcon icon="spinner" spin size="3x"/>
                    </div>
                    <form action="" className="needs-validation" onSubmit={submitHandler} noValidate style={{display: (loading ? 'none' : '')}}>
                        <MDBRow>
                            <MDBCol md="6">
                                <MDBInput label="Nome" id="nome" name="nome"
                                          onChange={handleForm}
                                          containerClass={errors.nome ? "invalid-input" : ""}
                                          value={form.nome}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="CPF" id="cpf" name="cpf"
                                          onChange={handleForm}
                                          containerClass={errors.cpf ? "invalid-input" : ""}
                                          value={form.cpf}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <SingleSelect
                                    label="Sexo"
                                    options={optionsSexo}
                                    onChange={handleSexo}
                                    className={errors.sexo ? "invalid-select" : ""}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput
                                    label="Nascimento"
                                    type="date"
                                    id="nascimento"
                                    onChange={handleForm}
                                    containerClass={errors.cpf ? "invalid-input" : ""}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <SingleSelect
                                    label="Estado Civil"
                                    options={optionsEstadoCivil}
                                    onChange={handleEstadoCivil}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="Profissão" id="profissao" name="profissao" onChange={handleForm} />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="E-mail" id="email" name="email"
                                          onChange={handleForm} containerClass={errors.email ? "invalid-input" : ""}/>
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="Cel" id="cel" name="cel"
                                          onChange={handleForm}
                                          containerClass={errors.cel ? "invalid-input" : ""}
                                          value={form.cel}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="Tel" id="tel" name="tel"
                                          onChange={handleForm}
                                          containerClass={errors.tel ? "invalid-input" : ""}
                                          value={form.tel}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="Tel2" id="tel2" name="tel2"
                                          onChange={handleForm}
                                          value={form.tel2}
                                          containerClass={errors.tel ? "invalid-input" : ""}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="Cep" id="cep" name="cep"
                                          onChange={handleForm}
                                          value={form.cep}
                                          containerClass={errors.cep ? "invalid-input" : ""}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="Endereço" id="endereco" name="endereco" onChange={handleForm} value={form.endereco} />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="Número" id="numero" name="numero" onChange={handleForm} value={form.numero} />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="Complemento" id="complemento" name="complemento" onChange={handleForm} value={form.complemento} />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="Bairro" id="bairro" name="bairro" onChange={handleForm} value={form.bairro} />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="Cidade" id="cidade" name="cidade" onChange={handleForm} value={form.cidade} />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput label="Estado" id="estado" name="estado" onChange={handleForm} value={form.estado} />
                            </MDBCol>
                        </MDBRow>
                        <hr/>
                        <MDBRow>
                            <MDBCol className="text-right">
                                <div style={{display: (saving ? 'none' : '')}}>
                                    <CreateButton onClick={handleValidate} />
                                </div>
                                <MDBBtn outline disabled style={{display: (saving ? '' : 'none')}}>
                                    <MDBIcon icon="spinner" spin />&nbsp;&nbsp;Salvar
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Edit;
