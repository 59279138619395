import {
    MDBBtn,
    MDBCol,
    MDBCollapse,
    MDBContainer,
    MDBLink,
    MDBNavbar,
    MDBNavbarBrand, MDBNavbarNav,
    MDBNavbarToggler, MDBNavItem, MDBNavLink,
    MDBRow
} from "mdbreact";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";

import logo from "../../../assets/logo-site.png";
import pc from "../../../assets/pc.png";
import bgItem from "../../../assets/bg-item.png"
import cel from "../../../assets/cel.png";
import bgHome from "../../../assets/bg.png";
import analise from "../../../assets/analise.png";
import planejamento from "../../../assets/planejamento.png";
import tempo from "../../../assets/tempo.png";


//import "./index.css";

const Home = () => {

    const [isOpen, setIsOpen] = useState(true);
    const history = useHistory();
    const [textoPrincipal, setTextoPrincipal] = useState({
        id: 0,
        titulo: ''
    });
    const [texto1, setTexto1] = useState({
        id: 0,
        titulo: '',
        texto: ''
    });
    const [texto2, setTexto2] = useState({
        id: 0,
        titulo: '',
        texto: ''
    });
    const [recursos, setRecursos] = useState([
        {titulo: '', texto: ''},
        {titulo: '', texto: ''},
        {titulo: '', texto: ''},
    ]);

    useEffect(() => {
        get('texto-principal-home');
        get('texto1-home');
        get('texto2-home');
        listRecursos();
    }, []);

    async function get(slug){
        /*if(slug === 'texto-principal-home'){
            setLoadingTextoPrincipal(true);
        }
        if(slug === 'texto1-home'){
            setLoadingTexto1(true);
        }
        if(slug === 'texto2-home'){
            setLoadingTexto2(true);
        }*/
        try{
            const result = await api.get('/api/public-site-texto-slug/'+slug);
            console.log(result.data);
            if(slug === 'texto-principal-home'){
                setTextoPrincipal({
                    id: result.data.id,
                    titulo: result.data.titulo
                });
                //setLoadingTextoPrincipal(false);
            }
            if(slug === 'texto1-home'){
                setTexto1({
                    id: result.data.id,
                    titulo: result.data.titulo,
                    texto: result.data.texto
                });
                //setLoadingTexto1(false);
            }
            if(slug === 'texto2-home'){
                setTexto2({
                    id: result.data.id,
                    titulo: result.data.titulo,
                    texto: result.data.texto
                });
                //setLoadingTexto2(false);
            }
        }catch (error){
            console.log(error);
            /*if(slug === 'texto-principal-home'){
                setLoadingTextoPrincipal(false);
            }
            if(slug === 'texto1-home'){
                setLoadingTexto1(false);
            }
            if(slug === 'texto2-home'){
                setLoadingTexto2(false);
            }*/
        }
    }

    async function listRecursos(search = ''){
        //setLoading(true);
        try{
            const result = await api.get('/api/public-site-recurso', {params: {search: search}});
            let items = result.data;
            setRecursos(items);
            //setLoading(false);
        }catch (error){
            console.log(error);
            //setLoading(false);
        }
    }

    return (
        <MDBContainer fluid>
            {/*<MDBRow style={{display: 'none'}}>
                <MDBCol md="4"><img src={logo} alt="icdsprev"/></MDBCol>
                <MDBCol md="8">
                    <MDBRow>
                        <MDBCol md="2"><MDBLink className="menu-home">Recursos</MDBLink></MDBCol>
                        <MDBCol md="2"><MDBLink className="menu-home">Planos</MDBLink></MDBCol>
                        <MDBCol md="2"><MDBLink className="menu-home">Login</MDBLink></MDBCol>
                        <MDBCol md="4"><MDBBtn>Assine Agora</MDBBtn></MDBCol>
                        <MDBCol md="2">&nbsp;</MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>*/}
            {/*<div>
                <MDBNavbar expand="sm" className="navbar-site">
                    <MDBNavbarBrand>
                        <a href="/">
                            <img src={logo} alt="icdsprev"/>
                        </a>
                    </MDBNavbarBrand>
                    <MDBNavbarToggler onClick={() => setIsOpen(!isOpen)} />
                    <MDBCollapse id="navbarSite" isOpen={isOpen} navbar>
                        <MDBNavbarNav right>
                            <MDBNavItem className="menu-home">
                                <MDBNavLink to="#!">Recursos</MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem className="menu-home">
                                <MDBNavLink to="planos">Planos</MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem className="menu-home">
                                <MDBNavLink to="login">Login</MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem className="menu-home">
                                <MDBBtn color="indigo"  size="sm" className="btn-signup" onClick={() => {history.push("/assinar");}}>Assine Agora</MDBBtn>
                            </MDBNavItem>
                            <MDBNavItem className="menu-home" style={{width: '150px'}}>&nbsp;</MDBNavItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBNavbar>
            </div>*/}

            <br/><br/><br/><br/>
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="5">
                        <br/><br/>
                        <h1>{textoPrincipal.titulo}</h1>
                    </MDBCol>
                    <MDBCol md="2">&nbsp;</MDBCol>
                    <MDBCol md="5">
                        <img src={pc} alt="PC" width="100%"/>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="12 text-center">
                        <br/><br/><br/><br/>
                        <h2>{texto1.titulo}</h2>
                        <p>{texto1.texto}</p>
                    </MDBCol>

                    {
                        recursos.map((item) => {
                            return (
                                <MDBCol md="4 text-center">
                                    <div className="item">
                                        <div className="bg-item">
                                            <img src={planejamento} alt="" width="95" className="bg-item-img"/>
                                        </div>
                                        <h3>{item.titulo}</h3>
                                        <p>{item.texto}</p>
                                    </div>
                                </MDBCol>
                            )
                        })
                    }


                </MDBRow>

                <MDBRow>
                    <MDBCol md="5">
                        <br/><br/><br/><br/><br/><br/><br/><br/>
                        <h2>{texto2.titulo}</h2>
                        <p>{texto2.texto}</p>
                    </MDBCol>
                    <MDBCol md="2">&nbsp;</MDBCol>
                    <MDBCol md="5">
                        <br/><br/><br/><br/><br/><br/><br/><br/>
                        <img src={cel} alt="Celular" width="50%" style={{zIndex: 1, position: 'relative'}}/>
                    </MDBCol>
                    <img src={bgHome} width="40%" className="background-home"/>
                </MDBRow>


            </MDBContainer>

        </MDBContainer>
    );
}

import React, {useEffect, useState} from "react";

export default Home;
